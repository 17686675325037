import { produce } from "immer";
import format from "../shared/formatters";

import {
  saveAllDetails,
  checkAllImgs,
  toggleChecked,
  deleteImg,
  updateImg,
  addImg,
  addError,
  clearError,
  saveDetail,
  saveLinks,
  setAccountSources,
  setAccountPropertyTypes,
  showDetailsFailure,
  clearDetailsFailure,
  setRefPartnerDetailsTeam,
  setRefPartnerDetailsBrokerage,
  setRefTitle,
  setRefStatus,
  setTransactionAmount,
  setClientDetailsFinancing,
  updatePropertyDetail,
} from "../TransactionDetailsPage/Main/Details/actions/creators";
import { saveNavigationListings } from "../TransactionDetailsPage/NavigationListings/action/creators";
import saveTitleCrossDetail from "../TransactionDetailsPage/Main/Tasks/actions/crossCreators";
import {
  editReferralDesiredAddress,
  referralDesiredAddressEditOn,
  referralDesiredAddressEditOff,
  saveDetailsFromHeader,
  referralTimeFrameEditOn,
  editTimeframeExact,
  referralCalendarEditOn,
  referralCalendarEditCancel,
  editReferralTransactionAmt,
  editReferralFee,
  editReferralStatus,
  editReferralTitle,
  referralEditTitleModalOn,
  referralEditTitleModalOff,
  isUpdateAddressModal,
  saveDetails as saveDetailsFromHeaderUpdate,
  editIsReferralFeePercentage,
  editAddressFromPipeline,
  setImportModalOpen,
} from "../TransactionDetailsPage/Header/actions/creators";
import { trim } from "lodash";
import { timeframeReadHelper } from "./helpers";

export const defaultState = {
  uuid: "",
  awsDirectPost: null,
  notableId: "",
  currentUserId: "",
  isAdmin: null,
  currentUser: null,
  slug: null,
  person: null,
  smsEnabled: false,
  accountUUID: "",
  transactionDetails: {
    title: "",
    use_custom_title: false,
    published: "",
    status: "active",
    est_close_price: "",
    estimated_commission: "",
    type: "",
  },
  listingDetails: {
    date_listed: "",
    expiration_date: "",
    price: "",
    mls_number: "",
    valid_mls_number: false,
    source: "",
    description: "",
    buyers_agent_commission: {
      id: "",
      percentage: true,
      value: "",
      cooperative_compensation: false,
      exclude_from_commissions: false,
      status: "",
    },
  },
  propertyDetails: {
    street_address: "",
    city: "",
    state: "",
    zip: "",
    property_type: {},
    beds: "",
    baths: "",
    sqft: "",
    neighborhood: "",
    county: "",
    year_built: "",
    parking: "",
    foundation: "",
  },
  referralPartner: {
    name: null,
    pdpLink: null,
  },
  noteDetails: {
    notes: [],
  },
  referralDetails: {
    clientIntent: null,
    addressField: null,
    timeframe: null,
    timeframeExact: null,
    timeframeRead: null,
    transactionAmt: null,
    referralFee: null,
    isReferralFeePercentage: true,
    status: null,
    isModalRenameTitleOpen: false,
    isReferralDesiredAddressEdit: false,
    isTimeFrameEdit: false,
    isReferralCalendarOpen: false,
    brokerage: null,
    team: null,
  },
  clientDetails: {
    clientName: null,
    pdpLink: null,
    financing: null,
  },
  meta: {
    accountPropertyTypes: [],
    accountSources: [],
  },
  primaryAgentDetails: {
    agent: null,
    agent_id: null,
    agent_avatar: null,
    primaryAgentRole: null,
  },
  headerDetails: {
    type: null,
    published: null,
  },
  additionalDetails: {},
  propertyPagePath: "",
  links: [],
  useMLSphotos: false,
  blossorId: "",
  listingImgs: [],
  listingImgErrors: [],
  peopleListed: [],
  checkAll: false,
  navigationListing: {
    previousSlug: null,
    nextSlug: null,
    previousSlugUuid: null,
    nextSlugUuid: null,
  },
  error: null,
  isUpdateAddressModalOpen: false,
  tempUpdatedStatus: null,
  tempPreStatus: null,
  isImportModalOpen: false,
  preStatus: null,
};

export const tdpDetailsReducer = (state = defaultState, payload) => {
  switch (payload.type) {
    case setAccountPropertyTypes().type: {
      return produce(state, (draft) => {
        draft.meta.accountPropertyTypes = payload.propertyTypes.map((s) => ({ label: s.name, value: s.id }));
      });
    }
    case setAccountSources().type: {
      return produce(state, (draft) => {
        draft.meta.accountSources = payload.sources.map((s) => ({ label: s.name, value: s.id }));
      });
    }
    case saveAllDetails().type:
      return produce(state, (draft) => {
        draft.transactionDetails.title = payload.details.transactionDetails.title;
        draft.transactionDetails.use_custom_title = payload.details.transactionDetails.use_custom_title;
        draft.transactionDetails.published = payload.details.transactionDetails.published;
        draft.transactionDetails.est_close_price = payload.details.transactionDetails.est_close_price;
        draft.transactionDetails.estimated_commission =
          payload.details.transactionDetails.estimated_commission;
        draft.transactionDetails.status = payload.details.transactionDetails.status;
        draft.listingDetails = payload.details.listingDetails;
        draft.propertyDetails = payload.details.propertyDetails;

        draft.referralDetails.team = payload.details.referralPartnerDetails.team;
        draft.referralDetails.brokerage = payload.details.referralPartnerDetails.brokerage;
        draft.referralDetails.transactionAmt = payload.details.referralDetails.transactionAmt;
        draft.referralDetails.referralFee = payload.details.referralDetails.referralFee;
        draft.propertyDetails.property_type = payload.details.propertyDetails.property_type;

        draft.referralDetails.addressField = payload.details.clientDetails.addressField;

        draft.additionalDetails = payload.details.additionalDetails;
        draft.propertyPagePath = payload.details.propertyPagePath;
        draft.links = payload.details.links;
        draft.useMLSphotos = payload.details.useMLSphotos;
        draft.blossorId = payload.details.blossorId;
        draft.listingImgs = payload.details.listingImgs;
        draft.peopleListed = payload.details.peopleListed;
        draft.checkAll = payload.details.checkAll;
        draft.referralPartner.name = payload.details.referralPartnerDetails.name;
        draft.referralPartner.pdpLink = payload.details.referralPartnerDetails.pdpLink;

        draft.clientDetails.financing = payload.details.clientDetails.financing;
        draft.clientDetails.name = payload.details.clientDetails.name;
        draft.clientDetails.pdpLink = payload.details.clientDetails.pdpLink;
      });
    case saveDetailsFromHeader().type:
      return produce(state, (draft) => {
        draft.transactionDetails.title = payload.details.transactionDetails.title;
        draft.transactionDetails.use_custom_title = payload.details.transactionDetails.use_custom_title;
        draft.transactionDetails.publishedState = payload.details.transactionDetails.publishedState;
        draft.transactionDetails.status = payload.details.transactionDetails.status;
        draft.transactionDetails.est_close_price = payload.details.transactionDetails.est_close_price;
        draft.transactionDetails.type = payload.details.transactionDetails.type;
        draft.listingDetails.source = payload.details.listingDetails.source;
        draft.listingDetails.price = payload.details.listingDetails.price;
        draft.listingDetails.mls_number = payload.details.listingDetails.mls_number;
        draft.listingDetails.valid_mls_number = payload.details.listingDetails.valid_mls_number;
        draft.primaryAgentDetails.agent = payload.details.primaryAgentDetails?.agent;
        draft.primaryAgentDetails.agentAvatar = payload.details.primaryAgentDetails?.agent_avatar;
        draft.primaryAgentDetails.agentId = payload.details.primaryAgentDetails?.agent_id;
        draft.primaryAgentDetails.primaryAgentRole = payload.details.primaryAgentDetails?.primaryAgentRole;

        draft.propertyDetails.street_address = payload.details.propertyDetails.street_address;
        draft.propertyDetails.city = payload.details.propertyDetails.city;
        draft.propertyDetails.state = payload.details.propertyDetails.state;
        draft.propertyDetails.zip = payload.details.propertyDetails.zip;
        draft.propertyDetails.beds = payload.details.propertyDetails.beds;
        draft.propertyDetails.baths = payload.details.propertyDetails.baths;
        draft.propertyDetails.sqft = payload.details.propertyDetails.sqft;
        draft.propertyDetails.property_type = payload.details.propertyDetails.property_type;
        draft.headerDetails.days_on_market = payload.details.headerDetails.days_on_market;
        draft.headerDetails.photo = payload.details.headerDetails.photo;
        draft.headerDetails.type = payload.details.headerDetails.type;
        draft.headerDetails.listing_id = payload.details.headerDetails.listing_id;
        draft.headerDetails.published = payload.details.headerDetails.published;
        draft.headerDetails.address_id = payload.details.headerDetails.address_id;
        draft.headerDetails.statusCode = payload.details.headerDetails.statusCode;
        draft.headerDetails.error = payload.details.headerDetails.error;
        draft.propertyDetails.beds = payload.details.propertyDetails.beds;
        draft.propertyDetails.baths = payload.details.propertyDetails.baths;
        draft.propertyDetails.sqft = payload.details.propertyDetails.sqft;

        draft.referralDetails.clientIntent = payload.details.referralDetails.clientIntent;
        draft.referralDetails.addressField = payload.details.referralDetails.addressField;
        draft.referralDetails.transactionAmt = payload.details.referralDetails.transactionAmt;
        draft.referralDetails.referralFee = payload.details.referralDetails.referralFee;
        (draft.referralDetails.isReferralFeePercentage =
          payload.details.referralDetails.isReferralFeePercentage),
          (draft.referralDetails.timeframe = payload.details.referralDetails.timeframe);
        draft.referralDetails.timeframeExact = payload.details.referralDetails.timeframeExact;
        draft.referralDetails.timeframeRead = payload.details.referralDetails.timeframeRead;
        draft.referralDetails.team = payload.details.referralDetails.team;
        draft.referralPartner.name = payload.details.referralPartner.name;
        draft.referralPartner.avatar = payload.details.referralPartner.avatar;
        draft.referralDetails.brokerage = payload.details.referralDetails.brokerage;
      });
    case saveTitleCrossDetail().type:
      return produce(state, (draft) => {
        draft.transactionDetails.tiztle = payload.title;
      });
    case saveDetail().type:
      return produce(state, (draft) => {
        draft[payload.group][payload.key] = payload.value;
      });
    case saveDetailsFromHeaderUpdate().type:
      return produce(state, (draft) => {
        payload.details.forEach((detail) => {
          draft[detail.group][detail.key] = detail.value;
        });
      });
    case showDetailsFailure().type:
      return produce(state, (draft) => {
        draft.error = payload.error;
      });
    case clearDetailsFailure().type:
      return produce(state, (draft) => {
        draft.error = null;
      });
    case saveLinks().type:
      return produce(state, (draft) => {
        draft.links = payload.value;
      });
    case checkAllImgs().type:
      return produce(state, (draft) => {
        const checked = !state.checkAll;

        draft.listingImgs = state.listingImgs.map((img) => ({
          ...img,
          checked,
        }));
        draft.checkAll = checked;
      });
    case toggleChecked().type:
      return produce(state, (draft) => {
        const idx = state.listingImgs.findIndex(({ id }) => id === payload.id);
        draft.listingImgs[idx].checked = !state.listingImgs[idx].checked;
      });
    case deleteImg().type:
      return produce(state, (draft) => {
        const imgs = [...state.listingImgs];
        const idx = imgs.findIndex(({ id }) => id === payload.id);
        imgs.splice(idx, 1);
        draft.listingImgs = imgs;
      });
    case updateImg().type:
      return produce(state, (draft) => {
        const imgs = [...state.listingImgs];
        const idx = imgs.findIndex(({ id }) => id === payload.image.tmpId);
        imgs[idx] = payload.image;
        draft.listingImgs = imgs;
      });
    case addImg().type:
      return produce(state, (draft) => {
        draft.listingImgs = [...state.listingImgs, payload.image];
      });
    case addError().type:
      return produce(state, (draft) => {
        draft.listingImgErrors = [...state.listingImgErrors, payload.error];
      });
    case clearError().type:
      return produce(state, (draft) => {
        const listingImgErrors = [...state.listingImgErrors];
        const idx = listingImgErrors.findIndex(({ id }) => id === payload.id);
        listingImgErrors.splice(idx, 1);
        draft.listingImgErrors = listingImgErrors;
      });
    case saveNavigationListings().type:
      return produce(state, (draft) => {
        draft.navigationListing = payload.navigationListings;
      });
    case referralDesiredAddressEditOn().type:
      return produce(state, (draft) => {
        draft.referralDetails.isReferralDesiredAddressEdit = true;
      });
    case referralDesiredAddressEditOff().type:
      return produce(state, (draft) => {
        draft.referralDetails.isReferralDesiredAddressEdit = false;
      });
    case referralTimeFrameEditOn().type: {
      return produce(state, (draft) => {
        draft.referralDetails.isTimeFrameEdit = true;
      });
    }
    case editReferralDesiredAddress().type:
      return produce(state, (draft) => {
        draft.referralDetails.addressField = trim(payload.address);
        draft.referralDetails.isReferralDesiredAddressEdit = false;
      });
    case editTimeframeExact().type:
      return produce(state, (draft) => {
        draft.referralDetails.isReferralCalendarOpen = false;
        draft.referralDetails.isTimeFrameEdit = false;

        draft.referralDetails.timeframe = payload.option;
        draft.referralDetails.timeframeExact = payload.exactDate;
        draft.referralDetails.timeframeRead = timeframeReadHelper(payload.option.label, payload.exactDate);
      });
    case referralCalendarEditOn().type:
      return produce(state, (draft) => {
        draft.referralDetails.timeframe = payload.option;
        draft.referralDetails.isReferralCalendarOpen = true;
      });
    case referralCalendarEditCancel().type:
      return produce(state, (draft) => {
        draft.referralDetails.timeframe = payload.option;
        draft.referralDetails.timeframeRead = timeframeReadHelper(payload.option?.value);

        draft.referralDetails.isReferralCalendarOpen = false;
        draft.referralDetails.isTimeFrameEdit = false;
      });
    case editReferralTransactionAmt().type:
      return produce(state, (draft) => {
        draft.referralDetails.transactionAmt = payload.transactionAmt;
      });
    case editReferralFee().type:
      return produce(state, (draft) => {
        draft.referralDetails.isReferralFeePercentage = payload.isFeePercentage;
        const changeAmtToValidNumber = format.number(
          payload.referralFee,
          2,
          payload.isFeePercentage ? false : true,
        );
        draft.referralDetails.referralFee = changeAmtToValidNumber;
      });
    case editIsReferralFeePercentage().type:
      return produce(state, (draft) => {
        draft.referralDetails.isReferralFeePercentage = payload.bool;
        draft.referralDetails.referralFee = null;
      });
    case editReferralStatus().type:
      return produce(state, (draft) => {
        draft.referralDetails.status = payload.status;
      });
    case editReferralTitle().type:
      return produce(state, (draft) => {
        draft.transactionDetails.title = payload.title;
        draft.referralDetails.isModalRenameTitleOpen = false;
      });
    case referralEditTitleModalOn().type:
      return produce(state, (draft) => {
        draft.referralDetails.isModalRenameTitleOpen = true;
      });
    case referralEditTitleModalOff().type:
      return produce(state, (draft) => {
        draft.referralDetails.isModalRenameTitleOpen = false;
      });
    case setRefPartnerDetailsTeam().type:
      return produce(state, (draft) => {
        draft.referralDetails.team = payload.team;
      });
    case setRefPartnerDetailsBrokerage().type: {
      return produce(state, (draft) => {
        draft.referralDetails.brokerage = payload.brokerage;
      });
    }
    case setRefTitle().type: {
      return produce(state, (draft) => {
        draft.transactionDetails.title = payload.title;
      });
    }
    case setRefStatus().type: {
      return produce(state, (draft) => {
        draft.transactionDetails.status = payload.status;
      });
    }
    case setTransactionAmount().type: {
      return produce(state, (draft) => {
        draft.referralDetails.transactionAmt = payload.amount;
      });
    }
    case setClientDetailsFinancing().type: {
      return produce(state, (draft) => {
        draft.clientDetails.financing = payload.financing;
      });
    }
    case isUpdateAddressModal().type: {
      return produce(state, (draft) => {
        draft.isUpdateAddressModalOpen = payload.boolean;
        draft.tempUpdatedStatus = null || payload.tempUpdatedStatus;
        draft.tempPreStatus = null || payload.tempPreStatus;
      });
    }
    case setImportModalOpen().type: {
      return produce(state, (draft) => {
        draft.isImportModalOpen = payload.isImportModalOpen;
        draft.preStatus = payload.preStatus;
      });
    }
    case updatePropertyDetail().type: {
      return produce(state, (draft) => {
        const additionalDetails = { ...state.additionalDetails };
        const [keyToUpdate] = Object.entries(additionalDetails).find(
          ([_key, value]) => value.id === payload.id,
        );

        const { [keyToUpdate]: labelName, ...restOfAdditionalDetails } = additionalDetails;
        const newAdditionalDetails = {
          [payload.name]: { ...labelName },
          ...restOfAdditionalDetails,
        };

        draft.additionalDetails = payload["_destroy"] ? restOfAdditionalDetails : newAdditionalDetails;
      });
    }
    case editAddressFromPipeline().type: {
      return produce(state, (draft) => {
        const { city, locality, postal_code, street_address } = payload.details.find(
          (item) => item.key === "address_attributes",
        ).value;

        const status = payload.details.find((item) => item.key === "status").value;
        const title = payload.details.find((item) => item.key === "title")?.value;

        draft.propertyDetails.city = city;
        draft.propertyDetails.state = locality;
        draft.propertyDetails.street_address = street_address;
        draft.propertyDetails.zip = postal_code;

        draft.transactionDetails.status = status === "coming soon" ? "comingsoon" : status;
        if (title) {
          draft.transactionDetails.title = title;
        }
        draft.transactionDetails.use_custom_title = title ? true : false;

        draft.isUpdateAddressModalOpen = false;
      });
    }
    default:
      return state;
  }
};

import React from "react";

import { ListCheckSolidV6 } from "@shared/v2/Icomoon";

import ActivitiesContainer from "./ActivitiesContainer";
import { activityCommonDefaultProps, activityCommonPropTypes, STYLES } from "../shared";
import PipedElements from "../PipedElements";

const NewTaskAcitivity = ({ activity, onReadChange }) => {
  const { TaskTitle, TaskType, AssignerName, TaskDueDate } = activity.data;
  return (
    <ActivitiesContainer
      activity={activity}
      onReadChange={onReadChange}
      Icon={ListCheckSolidV6}
      childContainerClass="!tw-mr-[0px]"
    >
      <div className={STYLES.contentContainer}>
        <span>{TaskTitle}</span>
        <span>Type: {TaskType}</span>
        <PipedElements>
          <span className="tw-whitespace-nowrap">Due: {TaskDueDate}</span>
          <span className="tw-whitespace-nowrap">From: {AssignerName}</span>
        </PipedElements>
      </div>
    </ActivitiesContainer>
  );
};

NewTaskAcitivity.propTypes = {
  ...activityCommonPropTypes,
};

NewTaskAcitivity.defaultProps = {
  ...activityCommonDefaultProps,
};

export default NewTaskAcitivity;

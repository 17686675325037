import React from "react";
import FieldLabelV1 from "./FieldLabelV1";
import TextInput from "../v2/TextInput";

function FieldPhoneV1({
  className = "",
  placeholder = "",
  onChange,
  required,
  label,
  value = "",
  flexStyle = "tw-flex-1 ",
  disabled,
}) {
  function validatePhoneNumber(phoneNumber) {
    // for the phone numbers entered in following format:
    // (123) 456-7890, (123)456-7890, 123-456-7890, 1234567890
    let re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

    return re.test(phoneNumber);
  }

  return (
    <div className={flexStyle} data-cy={`${label}`}>
      {label && <FieldLabelV1 {...{ label, required }} />}

      <TextInput
        placeholder={placeholder}
        className={className}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        value={value}
      />
    </div>
  );
}

export default FieldPhoneV1;

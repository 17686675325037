import React from "react";

import { ApartmentSolidV6, HouseCircleXmarkSolidV6, MagnifyingGlassSolidV6 } from "@shared/v2/Icomoon";

const LinkBar = ({ onClickView, currentView }) => {
  const handleLinkClick = (target) => {
    if (target === currentView) {
      return;
    }

    if (target === "/search_listings") {
      document.location.href = target;
      return;
    }

    onClickView(target);
  };

  return (
    <div className="tw-flex tw-flex-row tw-gap-12px">
      {AvailableLinks.map((link) => (
        <LinkButton
          target={link.target}
          icon={link.icon}
          text={link.text}
          handleLinkClick={handleLinkClick}
          active={currentView === link.target}
        />
      ))}
    </div>
  );
};

export default LinkBar;

const LinkButton = ({ target, icon: Icon, text, handleLinkClick, active }) => {
  const styles = active
    ? "tw-border-theme-brand-light tw-text-theme-brand"
    : "tw-border-gray-10 tw-text-gray-50";

  const hover = "hover:tw-border-theme-brand hover:tw-text-theme-brand-dark";

  return (
    <div
      className={`tw-flex tw-flex-col tw-items-center tw-justify-center ${active ? "" : "tw-cursor-pointer"} tw-gap-8px tw-w-[120px] tw-h-[65px] tw-border tw-border-solid tw-rounded-8px ${hover} ${styles}`}
      onClick={() => handleLinkClick(target)}
    >
      <Icon size="xl" />
      <span className={`tw-text-12px ${active ? "tw-font-semibold" : ""}`}>{text}</span>
    </div>
  );
};

const AvailableLinks = [
  {
    text: "MLS Listings",
    icon: MagnifyingGlassSolidV6,
    target: "/search_listings",
  },
  {
    text: "Expireds",
    icon: HouseCircleXmarkSolidV6,
    target: "expireds",
  },
  {
    text: "FSBO's",
    icon: ApartmentSolidV6,
    target: "fsbos",
  },
];

/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import { set, uniqBy } from "lodash";

export const initialState = {
  error: null,
  keepContact: null,
  loading: false,
  mergeContact: null,
  modalIsOpen: false,
  primaryContact: null,
  otherContact: null,
  potentialDuplicates: [],
  hasDuplicates: false,
};

const getHasDuplicates = createAsyncThunk("contactMerge/getHasDuplicates", (slug, thunkApi) =>
  caseTransformingAxios
    .get(`/api/v4/person/${slug}/detect_duplicates`)
    .then((res) => res.data)
    .catch(() => thunkApi.rejectWithValue(false)),
);

const getPotentialDuplicates = createAsyncThunk("contactMerge/getPotentialDuplicates", (slug, thunkApi) =>
  caseTransformingAxios
    .get(`/api/v4/person/${slug}/duplicates`)
    .then((res) => res.data)
    .catch((err) => thunkApi.rejectWithValue(err.response?.data)),
);

const mergeContacts = createAsyncThunk("contactMerge/mergeContacts", (data, thunkApi) => {
  const { mergeContact, primaryContact, otherContact } = thunkApi.getState().contactMerge;
  const other = mergeContact.uuid === primaryContact.uuid ? otherContact : primaryContact;

  const mergeContactWithOther = {
    ...mergeContact,
    addresses: uniqBy(
      [...(mergeContact.addresses || []), ...(other.addresses || [])].map((a) => ({
        ...a,
        addressableId: mergeContact.id,
      })),
      "id",
    ),
    emailDetails: uniqBy(
      [
        ...(mergeContact.emailDetails || []),
        ...(other.emailDetails || []).filter((o) => o.category === "other"),
      ].map((e) => ({
        ...e,
        detailableId: mergeContact.id,
      })),
      "id",
    ),
    milestonableMilestones: uniqBy(
      [...(mergeContact.milestonableMilestones || []), ...(other.milestonableMilestones || [])].map((p) => ({
        ...p,
        milestonableId: mergeContact.id,
      })),
      "newMilestoneId",
    ),
    personDetails: uniqBy(
      [...(mergeContact.personDetails || []), ...(other.personDetails || [])].map((p) => ({
        ...p,
        detailableId: mergeContact.id,
      })),
      "id",
    ),
    phoneDetails: uniqBy(
      [
        ...(mergeContact.phoneDetails || []),
        ...(other.phoneDetails || []).filter((o) => o.category === "other"),
      ].map((p) => ({
        ...p,
        detailableId: mergeContact.id,
      })),
      "id",
    ),
    socialMediaDetails: uniqBy(
      [...(mergeContact.socialMediaDetails || []), ...(other.socialMediaDetails || [])].map((p) => ({
        ...p,
        detailableId: mergeContact.id,
      })),
      "id",
    ),
  };

  return caseTransformingAxios
    .post(`/api/v4/merge/${mergeContact.uuid}/${other.uuid}`, mergeContactWithOther)
    .then(() => mergeContact)
    .catch((err) => thunkApi.rejectWithValue(err.response?.data));
});

export const contactMergeSlice = createSlice({
  name: "contactMerge",
  initialState,
  reducers: {
    setModalIsOpen: (state, action) => {
      state.modalIsOpen = action.payload;
    },
    setOtherContact: (state, action) => {
      state.otherContact = action.payload;
    },
    setKeepContact: (state, action) => {
      state.keepContact = action.payload;
    },
    updateKeepContact: (state, action) => {
      const { id, type, key, value } = action.payload;
      if (id) {
        set(
          state.keepContact[type].find((detail) => (detail.id || detail.newMilestoneId) === id),
          key,
          value,
        );
      } else {
        set(state.keepContact, key, value);
      }
    },
    setMergeContact: (state, action) => {
      state.mergeContact = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHasDuplicates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHasDuplicates.fulfilled, (state, action) => {
        state.loading = false;
        state.hasDuplicates = action.payload || false;
      })
      .addCase(getHasDuplicates.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(getPotentialDuplicates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPotentialDuplicates.fulfilled, (state, action) => {
        state.loading = false;
        state.primaryContact = action.payload.primaryContact;
        state.potentialDuplicates = action.payload.potentialDuplicates || [];
      })
      .addCase(getPotentialDuplicates.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(mergeContacts.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(mergeContacts.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(mergeContacts.rejected, (state) => {
        state.error = "Failed to merge contacts. Please try again later.";
        state.loading = false;
      });
  },
});

export { getHasDuplicates, getPotentialDuplicates, mergeContacts };

export const { setKeepContact, setModalIsOpen, setOtherContact, updateKeepContact, setMergeContact } =
  contactMergeSlice.actions;

export default contactMergeSlice.reducer;

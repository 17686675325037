/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const BellSolidV6 = (props) => (
  <Icon {...props} name="Bell Solid V6">
    <path d="M9 1v.6c2.281.463 4 2.481 4 4.9v.588c0 1.472.541 2.887 1.516 3.987l.231.259A.999.999 0 0 1 14 13H2a1.002 1.002 0 0 1-.747-1.666l.232-.259a6.004 6.004 0 0 0 1.516-3.987V6.5a4.975 4.975 0 0 1 4-4.9V1a1 1 0 1 1 2 0zM8 16c-.531 0-1.041-.209-1.416-.584S6 14.504 6 14h4c0 .503-.209 1.041-.584 1.416S8.503 16 8 16z" />
  </Icon>
);

export default BellSolidV6;

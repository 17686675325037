import React, { useEffect, useState } from "react";

import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import Dnc from "../Dnc";
import PropertyInfoBlock from "./PropertyInfoBlock";
import { ArrowsRotateSolidV6, PhoneHangupSolidV6, XmarkLargeSolidV6 } from "@shared/v2/Icomoon";
import FieldLabel from "@shared/v2/FieldLabel";
import { communicationOutcomes } from "../filters";
import FilterPill from "@shared/v2/FilterPill";
import CALL_STATES from "../../../Dialer/utils/states";
import useDialerWrapper from "../useDialerWrapper";
import { useDialerServiceUrl } from "../../../reducers/layoutReducer/selectors";
import { formatCallDuration } from "../../../PhoneSystem/TrackingNumbers/helpers";
import { addLead } from "../services";

const ProspectDialer = ({ prospect, property, agentId, onProspectAddedAsLead, onClose }) => {
  const [outcome, setOutcome] = useState("");
  const [notes, setNotes] = useState("");
  const [isAddingLead, setIsAddingLead] = useState(false);
  const [isLoggingCall, setIsLoggingCall] = useState(false);
  const [leadAddedSlug, setLeadAddedSlug] = useState("");
  const dialer = useDialerWrapper(useDialerServiceUrl());

  const isDangerProspect = prospect.isLead && !prospect.isMyLead && !prospect.isMyPondLead;
  const isPotentialLead = !prospect.isLead && !Boolean(leadAddedSlug); // TODO: if/when we are refreshing data, this should work with the prospect.isLead flag

  const logCall = async () => {
    setIsLoggingCall(true);
    await dialer.logCall(outcome, notes);

    setIsLoggingCall(false);
    onClose();
  };

  const onClickAddLead = async () => {
    setIsAddingLead(true);
    const slug = await addLead(prospect, property, agentId);

    if (slug) {
      setLeadAddedSlug(slug);
      onProspectAddedAsLead(prospect.phone);
    }

    setIsAddingLead(false);
  };

  useEffect(() => {
    dialer.startCall([prospect]);
  }, []);

  const onClickCancel = async () => {
    await dialer.implode();
    onClose();
  };

  return (
    <Modal
      containerClassName="!tw-z-[3001]"
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-max-w-[656px] tw-w-[100vw] tw-flex tw-flex-col tw-gap-[32px]"
      onHide={onClickCancel}
      show
    >
      <Modal.Header>
        <div className="tw-flex tw-flex-col tw-gap-8px !tw-m-0">
          <span className="tw-text-18px tw-font-bold tw-text-gray-75">{prospect.name}</span>
          <div className="tw-flex tw-flex-row tw-gap-6px tw-items-center">
            <span className="tw-text-gray-50 tw-text-14px tw-font-semibold">{prospect.phoneFormatted}</span>
            <Dnc show={prospect.isDnc} />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="tw-flex tw-flex-col tw-gap-32px">
        <div className="tw-flex tw-flex-row tw-gap-16px">
          <CallBar callState={dialer.callState} />
          <div className="tw-ml-auto">
            {dialer.callState === CALL_STATES.Ended ? (
              <Button
                schema="tertiary"
                size="medium"
                onClick={() => dialer.startCall([prospect])}
                className="tw-min-w-[132px] tw-inline-flex tw-justify-center tw-items-center tw-gap-[4px]"
              >
                <ArrowsRotateSolidV6 size="l" /> Redial
              </Button>
            ) : (
              <Button
                schema="solid-red"
                size="medium"
                onClick={dialer.endCall}
                className="tw-min-w-[132px] tw-inline-flex tw-justify-center tw-items-center tw-gap-[4px]"
              >
                <PhoneHangupSolidV6 size="l" /> End Call
              </Button>
            )}
          </div>
        </div>
        <Banner
          title="The number is already in your CRM."
          text="The number you've selected is already in your database and may be another team member's lead. Please ensure you're not contacting leads assigned to others."
          show={isDangerProspect}
          style="warning"
          size="small"
        />
        <Banner
          title="Want to follow up? Add this person as a lead."
          text="Adding this person as a lead will allow you to set follow up tasks and reminders. Brivity will autofill as much info as possible."
          show={isPotentialLead}
          style="info"
          size="small"
          onClick={onClickAddLead}
          btnText="Add as lead"
          btnDisabled={isAddingLead}
          allowClose
        />
        <Banner
          title="Successfully added this person as a lead."
          text="A lead has been created with fields autofilled from the person's information. Use the button to the right to edit details and set tasks."
          show={Boolean(leadAddedSlug)}
          style="success"
          size="small"
          onClick={() => window.open(`/people/${leadAddedSlug}`, "_blank")}
          btnText="View"
        />
        <PropertyInfoBlock property={property} />
        <Outcome outcome={outcome} setOutcome={setOutcome} />
        <Notes notes={notes} setNotes={setNotes} />
      </Modal.Body>
      <Modal.Footer>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <Button size="medium" schema="secondary" onClick={onClickCancel}>
            Close
          </Button>
          <Button
            size="medium"
            schema="primary"
            onClick={logCall}
            disabled={!outcome || dialer.callState !== CALL_STATES.Ended}
            isLoading={isLoggingCall}
          >
            Log Call
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ProspectDialer;

const CallBar = ({ callState }) => {
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (callState === CALL_STATES.Connected) {
      const interval = setInterval(() => {
        setDuration((d) => d + 1);
      }, 1_000);

      return () => {
        clearInterval(interval);
      };
    }

    if (callState === CALL_STATES.Init) {
      setDuration(0);
    }
  }, [callState]);

  const text =
    callState === CALL_STATES.Ended
      ? "Call Ended"
      : callState === CALL_STATES.Connected
        ? "Connected"
        : "Calling";
  const circleColor =
    callState === CALL_STATES.Ended
      ? "tw-bg-semantic-red-50"
      : callState === CALL_STATES.Connected
        ? "tw-bg-semantic-green-100"
        : "tw-bg-semantic-blue-100";

  return (
    <div className="tw-w-full tw-flex tw-flex-row tw-items-center tw-border tw-rounded-md tw-border-solid tw-border-gray-30 tw-text-12px tw-bg-gray-5 tw-py-9px tw-px-15px tw-text-gray-75">
      <div className={`tw-rounded-full tw-h-8px tw-w-8px tw-mr-6px ${circleColor}`} />
      <span className="tw-font-semibold">{text}</span>
      <span className="tw-ml-auto tw-font-normal">{formatCallDuration(duration)}</span>
    </div>
  );
};

const Banner = ({
  show,
  title,
  text,
  style = "info",
  size = "small",
  btnText,
  btnDisabled,
  onClick,
  allowClose,
}) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setIsShown(show);
  }, [show]);

  const Styles = {
    warning: "tw-bg-semantic-yellow-5 tw-text-semantic-yellow-120 tw-border-semantic-yellow-10",
    success: "tw-bg-semantic-green-5 tw-text-semantic-green-120 tw-border-semantic-green-10",
    info: "tw-bg-semantic-blue-5 tw-text-semantic-blue-120 tw-border-semantic-blue-10",
  };

  const Sizes = {
    small: "tw-font-semibold tw-text-12px",
    medium: "tw-font-bold tw-text-14px",
  };

  return isShown ? (
    <div
      className={`tw-flex tw-flex-row tw-items-center tw-gap-16px tw-px-16px tw-py-12px tw-border-solid tw-rounded tw-border ${Styles[style]} ${Sizes[size]}`}
    >
      <div className="tw-flex tw-flex-col tw-gap-4px">
        <span className="tw-font-semibold">{title}</span>
        <span className="tw-font-normal">{text}</span>
      </div>
      {onClick && (
        <Button
          size={size}
          schema="secondary"
          onClick={onClick}
          isLoading={btnDisabled}
          className={style === "success" ? "!tw-text-semantic-green-100 !tw-border-semantic-green-100" : ""}
        >
          <span className="tw-text-nowrap">{btnText}</span>
        </Button>
      )}
      {allowClose && (
        <XmarkLargeSolidV6 onClick={() => setIsShown(false)} size="m" className={`tw-cursor-pointer`} />
      )}
    </div>
  ) : null;
};

const Outcome = ({ outcome, setOutcome }) => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-[8px]">
      <FieldLabel label="Call Outcome" isRequired />
      <div className="tw-flex tw-flex-wrap tw-gap-[6px]">
        {communicationOutcomes.map((option) => (
          <FilterPill
            key={option.value}
            selected={option.value === outcome}
            onClick={() => setOutcome(option.value === outcome ? null : option.value)}
            size="medium"
            variant="primary"
          >
            {option.label}
          </FilterPill>
        ))}
      </div>
    </div>
  );
};

const Notes = ({ notes, setNotes }) => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-[8px]">
      <FieldLabel label="Notes" />
      <textarea
        className="tw-h-[116px] tw-resize-none tw-bg-[#f5f7f7] tw-border tw-rounded tw-border-solid tw-border-gray-15"
        value={notes}
        onChange={(e) => setNotes(e.target?.value || "")}
      />
    </div>
  );
};

import React, { useState } from "react";
import Proptypes from "prop-types";
import { Popover, PopoverContent, PopoverTrigger } from "@shared/v2/Popover/Popover";
import colors from "../../PersonDetail/components/colors.module.css";
import statusIntentCss from "../../PersonDetail/components/statusIntentDropdown.module.css";

const EXCLUDED_STATUSES = ["active-client", "prospective-client", "brivity-user", "non-compete"];

const Status = ({ disabled, person }) => {
  const [show, setShow] = useState(false);
  const statusLists = Object.entries(person.status_options).map(([key, value]) =>
    key === "past" ? ["past", "past"] : [key, value],
  );

  // TODO: there is a cleaner way to do this..
  const changePastName = (str) => (str === "past-client" ? "past" : str);
  const [status, setStatus] = useState(person.person.status);
  const statusClass = (stat) => {
    switch (stat) {
      case "new":
        return colors.statusNew;
      case "unqualified":
        return colors.statusUnqual;
      case "hot":
        return colors.statusHot;
      case "watch":
        return colors.statusWatch;
      case "nurture":
        return colors.statusNurt;
      case "past-client":
        return colors.statusPast;
      case "past":
        return colors.statusPast;
      case "inactive":
        return colors.statusInactive;
      case "trash":
        return colors.statusTrash;
      case "archived":
        return colors.statusArchived;
      case "pending":
        return colors.statusPending;
      case "active-client":
        return colors.statusActiveClient;
      case "prospective-client":
        return colors.statusProspectiveClient;
      default:
        return colors.statusOther;
    }
  };

  const handleSelect = (index) => {
    const newStatus = statusLists[index][0];
    setShow(false);
    const param = {
      status: newStatus,
    };
    const archivedFilter = window.location.search.match("archived") !== null;
    const inactiveFilter = window.location.search.match("inactive") !== null;
    const trashFilter = window.location.search.match("trash") !== null;
    window.App.leadUpdateChannel.perform("lead_update", {
      id: person.person_lead_id,
      person_attributes: { ...param },
      archived_filter: archivedFilter,
      inactive_filter: inactiveFilter,
      trash_filter: trashFilter,
    });
    setStatus(statusLists[index][1]);
  };

  return (
    <div className="tw-relative tw-flex tw-justify-center status-dd">
      <Popover open={show} onOpenChange={setShow}>
        <PopoverTrigger onClick={!EXCLUDED_STATUSES.includes(status) ? () => setShow((v) => !v) : undefined}>
          <button
            type="button"
            disabled={disabled}
            className={`focus:tw-outline-none tw-text-[11px] tw-py-[7px] tw-rounded-full tw-h-[24px] tw-w-[120px] tw-leading-4 tw-font-bold	tw-inline-block tw-bg-transparent ${changePastName(
              status,
            )} tw-border-0 ${statusClass(status)}
        ${show && statusIntentCss.active}`}
          >
            <div className={statusIntentCss.statusIntentButton}>
              <span
                className={`${!EXCLUDED_STATUSES.includes(status) ? statusIntentCss.selectedStatusIntentText : ""} ${disabled ? "after:tw-content-none" : ""}`}
              >
                {status === "prospective-client" ? "PROSPECTIVE" : status.replace("-", " ").toUpperCase()}
              </span>
            </div>
          </button>
        </PopoverTrigger>
        <PopoverContent className="tw-z-3000 tw-gap-[8px] tw-p-[12px]">
          {statusLists.map(([, value], index) => (
            <button
              key={value}
              type="button"
              className={`focus:tw-outline-none tw-border-0 tw-bg-transparent tw-text-[11px] tw-py-[7px] tw-rounded-full tw-h-[24px] tw-w-[120px] tw-leading-4	tw-font-bold	tw-inline-block ${value} ${statusClass(value)}`}
              onClick={() => handleSelect(index)}
            >
              {value.replace("-", " ").toUpperCase()}
            </button>
          ))}
        </PopoverContent>
      </Popover>
    </div>
  );
};

Status.propTypes = {
  disabled: Proptypes.bool,
  person: Proptypes.shape({
    status_options: Proptypes.shape({}),
    person_lead_id: Proptypes.number,
    person: Proptypes.shape({ status: Proptypes.string }),
  }),
};

Status.defaultProps = {
  disabled: false,
  person: null,
};

export default Status;

import React from 'react';

const defaultOrImage = (avatar, name) => {
  if (avatar.match(/^https:/)) {
    return <img className="circled" src={avatar} alt={name} />
  } else {
    return <span className="circled user-image default-avatar">{avatar}</span>;
  }
};

export { defaultOrImage }

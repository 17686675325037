export const valueOrDash = (value) => value || "\u2014";

export const prospectNameColor = (prospect) => {
  if (!prospect.phone) {
    return "tw-text-gray-30";
  }

  if (prospect.isMyLead) {
    return "tw-text-theme-primary";
  }

  return "tw-text-gray-75";
};

export const SearchTypeConfig = {
  expireds: {
    domOrList: "DOM",
    domOrListField: "daysOnMarket",
    showAllPersonsColumn: true,
    showDaysOnMarketColumn: true,
    showStatusFilter: true,
    dateName: "Status Date",
    typeName: "Expired",
    dateDescription: "expired",
  },
  fsbos: {
    domOrList: "Publish Date",
    domOrListField: "statusDateDisplay",
    showAllPersonsColumn: false,
    showDaysOnMarketColumn: false,
    showStatusFilter: false,
    dateName: "Publish Date",
    typeName: "FSBO",
    dateDescription: "was listed",
  },
};

import React from "react";
import Dropdown from "@shared/v2/Dropdown";
import Tooltip from "@shared/v2/Tooltip";
import { CircleQuestionSolidV6 } from "@icons";
import RadioButton from "./RadioButton";
import { caseTransformingAxios as axios } from "../../shared/v2/caseTransformingAxios";
import { Modal, Form, Row, Col, FormGroup } from "react-bootstrap";

const SEARCH_RADIUS = [
  { label: "Smart Radius", value: "0" },
  { label: "0.5 mile radius", value: "0.5" },
  { label: "1.0 mile radius", value: "1.0" },
  { label: "1.5 mile radius", value: "1.5" },
  { label: "2.0 mile radius", value: "2.0" },
  { label: "3.0 mile radius", value: "3.0" },
  { label: "4.0 mile radius", value: "4.0" },
  { label: "5.0 mile radius", value: "5.0" },
  { label: "10.0 mile radius", value: "10.0" },
  { label: "25.0 mile radius", value: "25.0" },
  { label: "50.0 mile radius", value: "50.0" },
  { label: "100.0 mile radius", value: "100.0" },
];

const DRIP_FREQUENCY = [
  { label: "Never", value: "never" },
  { label: "Every week", value: "every_week" },
  { label: "Every 2 weeks", value: "every_2_weeks" },
  { label: "Every Month", value: "every_month" },
  { label: "Quarterly", value: "quarterly" },
  { label: "Annually", value: "annually" },
];

class BulkReportsOptionsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      dripFrequency: DRIP_FREQUENCY,
      selectedDripFrequency: "every_2_weeks",
      selectedRadio: "create",
      mlses: [],
      selectedMls: "",
      propertyTypes: [],
      searchRadius: SEARCH_RADIUS,
      selectedSearchRadius: "0",
      hasCMAAccount: false,
    };
    this.radioGroupHandler = this.radioGroupHandler.bind(this);
  }

  componentDidMount() {
    this.getMlsListForUser();
    this.checkUserHasCMA();
  }

  handleMlsChange = (option) => {
    let mlsId = option.value || "";
    this.setState({ selectedMls: mlsId });
    this.getPropertyTypes(mlsId);
  };

  handlePropertyTypeChange = (option) => {
    this.setState({ selectedPropertyType: option.value || "" });
  };

  handleSearchRadiusChange = (option) => {
    this.setState({ selectedSearchRadius: option.value || "" });
  };

  handleDripFrequencyChange = (option) => {
    this.setState({ selectedDripFrequency: option.value || "" });
  };

  radioGroupHandler(val) {
    this.setState({ selectedRadio: val });
  }

  checkUserHasCMA = () => {
    axios.get("/bulk_market_reports/has_cma_account").then((res) => {
      this.setState({ hasCmaAccount: res.data.hasCmaAccount, isLoading: false });
    });
  };

  getPropertyTypes = (mlsId) => {
    const path = `/bulk_market_reports/mls_property_types`;
    axios.get(path, { params: { mlsId: mlsId } }).then((res) => {
      let defaultValue = res.data[0].value;
      for (const pt of res.data) {
        if (pt.value.toLowerCase().startsWith("detached")) {
          defaultValue = pt.value;
          break;
        } else if (pt.value.toLowerCase().startsWith("single")) {
          defaultValue = pt.value;
          break;
        } else if (pt.value.toLowerCase().startsWith("resi")) {
          defaultValue = pt.value;
          break;
        }
      }
      this.setState({ propertyTypes: res.data, selectedPropertyType: defaultValue });
    });
  };

  getMlsListForUser = () => {
    const path = `/bulk_market_reports/mlses`;
    axios.get(path).then((res) => {
      this.setState({ mlses: res.data, selectedMls: res.data[0].value });
      this.getPropertyTypes(res.data[0].value);
    });
  };

  saveDialog = () => {
    const data = {
      mls_data_source_id: this.state.selectedMls,
      property_type: this.state.selectedPropertyType,
      radius: this.state.selectedSearchRadius,
      recurring_frequency: this.state.selectedDripFrequency,
    };
    this.props.showStatusModal(data, this.state.selectedRadio);
  };

  renderCreate = () => {
    return (
      this.state.selectedRadio === "create" && (
        <div>
          <div className="info-alert alert alert-secondary">
            <span>
              {`${this.props.validAddressCount} of the ${this.props.selectedLeads.length}`} selected contacts
              have valid contact info to create a Market Report
            </span>
            <Tooltip
              className="tw-ml-[6px] tw-align-middle"
              placement="top"
              trigger={<i className="fa fa-question-circle brivity-blue" />}
              content="A contact needs an email address and a physical home address in order to meet the criteria for this feature"
            />
          </div>
          <p>
            To mass create Market Reports, select an MLS, search radius, property type, and drip frequency,
            and Brivity CMA will create and send individual Market Reports for each contact based on their
            home address.
          </p>
          <Row>
            <Col xs={6}>
              <FormGroup>
                <Dropdown
                  label="MLS"
                  isClearable={false}
                  name="mlslist"
                  onChange={this.handleMlsChange}
                  options={this.state.mlses}
                  placeholder="MLS"
                  value={this.state.mlses.find((m) => m.value === this.state.selectedMls)}
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <Dropdown
                  label={
                    <>
                      Search Radius
                      <Tooltip
                        trigger={
                          <CircleQuestionSolidV6 size="l" className="tw-text-theme-primary tw-ml-[4px]" />
                        }
                        innerClassName="tw-max-w-[250px]"
                        content="Smart radius automatically expands the Market Report radius around the address until there are at least 15 search results, or you can manually set the search radius"
                        placement="top"
                      />
                    </>
                  }
                  isClearable={false}
                  name="searchRadius"
                  onChange={this.handleSearchRadiusChange}
                  options={this.state.searchRadius}
                  placeholder="Search Radius"
                  value={this.state.searchRadius.find((s) => s.value === this.state.selectedSearchRadius)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FormGroup>
                <Dropdown
                  label="Property Type"
                  isClearable={false}
                  name="propertyType"
                  onChange={this.handlePropertyTypeChange}
                  options={this.state.propertyTypes}
                  placeholder="Property Types"
                  value={this.state.propertyTypes.find((t) => t.value === this.state.selectedPropertyType)}
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <Dropdown
                  label={
                    <>
                      Market Report Drip Frequency
                      <Tooltip
                        trigger={
                          <CircleQuestionSolidV6 size="l" className="tw-text-theme-primary tw-ml-[4px]" />
                        }
                        innerClassName="tw-max-w-[250px]"
                        content="How often the dynamic Market Report will be emailed to the contacts"
                        placement="top"
                      />
                    </>
                  }
                  isClearable={false}
                  name="dripFrequency"
                  onChange={this.handleDripFrequencyChange}
                  options={this.state.dripFrequency}
                  placeholder="Drip Frequency"
                  value={this.state.dripFrequency.find((d) => d.value === this.state.selectedDripFrequency)}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      )
    );
  };

  renderChange = () => {
    return (
      this.state.selectedRadio === "change" && (
        <div>
          <div className="info-alert alert alert-secondary">
            <span>
              {`${this.props.validReportCount} of the ${this.props.selectedLeads.length}`} selected contacts
              have one or more Market Report
            </span>
          </div>
          <p>Change the Market Report drip email frequency for contacts with existing reports.</p>
          <Row>
            <Col xs={6}>
              <FormGroup>
                <Dropdown
                  label={
                    <>
                      Market Report Drip Frequency
                      <Tooltip
                        trigger={
                          <CircleQuestionSolidV6 size="l" className="tw-text-theme-primary tw-ml-[4px]" />
                        }
                        innerClassName="tw-max-w-[250px]"
                        content="How often the dynamic Market Report will be emailed to the contacts"
                        placement="top"
                      />
                    </>
                  }
                  isClearable={false}
                  name="dripFrequency"
                  onChange={this.handleDripFrequencyChange}
                  options={this.state.dripFrequency}
                  placeholder="Drip Frequency"
                  value={this.state.dripFrequency.find((d) => d.value === this.state.selectedDripFrequency)}
                />
              </FormGroup>
            </Col>
            <Col xs={6}></Col>
          </Row>
        </div>
      )
    );
  };

  renderBulkModal = () => {
    return (
      <Modal className="modal" backdrop="static" show onHide={this.props.closeOptionsModal}>
        <Form name="bulk">
          <Modal.Header closeButton>
            <Modal.Title>Market Report Actions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Market Reports are one of the best ways to nurture seller leads and your sphere by showing them
              what's going on in the real estate market in their neighborhood.
            </p>
            <Row>
              <Col xs={6}>
                <RadioButton
                  label="Create Market Reports"
                  name="create"
                  selected={this.state.selectedRadio === "create"}
                  radioGroupHandler={this.radioGroupHandler}
                />
              </Col>
              <Col xs={6}>
                <RadioButton
                  label="Change Drip Frequency"
                  name="change"
                  selected={this.state.selectedRadio === "change"}
                  radioGroupHandler={this.radioGroupHandler}
                />
              </Col>
            </Row>
            {this.renderCreate()}
            {this.renderChange()}
          </Modal.Body>
          <Modal.Footer>
            <div className="pull-left">
              <button onClick={this.props.closeOptionsModal} type="button" className="modal-cancel">
                Cancel
              </button>
            </div>
            <button
              disabled={
                (this.state.selectedRadio === "create" && this.props.validAddressCount < 1) ||
                (this.state.selectedRadio === "change" && this.props.validReportCount < 1)
              }
              onClick={this.saveDialog}
              type="button"
              className="modal-submit"
            >
              Save
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  renderSupportModal = () => {
    return (
      <Modal className="modal" backdrop="static" show onHide={this.props.closeOptionsModal}>
        <Form name="bulk">
          <Modal.Header closeButton>
            <Modal.Title>Oops!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Looks like you don't have a CMA Account setup yet.</p>
            <p>
              Please reach out to Support at
              (855) 427-4848.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="pull-right">
              <button onClick={this.props.closeOptionsModal} type="button" className="modal-cancel">
                Close
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  renderSpinnerModal = () => {
    return (
      <Modal className="modal" backdrop="static" show onHide={this.props.closeOptionsModal}>
        <Form name="bulk">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <i className="fa fa-pulse fa-spinner" />
          </Modal.Body>
          <Modal.Footer>
            <div className="pull-right">
              <button onClick={this.props.closeOptionsModal} type="button" className="modal-cancel">
                Close
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  render() {
    this.modal = this.state.hasCmaAccount ? this.renderBulkModal() : this.renderSupportModal();
    return this.state.isLoading ? this.renderSpinnerModal() : this.modal;
  }
}
export default BulkReportsOptionsModal;

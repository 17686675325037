const sizeDictionary = {
  s: "tw-w-8px tw-h-8px",
  m: "tw-w-12px tw-h-12px",
  l: "tw-w-16px tw-h-16px",
  xl: "tw-w-20px tw-h-20px",
  xxl: "tw-w-24px tw-h-24px",
  xxxl: "tw-w-32px tw-h-32px",

  // Sizes should be defined by the caller, through the `className` prop
  custom: "",

  // Should generally be avoided, in favor of the design system sizes above
  legacyInherit: "tw-w-1em tw-h-1em",
};

export const availableSizes = Object.keys(sizeDictionary);

export const sizeClasses = (size) => sizeDictionary[size] || "";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Popover, PopoverContent, PopoverItem, PopoverTrigger } from "@shared/v2/Popover";
import Snackbar from "@shared/v2/Snackbar";
import { EllipsisSolidV6 } from "@shared/v2/Icomoon";
import IconButton from "@shared/v2/IconButton";

const CmaReportMenu = ({ deleteReportFn, id }) => {
  const [showCopiedLink, setShowCopiedLink] = useState(false);

  useEffect(() => {
    if (showCopiedLink) {
      const timeoutId = setTimeout(() => setShowCopiedLink(false), 3500);
      return () => clearTimeout(timeoutId);
    }

    // Always return a function, even if it does nothing when showCopiedLink is false
    return () => {};
  }, [showCopiedLink]);

  const host = process.env.NODE_ENV === "production" ? "www.brivitycma.com" : "www.staging.brivitycma.com";
  const previewUrl = `https://${host}/reports/${id}/preview`;
  const editUrl = `https://${host}/reports/build/${id}`;
  const openPreviewFn = () => window.open(previewUrl, "_blank");
  const editReportFn = () => window.open(editUrl, "_blank");
  const shareReportFn = () => navigator.clipboard.writeText(previewUrl).then(() => setShowCopiedLink(true));
  const dropdownLinks = [
    { key: "edit", onClick: editReportFn, icon: "pencil", text: "Edit" },
    { key: "preview", onClick: openPreviewFn, icon: "eye", text: "Preview" },
    { key: "share", onClick: shareReportFn, icon: "link", text: "Copy Link" },
    { key: "delete", onClick: deleteReportFn, icon: "trash", text: "Delete" },
  ];

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <IconButton schema="tertiary" size="small" aria-label="menu">
          <EllipsisSolidV6 />
        </IconButton>
      </PopoverTrigger>

      <PopoverContent className="!tw-z-[3001]">
        {dropdownLinks.map((link) => (
          <PopoverItem key={link.key} onClick={link.onClick}>
            <i className={`tw-mr-[8px] fa fa-${link.icon}`} />
            {link.text}
          </PopoverItem>
        ))}
      </PopoverContent>
      <Snackbar position="top" show={showCopiedLink} text="Link copied" schema="executed" />
    </Popover>
  );
};

CmaReportMenu.propTypes = {
  deleteReportFn: PropTypes.func,
  id: PropTypes.string,
};

CmaReportMenu.defaultProps = {
  deleteReportFn: null,
  id: null,
};

export default CmaReportMenu;

import React from "react";
import Proptypes from "prop-types";
import Dropdown from "@shared/v2/Dropdown";
import { useOnMinMaxSelectedChange, useMinMaxState } from "../../hookHelpers";
import { hasMinMaxError } from "../../helpers";
import { useDebounce } from "../../../shared/hookHelpers";

const consecutiveYearOptionsUpToCurrentYear = (startYear) => {
  const yearOptions = [];
  for (let currentYear = new Date().getFullYear(); currentYear >= startYear; currentYear -= 1) {
    yearOptions.push({ label: currentYear.toString(), value: currentYear.toString() });
  }
  return yearOptions;
};
const baseOptions = [
  ...consecutiveYearOptionsUpToCurrentYear(2005),
  {
    label: "2000",
    value: "2000",
  },
  {
    label: "1990",
    value: "1990",
  },
  {
    label: "1980",
    value: "1980",
  },
  {
    label: "1970",
    value: "1970",
  },
  {
    label: "1960",
    value: "1960",
  },
  {
    label: "1950",
    value: "1950",
  },
  {
    label: "1940",
    value: "1940",
  },
  {
    label: "1930",
    value: "1930",
  },
  {
    label: "1920",
    value: "1920",
  },
  {
    label: "1910",
    value: "1910",
  },
  {
    label: "1900",
    value: "1900",
  },
  {
    label: "1800",
    value: "1800",
  },
];
const minOptions = [{ label: "No min", value: null }, ...baseOptions];
const maxOptions = [{ label: "No max", value: null }, ...baseOptions];

const YearBuilt = ({ min, max, onChange }) => {
  const [selectedMin, onSelectMin, selectedMax, onSelectMax] = useMinMaxState(min, max);
  const hasError = hasMinMaxError(selectedMin, selectedMax);
  const debouncedMin = useDebounce(selectedMin, 500);
  const debouncedMax = useDebounce(selectedMax, 500);
  useOnMinMaxSelectedChange(debouncedMin, debouncedMax, hasError, onChange);

  return (
    <div data-cy="year-built-wrapper" className="tw-mb-[4px]">
      <span className="tw-text-base tw-text-gray-50 tw-block tw-mb-[8px]">Year Built</span>
      <div className="tw-flex tw-gap-[24px]">
        <Dropdown
          containerClassName="tw-flex-1"
          name="min_year"
          value={minOptions.find((o) => o.value === selectedMin)}
          options={minOptions}
          isClearable={false}
          isSearchable={false}
          placeholder="No Min"
          onChange={(opt) => onSelectMin(opt.value)}
        />
        <Dropdown
          containerClassName="tw-flex-1"
          error={hasError ? "Max must be greater than min" : ""}
          name="max_year"
          value={maxOptions.find((o) => o.value === selectedMax)}
          options={maxOptions}
          isSearchable={false}
          isClearable={false}
          placeholder="No Max"
          onChange={(opt) => onSelectMax(opt.value)}
        />
      </div>
    </div>
  );
};
export default YearBuilt;

YearBuilt.propTypes = {
  min: Proptypes.string,
  max: Proptypes.string,
  onChange: Proptypes.func,
};

YearBuilt.defaultProps = {
  min: undefined,
  max: undefined,
  onChange: () => {},
};

import React from "react";
import { Outlet } from "react-router";
import Typography from "@shared/v2/Typography";
import TabButton from "./TabButton";

const WebsiteIndex = () => (
  <div className="tw-h-full tw-flex tw-flex-col">
    <div className="tw-flex tw-gap-[40px] tw-px-[32px] tw-py-[8px] tw-border-b-[1px] tw-border-x-0 tw-border-t-0 tw-border-solid tw-border-neutral-gray-10 tw-h-[57px]">
      <Typography variant="headline1">Website</Typography>
      <div className="tw-self-end tw-flex tw-gap-[12px]">
        <TabButton active>Blog</TabButton>
      </div>
    </div>
    <div className="tw-flex-1 tw-bg-gray-5 tw-overflow-auto">
      <Outlet />
    </div>
  </div>
);

export default WebsiteIndex;

import { useSelector } from "react-redux";

export const useCurrentUser = () => useSelector((state) => state.layout.currentUser);
export const useCurrentSuperUser = () => useSelector((state) => state.layout?.currentSuperUser);
export const useFeatureFlags = () => useSelector((state) => state.layout.featureFlags);
export const useTheme = () => useSelector((state) => state.layout.theme);
export const useDialerServiceUrl = () => useSelector((state) => state.layout.apiUrls.dialerServiceUrl);
export const useCallRecordingSettings = () =>
  useSelector((state) => state.layout.currentUser?.callRecordingSettings);
export const useAccountCallRecordingLoading = () =>
  useSelector((state) => state.layout.accountCallRecordingLoading);
export const useAccountCallRecordingSettings = () =>
  useSelector((state) => state.layout.currentUser.account?.callRecordingSettings);
export const useIsAdmin = () => useSelector((state) => state.layout.currentUser.account?.isAdmin);
export const useAcccountUuid = () => useSelector((state) => state.layout.currentUser.account?.uuid);

export const useOutboundCallRecordingSetting = () => {
  const callRecordingSettings = useCallRecordingSettings();
  const accountCallRecordingSettings = useAccountCallRecordingSettings();
  const outboundRecording =
    accountCallRecordingSettings?.outboundRecording === "by_number"
      ? callRecordingSettings?.outboundRecording || "disabled"
      : accountCallRecordingSettings?.outboundRecording;
  const outboundNotificationMessage = accountCallRecordingSettings?.outboundNotificationMessage ?? false;

  return { outboundRecording, outboundNotificationMessage };
};

export const useMessagingServiceUrl = () => useSelector((state) => state.layout.apiUrls.messagingServiceUrl);
export const useSupportPhoneNumber = () => useSelector((state) => state.layout.support.phoneNumber);

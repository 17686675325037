import PropTypes from 'prop-types';
import React from 'react';
import css from './QuickSearch.module.css';

const Result = ({ result, cursor, index, onHover, saveRecentSearch }) => {
  const itemClass = cursor === index ? css.searchResultItemActive : css.searchResultItem;

  const handleMouseDown = () => {
    saveRecentSearch(result);
    window.location.replace(result.path);
  };

  return (
    <div
      id={cursor === index ? 'searchResultItemActive' : 'searchResultItem'}
      className={itemClass}
      data-slug={result.slug}
      data-item-id={result.id}
      onMouseDown={handleMouseDown}
      onMouseOver={(e) => onHover(index, e)}
    >
      <i className={`${result.icon} ${result.color}`}></i>
      {result.label}
      <span className={result.color}> ({result.status} {result.type})</span>
    </div>
  );
};

Result.propTypes = {
  result: PropTypes.object,
  onHover: PropTypes.func,
};

export default Result;

/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { connect } from "react-redux";
import { shape, arrayOf } from "prop-types";

import Event from "../Event";

const Events = ({ activeEvents = [] }) => (
  <div data-cy="tdp-timeline-events">
    {activeEvents.length ? (
      activeEvents.map((event) => <Event {...event} key={event.id} />)
    ) : (
      <div
        data-cy="tdp-timeline-empty-events"
        className="tw-text-14px tw-text-gray-50 tw-w-full tw-h-[68px] tw-flex tw-items-center tw-justify-center"
      >
        No events to show
      </div>
    )}
  </div>
);

const mapStateToProps = (state) => ({
  activeEvents: state.tdpTimelineReducer.activeEvents,
});

Events.propTypes = {
  activeEvents: arrayOf(shape({})),
};

Events.defaultProps = {
  activeEvents: [],
};

export default connect(mapStateToProps)(Events);

import React from "react";
import PropTypes from "prop-types";
import capitalize from "capitalize";

const LocationBubble = ({ location, type, removeLocation }) => (
  <div className="location-bubble">
    <input
      className="filter-bubble-checkbox"
      type="checkbox"
      name={`leads_filter[selected_website_history_filters][${type}][]`}
      id={`leads_filter_selected_website_history_filters_${type}_${location}`}
      value={location}
      checked="checked"
    />
    <div className="removable-bubble">
      <span>{capitalize(type)}: </span>
      <label
        className="info-label"
        htmlFor={`leads_filter_selected_website_history_filters_${type}_${location}`}
      >
        {location}
      </label>
      {/* eslint-disable-next-line  jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
      <i onClick={() => removeLocation(type, location)} className="fa fa-remove" />
    </div>
  </div>
);

LocationBubble.propTypes = {
  location: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  removeLocation: PropTypes.func.isRequired,
};

export default LocationBubble;

import React from "react";
import Proptypes from "prop-types";
import { Modal } from "react-bootstrap";
import HeaderTitle from "../HeaderTitle";
import AdPlan from "./AdPlan";

const PlanModal = ({ planId, modalOpen, closeModal }) => (
  <>
    <Modal
      className="modal"
      backdrop="static"
      show={modalOpen}
      onHide={closeModal}
      data-cy="marketing-advertising-plan-modal"
    >
      <Modal.Header className="tw-pb-0 tw-px-[32px] tw-pt-[32px]">
        <Modal.Title className="tw-pb-[12px]  tw-border-0">
          <HeaderTitle title="Advertising Plan" onClose={closeModal} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="tw-pt-0 tw-px-[32px] tw-pb-[32px]">
        <AdPlan planId={planId} closeModal={closeModal} />
      </Modal.Body>
    </Modal>
  </>
);

PlanModal.propTypes = {
  modalOpen: Proptypes.bool,
  closeModal: Proptypes.func,
  planId: Proptypes.number,
};
PlanModal.defaultProps = {
  modalOpen: false,
  closeModal: null,
  planId: null,
};
export default PlanModal;

import React from "react";

import { AtSolidV6 } from "@shared/v2/Icomoon";

import ActivitiesContainer from "./ActivitiesContainer";
import { activityCommonDefaultProps, activityCommonPropTypes } from "../shared";

const NoteMentionActivity = ({ activity, onReadChange }) => {
  const { note } = activity.data;

  return (
    <ActivitiesContainer
      activity={activity}
      onReadChange={onReadChange}
      Icon={AtSolidV6}
      title={activity.title}
    >
      Mention: {note}
    </ActivitiesContainer>
  );
};

export default NoteMentionActivity;

NoteMentionActivity.propTypes = {
  ...activityCommonPropTypes,
};

NoteMentionActivity.defaultProps = {
  ...activityCommonDefaultProps,
};

/* eslint-disable no-underscore-dangle */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import FieldSelect from "../../../shared/UtilsV1/FieldSelect";
import FieldSelectWithCategoryOptionsWithSearchableDropdown from "../../../shared/UtilsV1/FieldSelectWithCategoryOptionsWithSearchableDropdown";
import FieldTextV1 from "../../../shared/UtilsV1/FieldTextV1";
import RemoveFieldButton from "../../../shared/v2/RemoveFieldButton";

function AddressView({
  removable,
  currentAddress,
  index,
  setPersonValue,
  options,
  addressList,
  stateOptions,
}) {
  const { addressType, city, postalCode, streetAddress, locality } = currentAddress;

  const optionsForValueMatch = useMemo(
    () =>
      [].concat(
        ...stateOptions.map((category) =>
          category.options.map((option) => ({ label: option.value, value: option.value })),
        ),
      ),
    [stateOptions],
  );

  const onRemove = (targetAddress) => {
    let filteredAddressList = addressList;
    if (Object.hasOwnProperty.call(targetAddress, "id")) {
      filteredAddressList = addressList.map((address) =>
        address === targetAddress ? { ...address, _destroy: true } : address,
      );
    } else {
      filteredAddressList = addressList.filter((address) => address !== targetAddress);
    }

    setPersonValue({ key: "addressesAttributes", value: filteredAddressList });
  };

  const updateAddress = (targetAddress, value) =>
    setPersonValue({
      key: "addressesAttributes",
      value: addressList.map((address) => {
        if (address !== targetAddress) return address;
        return { ...address, ...value };
      }),
    });

  return currentAddress._destroy ? null : (
    <div className={`tw-flex tw-flex-col tw-gap-[24px] ${index !== 0 ? "tw-mt-[24px]" : ""}`}>
      <div className="tw-flex tw-gap-2 tw-flex-1">
        <FieldSelect
          label="Address Type"
          options={options}
          onChange={(newValue) => updateAddress(currentAddress, { addressType: newValue })}
          value={addressType}
          placeholder="Choose One"
        />

        {removable && (
          <RemoveFieldButton onClick={() => onRemove(currentAddress)} className="tw-mt-auto tw-mb-[1px]" />
        )}
      </div>
      <FieldTextV1
        label="Street Address"
        onChange={(value) => updateAddress(currentAddress, { streetAddress: value })}
        value={streetAddress}
      />
      <div className="tw-flex tw-gap-6">
        <FieldTextV1
          label="City"
          onChange={(value) => updateAddress(currentAddress, { city: value })}
          value={city}
        />
        <div className="tw-flex tw-gap-3">
          <FieldSelectWithCategoryOptionsWithSearchableDropdown
            label="State / Province"
            options={[...stateOptions]}
            optionsForValueMatch={optionsForValueMatch}
            onChange={(value) => updateAddress(currentAddress, { locality: value })}
            value={locality}
            placeholder="Select"
            searchable
          />
          <FieldTextV1
            label="ZIP / Postal Code"
            onChange={(value) => updateAddress(currentAddress, { postalCode: value })}
            value={postalCode}
          />
        </div>
      </div>
      <hr className="tw-w-full tw-border-gray-10 tw-m-0" />
    </div>
  );
}
const addressPropType = PropTypes.shape({
  addressType: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  locality: PropTypes.string,
  streetAddress: PropTypes.string,
  postalCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.number,
  _destroy: PropTypes.bool,
});
AddressView.propTypes = {
  removable: PropTypes.bool,
  currentAddress: addressPropType,
  index: PropTypes.number,
  setPersonValue: PropTypes.func.isRequired,
  addressList: PropTypes.arrayOf(addressPropType),
  stateOptions: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
    }),
  ).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

AddressView.defaultProps = {
  removable: false,
  currentAddress: null,
  index: null,
  addressList: [],
  options: [],
};
export default AddressView;

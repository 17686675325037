import React, { useEffect, useMemo } from "react";

const BrivityTextEditor = ({ className, id, placeholders, ...props }) => {
  const dataPlaceholders = useMemo(() => JSON.stringify(placeholders), [placeholders]);

  useEffect(() => {
    const editor = tinyMCE.createEditor(id, tinyMCE.settings);
    editor.render();
    return () => {
      tinyMCE.execCommand("mceRemoveEditor", false, id);
      tinyMCE.editors = [];
    };
  }, []);

  return (
    <div className={className}>
      <textarea {...props} id={id} className="tinymce" data-placeholders={dataPlaceholders} />
      <input id="file-upload" className="hidden" name="image" type="file" />
    </div>
  );
};

export default BrivityTextEditor;

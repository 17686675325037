import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import Tooltip from "../Tooltip";
import { Info } from "../Icomoon";

const FieldLabel = forwardRef(
  (
    { label, isRequired, isDisabled, className, toolTipInfoContent, toolTipPlacement, ...otherProps },
    ref,
  ) => (
    <label
      ref={ref}
      className={`
      tw-flex tw-items-center tw-gap-[4px] tw-m-0
      tw-text-14d tw-font-semibold
      ${isDisabled ? "tw-text-neutral-gray-30" : "tw-text-neutral-gray-50"}
      ${className}
    `}
      {...otherProps}
    >
      {label}
      {isRequired && <span className="tw-text-brivity-coral-100">*</span>}
      {toolTipInfoContent && (
        <Tooltip
          content={toolTipInfoContent}
          trigger={<Info size="m" className="tw-text-theme-datepicker-text-color" />}
          placement={toolTipPlacement}
        />
      )}
    </label>
  ),
);

FieldLabel.propTypes = {
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  toolTipInfoContent: PropTypes.node,
  toolTipPlacement: PropTypes.string,
};

FieldLabel.defaultProps = {
  isRequired: false,
  isDisabled: false,
  className: "",
  toolTipInfoContent: null,
  toolTipPlacement: null,
};

export default FieldLabel;

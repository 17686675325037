import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Proptypes from "prop-types";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button/Button";
import { removeCommas } from "@shared/v2/inputFormatUtils";
import {
  handleDocumentUpload,
  handleAgreementCreate,
  fetchAvailableTransactions,
  loadPropertyTypes,
  loadSources,
  loadRoles,
  loadAllAvailableRoles,
  createTransactions,
} from "../../../actions/personDetailActionCreators";
import UploadAgreement from "./UploadAgreement";
import CreateTransaction from "./CreateTransaction";
import TransactionsField from "./TransactionsField";
import AssociatedTransaction from "./AssociatedTransaction";
import SignedorExpirationDate from "./SignedorExpirationDate";
import { useCurrentUser } from "../../../../reducers/layoutReducer/selectors";

const removeFirstObjIfKeyIsZero = (array) => {
  if (array.length > 0 && array[0].value === 0) {
    return array.slice(1); // Remove the first element
  }
  return array; // Return the original array if condition not met
};

const findIdByTitle = (client, title) => {
  const clientObj = client.find((item) => item.title === title);
  return clientObj ? clientObj.id : null;
};

const AddAgreementModal = ({
  agreementName,
  modalOpen,
  closeModal,
  person,
  apptAddHandler,
  uploadingDocument,
  availableTransactions,
}) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const [uploadedFile, setUploadedFile] = useState(null);
  const [signedDate, setSignedDate] = useState("");
  const [expirationDate, setExpirationDate] = useState("");

  const [isCreateTransaction, setIsCreateTransaction] = useState(false);

  // transaction fields
  const [transactionTitle, setTransactionTitle] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [sources, setSources] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [closePrice, setClosePrice] = useState("");
  const [commission, setCommission] = useState("");
  const [isCommissionPercentage, setIsCommissionPercentage] = useState(true);
  const [propertyType, setPropertyType] = useState("");
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [primaryAgent, setPrimaryAgent] = useState(null);
  const [primaryAgents, setPrimaryAgents] = useState([]);
  const [referralFee, setReferralFee] = useState("25");
  const [isReferralFeePercentage, setIsReferralFeePercentage] = useState(true);
  const [referralAgent, setReferralAgent] = useState("");
  const [client, setClient] = useState("");
  const [clientIntent, setClientIntent] = useState("buyer");

  const [associatedTransaction, SetAssociatedTransaction] = useState(null);
  const [roles, setRoles] = useState([]);

  const loadDependencies = async () => {
    const [sources, propertyTypes, roleData, allRoles] = await Promise.all([
      loadSources(),
      loadPropertyTypes(),
      loadRoles(agreementName, currentUser.person.id),
      loadAllAvailableRoles(),
    ]);
    const defaultPropertyType = (propertyTypes || []).find((p) => p.label === "Residential") || {
      label: "Residential",
      value: -1,
    };
    setAllRoles(allRoles);
    setSources(sources);
    setPropertyTypes(propertyTypes);
    setPropertyType(defaultPropertyType);
    setPrimaryAgent(roleData.agentsOnTransaction?.length > 0 ? roleData.agentsOnTransaction[0] : null);
    setRoles(roleData.roles);

    setReferralAgent({
      label: currentUser.fullName,
      value: currentUser.person.id,
    });
  };

  useEffect(() => {
    if (isCreateTransaction && sources.length <= 0) {
      loadDependencies();
    }
  }, [isCreateTransaction]);

  useEffect(() => {
    if (primaryAgent && roles.length > 0 && primaryAgents?.length <= 0) {
      setPrimaryAgents(
        removeFirstObjIfKeyIsZero(roles.find((role) => role.value === primaryAgent.roleId)?.agents),
      );
    }
  }, [primaryAgent, roles]);

  useEffect(() => {
    if (person.data?.id) dispatch(fetchAvailableTransactions(person.data.attributes.uuid));
  }, [person.data?.id]);

  const handleHide = () => {
    closeModal();
    setUploadedFile(null);
  };

  const handleToggleTransaction = () => {
    if (isCreateTransaction) {
      setTransactionTitle("");
      setSelectedSource("");
      setClosePrice("");
      setCommission("");
      setIsCommissionPercentage(true);
      setReferralFee("25");
      setIsReferralFeePercentage(true);
      setReferralAgent({
        label: currentUser.fullName,
        value: currentUser.person.id,
      });
      setClient("");
      setClientIntent("buyer");
    }
    setIsCreateTransaction(!isCreateTransaction);
  };

  const handleAddAgreement = async () => {
    const payloadData = {
      agreement_type: agreementName,
      signed_at: signedDate,
      expired_at: expirationDate,
    };
    let getRoleId = null;
    if (agreementName === "buyer") {
      getRoleId = findIdByTitle(allRoles, "Buyer");
    } else if (agreementName === "seller") {
      getRoleId = findIdByTitle(allRoles, "Seller");
    } else {
      // its referral
      if (clientIntent === "buyer") {
        getRoleId = findIdByTitle(allRoles, "Buyer");
      } else if (clientIntent === "seller") {
        getRoleId = findIdByTitle(allRoles, "Seller");
      } else if (clientIntent === "tenant") {
        getRoleId = findIdByTitle(allRoles, "Renter");
      } else {
        // its landlord
        getRoleId = findIdByTitle(allRoles, "Landlord");
      }
    }
    const transactionData = {
      selectedAgreementName: agreementName,
      propertyType: propertyType,
      closePrice: closePrice ? Number(removeCommas(closePrice)) : null,
      transactionTitle: transactionTitle,
      source: selectedSource,
      client_id: client?.value,
      clientIntent: clientIntent,
      referringAgent: referralAgent?.value,
      referralFee: referralFee,
      primaryAgent: primaryAgent,
      commission: commission,
      commission_percentage: isCommissionPercentage,
      referralFeeIsPercentage: isReferralFeePercentage,
      personId: person.data?.id,
      personRoleId: getRoleId,
    };
    const [listing_id] = isCreateTransaction
      ? await Promise.all([dispatch(createTransactions(transactionData))])
      : [associatedTransaction?.value || null];

    if (!uploadedFile) {
      const agreementData = {
        ...payloadData,
        document_id: null,
        listing_id,
        isCreateTransaction,
      };
      dispatch(handleAgreementCreate(person.data.attributes.uuid, agreementData));
      handleHide();
    } else {
      dispatch(
        handleDocumentUpload(
          uploadedFile,
          person.data.attributes,
          true,
          { ...payloadData, listing_id, isCreateTransaction },
          true,
        ),
      );
      handleHide();
    }
    apptAddHandler();
  };

  const documentUpload = (event) => {
    if (event.target.files[0]) {
      setUploadedFile(event.target.files[0]);
    }
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer.files[0]) {
      setUploadedFile(event.dataTransfer.files[0]);
    }
  };

  const isDisabledbtn = () => {
    return (
      uploadingDocument ||
      (isCreateTransaction &&
        (agreementName === "buyer" || agreementName === "seller"
          ? !transactionTitle?.trim() || !selectedSource || Object.keys(selectedSource).length <= 0
          : !transactionTitle?.trim() || !client || Object.keys(client).length <= 0))
    );
  };

  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      // 636px = 616px from Figma + 10px for each horizontal padding (20px)
      dialogClassName="tw-w-[636px] tw-h-auto"
      contentClassName="tw-w-full tw-h-full"
      backdrop="static"
      show={modalOpen}
      onHide={handleHide}
      data-cy="add-agreement-modal"
    >
      <Modal.Header titleClassName="tw-mb-32px tw-text-neutral-gray-75" title="Add Agreement" closeButton />

      <Modal.Body className="tw-mb-32px tw-max-h-[70vh] tw-overflow-auto">
        <UploadAgreement
          handleFileDrop={handleFileDrop}
          handleDocumentUpload={documentUpload}
          uploadedFile={uploadedFile}
          selectedAgreementName={agreementName}
        />
        <SignedorExpirationDate
          signedDate={signedDate}
          expirationDate={expirationDate}
          setSignedDate={setSignedDate}
          setExpirationDate={setExpirationDate}
        />

        <hr className="tw-border-t tw-border-solid tw-border-gray-10" />

        <CreateTransaction
          handleToggleTransaction={handleToggleTransaction}
          isCreateTransaction={isCreateTransaction}
          selectedAgreementName={agreementName}
        />
        {isCreateTransaction ? (
          <TransactionsField
            selectedAgreementName={agreementName}
            transactionTitle={transactionTitle}
            setTransactionTitle={setTransactionTitle}
            selectedSource={selectedSource}
            setSelectedSource={setSelectedSource}
            sources={sources}
            propertyTypes={propertyTypes}
            closePrice={closePrice}
            setClosePrice={setClosePrice}
            commission={commission}
            setCommission={setCommission}
            propertyType={propertyType}
            setPropertyType={setPropertyType}
            referralFee={referralFee}
            setReferralFee={setReferralFee}
            referralAgent={referralAgent}
            setReferralAgent={setReferralAgent}
            client={client}
            setClient={setClient}
            clientIntent={clientIntent}
            setClientIntent={setClientIntent}
            isCommissionPercentage={isCommissionPercentage}
            setIsCommissionPercentage={setIsCommissionPercentage}
            isReferralFeePercentage={isReferralFeePercentage}
            setIsReferralFeePercentage={setIsReferralFeePercentage}
            primaryAgents={primaryAgents}
            setPrimaryAgent={setPrimaryAgent}
            primaryAgent={primaryAgent}
          />
        ) : (
          <AssociatedTransaction
            availableTransactions={availableTransactions}
            associatedTransaction={associatedTransaction}
            SetAssociatedTransaction={SetAssociatedTransaction}
          />
        )}
      </Modal.Body>

      <Modal.Footer className="tw-flex tw-justify-between">
        <Button
          onClick={handleHide}
          className="tw-px-[20px] tw-py-[8px] tw-flex tw-items-center"
          schema="tertiary"
          size="medium"
          data-cy="documents-upload-cancel-button"
        >
          CANCEL
        </Button>
        <Button
          className="tw-flex tw-items-center"
          size="medium"
          schema="primary"
          disabled={isDisabledbtn()}
          onClick={handleAddAgreement}
          data-cy="documents-upload-done-button"
        >
          ADD
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddAgreementModal.propTypes = {
  modalOpen: Proptypes.bool,
  closeModal: Proptypes.func,
  agreementName: Proptypes.string,
  person: Proptypes.shape({}),
  apptAddHandler: Proptypes.func,
};
AddAgreementModal.defaultProps = {
  modalOpen: false,
  closeModal: null,
  agreementName: "buyer",
  person: {},
  apptAddHandler: () => {},
};
export default AddAgreementModal;

import React from "react";
import PropTypes from "prop-types";
import Toggle from "@shared/v2/Toggle";
import Tooltip from "@shared/v2/Tooltip";
import { CircleInfoSolidV6 } from "@shared/v2/Icomoon";

const PlayMessageToggle = ({ checked, name, onChange }) => (
  <div className="tw-flex tw-gap-[16px]">
    <Toggle
      text={
        <span className="tw-flex tw-gap-[4px]">
          Play notification message{" "}
          <Tooltip
            multiline
            trigger={<CircleInfoSolidV6 />}
            innerClassName="tw-max-w-[180px]"
            content="A message informing the caller will play automatically when recording starts or stops."
          />
        </span>
      }
      checked={checked}
      name={name}
      onChange={onChange}
    />
    {/* TODO: wiereup message
    <Button schema="tertiary">
      <PlaySolidV6 size="s" /> Message
    </Button> */}
  </div>
);

PlayMessageToggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PlayMessageToggle;

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import Alert from "@shared/v2/Alert";
import Dropdown from "@shared/v2/Dropdown";
import FieldLabel from "@shared/v2/FieldLabel";
import { MagnifyingGlassSolidV6 } from "@shared/v2/Icomoon";
import ContactCard from "./ContactCard";
import ContactContainer from "./ContactContainer";
import {
  useContactMergeOtherContact,
  useContactMergePotentialDuplicates,
  useContactMergePrimaryContact,
} from "../reducers/contactMergeReducer/selectors";
import * as actions from "../reducers/contactMergeReducer";
import useQuickSearch from "../Dashboard/services/useQuickSearch";

const ChooseContact = () => {
  const dispatch = useDispatch();
  const potentialDuplicates = useContactMergePotentialDuplicates();
  const primaryContact = useContactMergePrimaryContact();
  const otherContact = useContactMergeOtherContact();
  const [search, setSearch] = useState("");
  const [searchContact, setSearchContact] = useState(null);
  const { data, error, loading } = useQuickSearch({ model: "Person", search });
  const people = useMemo(() => data.filter((p) => !p.loginUserId && p.status !== "trash"), [data]);

  useEffect(() => {
    if (!searchContact) return () => {};
    const abortController = new AbortController();

    caseTransformingAxios
      .get(`/people/${searchContact.slug}/mergable_details`, {
        signal: abortController.signal,
      })
      .then(({ data: contact }) => dispatch(actions.setOtherContact(contact)))
      .catch(console.error);

    return () => abortController.abort();
  }, [searchContact]);

  const showSearchedContact = searchContact && otherContact && searchContact.slug === otherContact.slug;

  return (
    <div className="tw-flex tw-flex-col tw-gap-[24px] tw-overflow-hidden">
      <p className="tw-m-0 tw-text-gray-50">
        Select a second lead to initiate the merge process. Leads with an identical mobile phone number or
        email address to the lead on the left will be suggested for merging.
      </p>
      <div className="tw-flex tw-gap-[12px] tw-items-start tw-overflow-hidden">
        <ContactContainer title="Merge this lead">
          <ContactCard contact={primaryContact} />
        </ContactContainer>

        <ContactContainer className="tw-self-stretch" title="With this lead">
          <div className="tw-flex tw-flex-col">
            <FieldLabel className="tw-uppercase !tw-text-11d !tw-mb-[4px] !tw-font-bold" label="Search" />
            {showSearchedContact && (
              <ContactCard
                contact={otherContact}
                showViewLink
                selected
                onRemove={() => {
                  setSearchContact(null);
                  dispatch(actions.setOtherContact(null));
                }}
              />
            )}
            {!showSearchedContact && (
              <Dropdown
                error={error}
                inputTrailing={<MagnifyingGlassSolidV6 size="l" className="tw-text-gray-30" />}
                isLoading={loading}
                isSearchable
                shouldFilterOptions={false}
                onChange={setSearchContact}
                onInputChange={setSearch}
                options={people}
                placeholder="By name, email, or phone"
              />
            )}
          </div>

          <div className="tw-min-h-[1px] tw-bg-gray-10" />

          <h5 className="tw-uppercase tw-text-11d tw-font-bold tw-text-neutral-gray-50 tw-mb-[-8px] tw-mt-0">
            Suggested
          </h5>

          {potentialDuplicates.length === 0 && (
            <Alert
              variant="info"
              text="It appears there are no duplicate people.  Perhaps there is a ‘trash’ or ‘archived’ lead that has not been merged with the same contact information."
            />
          )}
          {potentialDuplicates.map((c) => (
            <ContactCard
              key={c.id}
              contact={c}
              showViewLink
              disabled={primaryContact.hasListings && c.hasListings}
              onSelect={(oc) => dispatch(actions.setOtherContact(oc))}
              selected={c.id === otherContact?.id}
            />
          ))}
        </ContactContainer>
      </div>
    </div>
  );
};

export default ChooseContact;

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import WebsiteIndex from "./WebsiteIndex";
import BlogIndex from "./BlogIndex";

const WebsiteApp = () => {
  const store = ReactOnRails.getStore("layoutStore");

  return (
    <Provider store={store}>
      <BrowserRouter basename="/website">
        <Routes>
          <Route path="/" element={<WebsiteIndex />}>
            <Route index element={<Navigate to="blog" replace />} />
            <Route path="blog" element={<BlogIndex />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

export default WebsiteApp;

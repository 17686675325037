import React, { Component } from "react";
import axios from "axios";
import Proptypes from "prop-types";
import { PlusSolidV6 } from "@shared/v2/Icomoon";
import AiAssistant from "@shared/AiAssistant";
import Alert from "@shared/v2/Alert";
import Button from "@shared/v2/Button";
import Dropdown from "@shared/v2/Dropdown";
import FieldLabel from "@shared/v2/FieldLabel";
import Modal from "@shared/v2/Modal";
import TextInput from "@shared/v2/TextInput";
import RoleForTransaction from "./RoleForTransaction";
import TransactionAutocomplete from "./TransactionAutocomplete";
import modalCss from "../PersonDetail/components/modal.module.css";

export const TRANSACTION_STATUS = [
  { label: "Pipeline", value: "pipeline" },
  { label: "Coming Soon", value: "coming soon" },
  { label: "Active", value: "active" },
  { label: "Pending", value: "pending" },
  { label: "Closed", value: "sold" },
  { label: "Expired", value: "expired" },
  { label: "Withdrawn", value: "withdrawn" },
  { label: "Canceled", value: "canceled" },
  { label: "Archived", value: "archived" },
];

const ERROR_LIST = {
  transactionTitle: "Transaction Title can't be blank",
  streetAddress: "Street Address can't be blank",
  postalCode: "ZIP / Postal Code can't be blank",
  source: "Source is not included in the list",
};

class ListingTransactionModal extends Component {
  constructor(props) {
    super(props);
    const { defaultPropertyType, propertyTypes, defaultStatus } = props;
    this.state = {
      mlsIdsState: [],
      streetAddress: "",
      propertyType: defaultPropertyType,
      propertyTypesState: propertyTypes,
      source: null,
      expirationDate: undefined,
      postalCode: "",
      price: "",
      mlsNumber: "",
      status: defaultStatus || { label: "Pipeline", value: "pipeline" },
      beds: "",
      baths: "",
      sqFt: "",
      description: "",
      photos: [],
      city: "",
      state: "",
      transactionTitle: "",
      addRole: false,
      availableRoles: [],
      primaryPosition: 0,
      agentsOnTransaction: [],
      submitDisabled: false,
      validationErrorHtml: "",
      closePrice: null,
    };
  }

  componentDidMount = () => {
    this.loadDependencies();
  };

  loadDependencies = async () => {
    const promises = [this.loadRoles(), this.loadMls()];

    await Promise.all(promises);
  };

  loadRoles = async () => {
    const { transactionType } = this.props;

    const path =
      transactionType === "BuyerListing"
        ? "/roles/pending_transaction_roles"
        : "/roles/listing_transaction_roles";

    const response = await axios.get(path, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    let defaultRoles = response.data.default_roles;
    const { roles } = response.data;

    // Reorder listing or buyer's agent role to be first
    if (transactionType === "BuyerListing") {
      const buyersAgentRole = roles.find((role) => role.label === "Buyer's Agent");
      defaultRoles = [
        buyersAgentRole,
        ...defaultRoles.filter((defaultRole) => defaultRole.label !== "Buyer's Agent"),
      ];
    } else {
      const listingAgentRole = roles.find((role) => role.label === "Listing Agent");
      defaultRoles = [
        listingAgentRole,
        ...defaultRoles.filter((defaultRole) => defaultRole.label !== "Listing Agent"),
      ];
    }

    // Undefined shows up if the account doesn't have "Buyer's Agent" or "Listing Agent"
    defaultRoles = defaultRoles.filter((defaultRole) => defaultRole !== undefined);

    this.setState({
      availableRoles: roles.filter(
        (role) => !defaultRoles.some((defaultRole) => role.value === defaultRole.value),
      ),
      agentsOnTransaction: defaultRoles.map((role, index) => ({
        ...(role.agents[0].label.includes("Role Default") ? role.agents[0] : null),
        roleId: role.value,
        visible: "false",
        primary: index === 0 ? "true" : "false",
      })),
      roles,
    });
  };

  loadMls = async () => {
    const { currentUserId } = this.props;
    const { mlsIdsState } = this.state;
    if (mlsIdsState.length) {
      return;
    }
    // This route throws a 500 when there are no MLS IDs which...shouldn't happen
    // So this try/catch is necessary for an otherwise normal response
    try {
      const response = await axios.get(`/users/${currentUserId}/mls_ids`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      this.setState({ mlsIdsState: response.data.ids });
    } catch (err) {
      console.log("Error loading MLS IDs:", err);
    }
  };

  onListingFieldUpdate = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  handleVisibilitySelect = (visible, roleId) => {
    const { agentsOnTransaction } = this.state;
    const newAgentsOnTransaction = agentsOnTransaction.slice();
    const agent = newAgentsOnTransaction.find((a) => a.roleId === roleId);
    if (agent) {
      agent.visible = visible;
      this.setState({ agentsOnTransaction: newAgentsOnTransaction });
    }
  };

  handleAgentSelect = (agentId, roleId) => {
    const { agentsOnTransaction } = this.state;
    const newAgentsOnTransaction = agentsOnTransaction.slice();
    const agent = newAgentsOnTransaction.find((a) => a.roleId === roleId);
    if (agent) {
      agent.value = agentId;
      this.setState({ agentsOnTransaction: newAgentsOnTransaction });
    }
  };

  handleSourceSelect = (value) => {
    this.setState({ source: value });
  };

  handlePropertyTypeSelect = (propertyType) => {
    this.setState({ propertyType });
  };

  handleExpirationDateChange = (date) => {
    this.setState({ expirationDate: date });
  };

  onRoleSelect = ({ value }) => {
    const { roles, agentsOnTransaction } = this.state;
    let defaultAgent = roles.find((role) => role.value === value).agents[0];
    defaultAgent = defaultAgent.label.includes("Role Default") ? defaultAgent : null;
    const newAgentRole = { ...defaultAgent, visible: "false", roleId: value, primary: "false" };

    if (!agentsOnTransaction.length) {
      newAgentRole.primary = "true";
    }

    this.setState((prevState) => ({
      addRole: false,
      availableRoles: prevState.availableRoles.filter((r) => r.value !== value),
      agentsOnTransaction: [...prevState.agentsOnTransaction, newAgentRole],
    }));
  };

  handleRemoveRole = (roleId) => {
    const { roles, agentsOnTransaction } = this.state;
    const matchingRole = roles.find((role) => role.value === roleId);
    const matchingAgent = agentsOnTransaction.find((agent) => agent.roleId === roleId);
    const newAgentsOnTransaction = agentsOnTransaction.filter((agent) => agent.roleId !== roleId);

    if (matchingAgent.primary === "true" && newAgentsOnTransaction.length) {
      newAgentsOnTransaction[0].primary = "true";
    }

    this.setState((prevState) => ({
      availableRoles: [...prevState.availableRoles, matchingRole],
      agentsOnTransaction: newAgentsOnTransaction,
    }));
  };

  setPrimaryAgent = (roleId) => {
    const { agentsOnTransaction } = this.state;
    const newAgentsOnTransaction = agentsOnTransaction.slice();
    // eslint-disable-next-line no-restricted-syntax
    for (const agent of newAgentsOnTransaction) {
      agent.primary = String(agent.roleId) === String(roleId) ? "true" : "false";
    }

    this.setState({ agentsOnTransaction: newAgentsOnTransaction });
  };

  clearListing = () => {
    this.setState({
      selectedBlossorId: "",
    });
  };

  isPipeline = () => {
    const { status } = this.state;
    return status.value === "pipeline";
  };

  selectListing = async (term) => {
    const response = await axios.get(`/listings_api?blossor_id=${term.key}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const listingPropertyType = response.data.standardPropertyType || response.data.propertyType;
    const { propertyTypesState } = this.state;
    const selectedPropertyType = propertyTypesState.find((pt) => pt.label === listingPropertyType) || {
      label: listingPropertyType,
      value: -1,
    };

    this.setState((prevState) => ({
      selectedBlossorId: term.key,
      streetAddress: response.data.streetAddress,
      postalCode: response.data.postalCode || prevState.postalCode,
      price: response.data.price || prevState.price,
      propertyTypesState:
        selectedPropertyType.value === -1
          ? [...prevState.propertyTypesState, selectedPropertyType]
          : prevState.propertyTypesState,
      propertyType: selectedPropertyType,
      mlsNumber: response.data.mlsNum,
      beds: response.data.bedrooms || prevState.beds,
      baths: response.data.bathsTotalDecimal || prevState.baths,
      sqFt: response.data.squareFeet || prevState.sqFt,
      description: response.data.remarks,
      photos: response.data.photos,
      city: response.data.city,
      state: response.data.state,
    }));
  };

  handleStatusSelect = (status) => {
    this.setState({ status });
  };

  isInvalidSubmit = () => {
    const {
      status: { value },
      transactionTitle, // pipeline
      streetAddress,
      postalCode,
      source, // pipeline && non
    } = this.state;

    const htmlErrors = [];

    if (value === "pipeline") {
      if (!transactionTitle.trim()) {
        htmlErrors.push(ERROR_LIST.transactionTitle);
      }
    } else {
      if (!streetAddress.trim()) {
        htmlErrors.push(ERROR_LIST.streetAddress);
      }
      if (!postalCode.trim()) {
        htmlErrors.push(ERROR_LIST.postalCode);
      }
    }

    if (!source) {
      htmlErrors.push(ERROR_LIST.source);
    }

    if (htmlErrors.length) {
      const htmlToShow = (
        <Alert
          containerClass="[&_dl]:tw-mb-0"
          variant="error"
          text={
            <div
              className="tw-flex tw-flex-col tw-gap-[8px]"
              dangerouslySetInnerHTML={{
                __html: `<dl><dt>${htmlErrors.join("</dt></dl><dl><dt>")}</dl></dt>`,
              }}
            />
          }
        />
      );
      this.setState({ submitDisabled: false, validationErrorHtml: htmlToShow });
      return true;
    }

    return false;
  };

  handleSubmit = async (publish) => {
    if (this.isInvalidSubmit()) return;
    const { transactionType, newMilestoneId } = this.props;
    this.setState({ submitDisabled: true });
    const {
      propertyType,
      status,
      price,
      mlsNumber,
      transactionTitle,
      closePrice,
      expirationDate,
      streetAddress,
      city,
      state,
      postalCode,
      agentsOnTransaction,
      photos,
      beds,
      baths,
      sqFt,
      source,
      description,
    } = this.state;
    // Create property type if it doesn't already exist
    if (propertyType.value === -1) {
      const propertyTypeFormData = new FormData();
      propertyTypeFormData.append("utf8", "✓");
      propertyTypeFormData.append("authenticity_token", ReactOnRails.authenticityToken());
      propertyTypeFormData.append("property_type[name]", propertyType.label);

      const response = await axios.post("/property_types.json", propertyTypeFormData);

      this.setState({ propertyType: { label: response.data.name, value: response.data.id } });
    }

    const formData = new FormData();

    const listingClosePrice = `listing[${status.value === "sold" ? "close_price" : "est_close_price"}]`;

    formData.append("utf8", "✓");
    formData.append("authenticity_token", ReactOnRails.authenticityToken());

    formData.append("listing[type]", transactionType);
    formData.append("property_type", propertyType.value);
    formData.append("listing[price]", price);
    formData.append("listing[mls_number]", mlsNumber);
    formData.append("listing[status]", status.value);
    formData.append("listing[title]", transactionTitle);
    formData.append(listingClosePrice, closePrice);

    formData.append("listing[milestonable_milestones_attributes][0][new_milestone_id]", newMilestoneId);
    formData.append("listing[milestonable_milestones_attributes][0][date]", expirationDate);

    formData.append("listing[address_attributes][street_address]", streetAddress);
    formData.append("listing[address_attributes][city]", city);
    formData.append("listing[address_attributes][locality]", state);
    formData.append("listing[address_attributes][postal_code]", postalCode);
    formData.append("listing[address_attributes][address_type]", "listing");

    for (let i = 0; i < agentsOnTransaction.length; i += 1) {
      const agent = agentsOnTransaction[i];

      formData.append(`listing[team_members_attributes][${agent.value}${i}][person_id]`, agent.value);
      formData.append(`listing[team_members_attributes][${agent.value}${i}][role_id]`, agent.roleId);
      formData.append(
        `listing[team_members_attributes][${agent.value}${i}][visible_to_public]`,
        agent.visible,
      );
      formData.append(`listing[team_members_attributes][${agent.value}${i}][primary]`, agent.primary);
    }

    for (let i = 0; i < photos.length; i += 1) {
      formData.append("photo_urls[]", photos[i].url);
    }

    formData.append("add_new_role_id", "");

    formData.append("listing[details_attributes][][name]", "beds");
    formData.append("listing[details_attributes][][value]", beds);

    formData.append("listing[details_attributes][][name]", "baths");
    formData.append("listing[details_attributes][][value]", baths);

    formData.append("listing[details_attributes][][name]", "sqft");
    formData.append("listing[details_attributes][][value]", sqFt);

    formData.append("listing[details_attributes][][_destroy]", false);

    formData.append("listing[source_id]", source?.value);
    formData.append("listing[description]", description);
    formData.append("listing[published]", publish && transactionType !== "pending");

    if (transactionType === "BuyerListing") {
      formData.append("listing[only_pending_info]", "true");
    }

    try {
      await axios.post("/listings/validate_new_listing_steps", formData);
      this.setState({ validationErrorHtml: "" });
    } catch (error) {
      // Well this is scary...
      const html = error.response.data.content;
      const htmlToShow = (
        <Alert
          containerClass="[&_dl]:tw-mb-0"
          variant="error"
          text={
            <div className="tw-flex tw-flex-col tw-gap-[8px]" dangerouslySetInnerHTML={{ __html: html }} />
          }
        />
      );
      this.setState({ submitDisabled: false, validationErrorHtml: htmlToShow });
      return;
    }

    try {
      const createResponse = await axios.post("/listings", formData);
      window.location.replace(createResponse.data);
    } catch (error) {
      this.setState({ submitDisabled: false });
    }
  };

  render() {
    const { propertyTypes, transactionType, closeModal, mlsIds, sources } = this.props;
    const {
      mlsIdsState,
      streetAddress,
      propertyType,
      source,
      postalCode,
      price,
      mlsNumber,
      status,
      beds,
      baths,
      sqFt,
      description,
      city,
      state,
      transactionTitle,
      roles,
      primaryPosition,
      agentsOnTransaction,
      validationErrorHtml,
      closePrice,
      addRole,
      availableRoles,
      submitDisabled,
      selectedBlossorId,
    } = this.state;
    return (
      <Modal
        id="listing-transaction-modal"
        className="person-details-modal tw-flex tw-items-center tw-justify-center"
        contentClassName="tw-max-w-[640px] tw-w-[100vw] tw-max-h-[85vh] tw-flex tw-flex-col tw-gap-[32px]"
        show
        onHide={closeModal}
      >
        <Modal.Header
          title="Add Listing Information"
          description={
            mlsIdsState.length ? "Import a listing or fill out the information below manually." : ""
          }
          closeButton
        />
        <Modal.Body
          data-cy="listing-create-modal-0"
          className="tw-flex-1 tw-flex tw-flex-col tw-gap-[24px] tw-overflow-y-auto tw-px-[8px]"
        >
          <div className={mlsIdsState.length === 0 ? "hidden" : ""}>
            <TransactionAutocomplete
              mlsIds={mlsIds}
              selectedBlossorId={selectedBlossorId}
              selectListing={this.selectListing}
              clearListing={this.clearListing}
              listingData={this.state}
            />
            <hr className={modalCss.divider} />
          </div>

          <div className="tw-flex tw-gap-[24px]">
            <Dropdown
              containerClassName="tw-flex-1"
              label="Transaction Status"
              options={TRANSACTION_STATUS}
              value={status}
              onChange={this.handleStatusSelect}
              isRequired
              menuShouldComeToFront
            />
            <TextInput
              containerClassName="tw-flex-1"
              label="Transaction Title"
              name="transactionTitle"
              value={transactionTitle}
              onChange={this.onListingFieldUpdate}
              isRequired
            />
          </div>

          <div className="tw-flex tw-gap-[24px]">
            <Dropdown
              label="Property Type"
              containerClassName="tw-flex-1"
              isRequired
              options={propertyTypes}
              value={propertyType}
              onChange={this.handlePropertyTypeSelect}
              menuShouldComeToFront
            />
            <TextInput
              containerClassName="tw-flex-1"
              label="Street Address"
              name="streetAddress"
              value={streetAddress}
              onChange={this.onListingFieldUpdate}
            />
          </div>

          <div className="tw-flex tw-gap-[24px]">
            <TextInput
              containerClassName="tw-flex-1"
              label="City"
              name="city"
              value={city}
              onChange={this.onListingFieldUpdate}
            />
            <TextInput
              containerClassName="tw-flex-1"
              label="State / Province"
              name="state"
              value={state}
              onChange={this.onListingFieldUpdate}
            />
          </div>

          <div className="tw-flex tw-gap-[24px]">
            <TextInput
              containerClassName="tw-flex-1"
              label="Zip / Postal Code"
              name="postalCode"
              value={postalCode}
              onChange={this.onListingFieldUpdate}
            />
            <TextInput
              containerClassName="tw-flex-1"
              label="MLS #"
              name="mlsNumber"
              value={mlsNumber}
              onChange={this.onListingFieldUpdate}
            />
          </div>

          <div className="tw-flex tw-gap-[24px]">
            <TextInput
              containerClassName="tw-flex-1"
              label="Listing Price"
              name="price"
              value={price}
              onChange={this.onListingFieldUpdate}
            />
            <TextInput
              containerClassName="tw-flex-1"
              label={status.value === "sold" ? "Close Price" : "Estimated Close Price"}
              name="closePrice"
              value={closePrice}
              onChange={this.onListingFieldUpdate}
            />
          </div>

          <div className="tw-flex tw-gap-[24px]">
            <TextInput
              containerClassName="tw-flex-1"
              label="Beds"
              name="beds"
              value={beds}
              onChange={this.onListingFieldUpdate}
            />
            <TextInput
              containerClassName="tw-flex-1"
              label="Baths"
              name="baths"
              value={baths}
              onChange={this.onListingFieldUpdate}
            />
          </div>

          <div className="tw-flex tw-gap-[24px]">
            <TextInput
              containerClassName="tw-flex-1"
              label="SqFt"
              name="sqFt"
              value={sqFt}
              onChange={this.onListingFieldUpdate}
            />
            <Dropdown
              isRequired
              label="Source"
              containerClassName="tw-flex-1"
              isSearchable
              options={sources}
              value={source}
              onChange={this.handleSourceSelect}
              menuShouldComeToFront
            />
          </div>

          <div>
            <div className="tw-flex tw-justify-between tw-items-end tw-mb-[8px]">
              <FieldLabel label="Listing Description" />
              <AiAssistant
                textareaId="transaction-description-input"
                messageType="Generic"
                onInsertClick={(text) =>
                  this.onListingFieldUpdate({ target: { name: "description", value: text } })
                }
              />
            </div>
            <TextInput
              id="transaction-description-input"
              multiline
              name="description"
              value={description}
              onChange={this.onListingFieldUpdate}
              rows="6"
            />
          </div>

          {agentsOnTransaction.map((agent, position) => (
            <RoleForTransaction
              key={agent.roleId}
              role={roles.find((role) => role.value === agent.roleId)}
              visible={agent.visible}
              selectedAgentId={agent.value}
              position={position}
              primaryPosition={primaryPosition}
              primary={agent.primary}
              showVisibility={transactionType !== "BuyerListing"}
              setPrimaryAgent={this.setPrimaryAgent}
              handleAgentSelect={this.handleAgentSelect}
              handleVisibilitySelect={this.handleVisibilitySelect}
              handleRemoveRole={this.handleRemoveRole}
            />
          ))}

          {addRole && (
            <div>
              <Dropdown
                label="Role"
                isSearchable
                options={availableRoles}
                onChange={this.onRoleSelect}
                menuShouldComeToFront
              />
            </div>
          )}
          <div>
            <Button onClick={() => this.setState((prevState) => ({ addRole: !prevState.addRole }))}>
              Add Role <PlusSolidV6 size="s" />
            </Button>
          </div>
        </Modal.Body>

        <Modal.Footer className="tw-flex tw-flex-col tw-gap-[16px]">
          {validationErrorHtml}
          <div className="tw-flex tw-justify-between tw-gap-[8px]">
            <Button
              size="medium"
              schema="tertiary"
              onClick={(e) => {
                e.preventDefault();
                closeModal();
              }}
            >
              Cancel
            </Button>
            <div className="tw-flex tw-gap-[8px]">
              <Button
                size="medium"
                schema="secondary"
                disabled={submitDisabled}
                onClick={() => this.handleSubmit(true)}
              >
                Create Transaction
              </Button>
              <Button size="medium" disabled={submitDisabled} onClick={() => this.handleSubmit(false)}>
                Save as Draft
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ListingTransactionModal;

export const optionShape = Proptypes.shape({
  label: Proptypes.string,
  value: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
});

ListingTransactionModal.propTypes = {
  defaultPropertyType: optionShape,
  propertyTypes: Proptypes.arrayOf(optionShape),
  defaultStatus: optionShape,
  currentUserId: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  transactionType: Proptypes.string,
  closeModal: Proptypes.func,
  sources: Proptypes.arrayOf(optionShape),
  mlsIds: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  newMilestoneId: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
};

ListingTransactionModal.defaultProps = {
  defaultPropertyType: null,
  propertyTypes: [],
  defaultStatus: null,
  currentUserId: null,
  transactionType: "",
  closeModal: null,
  sources: [],
  mlsIds: null,
  newMilestoneId: null,
};

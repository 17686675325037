import React, { useState } from "react";
import { format } from "date-fns";
import { useTheme } from "../../../../reducers/layoutReducer/selectors";
import { activityCommonDefaultProps, activityCommonPropTypes, STYLES } from "../shared";

// this requires layoutReducer/selectors
const ActivitiesContainer = ({
  activity,
  Icon,
  children,
  onReadChange,
  SideContent,
  title,
  queryParams = "",
  childContainerClass = "",
}) => {
  const isPlace = useTheme().name === "place-theme";

  const Tag = activity.personUuid ? "a" : "div";
  const [mouseDownTag, setMouseDownTag] = useState(false);

  return (
    <Tag
      href={
        activity.personUuid
          ? `/people/${activity.personUuid}${queryParams ? `?${queryParams}` : ""}`
          : undefined
      }
      className={`tw-text-brand-primary hover:tw-text-brand-primary tw-group tw-border-solid tw-border-[1px]   tw-rounded-[8px] tw-w-full tw-bg-white tw-p-24px  tw-flex hover:tw-no-underline tw-border-gray-10  
        
        ${isPlace ? "hover:tw-border-[#C8D9FA]" : "hover:tw-border-[#DEF3F3]"}
        ${mouseDownTag ? `${isPlace ? "active:tw-border-[#C8D9FA]" : ""} active:tw-border-[3px] active:tw-p-22px active:tw-no-underline` : ""}`}
      onMouseDown={() => setMouseDownTag(true)}
      onMouseUp={() => setMouseDownTag(false)}
    >
      <Icon size="l" className="tw-mr-8px" />
      <div className={`tw-text-14d tw-mr-24px ${childContainerClass}`}>
        <div className="tw-font-semibold tw-mb-8px">{title}</div>
        {children}
      </div>
      <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-between tw-items-end">
        <div className="tw-min-w-[111px] tw-h-28px tw-flex tw-justify-end">
          <button
            disabled={activity.loading}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onReadChange(e);
            }}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
            type="button"
            className={`tw-hidden group-hover:tw-block tw-mt-[-6px] tw-mr-[-6px] tw-cursor-pointer tw-rounded-[4px] tw-text-11d tw-font-bold tw-whitespace-nowrap tw-shadow-[0px_2px_8px_0px_rgba(213,213,213,0.5)] tw-bg-white tw-p-6px tw-h-28px tw-ml-auto tw-border-[1px] tw-border-solid tw-border-transparent  ${STYLES.textThemed(isPlace)} ${
              isPlace
                ? "hover:tw-border-[#C8D9FA] active:tw-border-[#C8D9FA] active:tw-bg-[#E6EDFA]"
                : "hover:tw-border-[#DEF3F3] active:tw-border-[#DEF3F3] active:tw-bg-blue-10"
            }`}
          >
            {`MARK AS ${activity.read ? "UNREAD" : "READ"}`}
          </button>
          <div
            className={`tw-block group-hover:tw-hidden tw-rounded-full tw-w-8px tw-h-8px tw-ml-auto ${STYLES.bgThemed(isPlace)} ${activity.read ? "tw-hidden" : ""}`}
          />
        </div>
        {SideContent && <SideContent />}
        <div className="tw-text-12d tw-font-nunito tw-text-gray-50">
          {format(`${activity.createdAt}`, "hh:mm aaa")}
        </div>
      </div>
    </Tag>
  );
};

ActivitiesContainer.propTypes = {
  ...activityCommonPropTypes,
};

ActivitiesContainer.defaultProps = {
  ...activityCommonDefaultProps,
};

export default ActivitiesContainer;

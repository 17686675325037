import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Transition } from "react-transition-group";
import Alert from "@shared/v2/Alert";
import Button from "@shared/v2/Button";
import Checkbox from "@shared/v2/Checkbox";
import Dropdown from "@shared/v2/Dropdown";
import Modal from "@shared/v2/Modal";
import TextLink from "@shared/v2/TextLink";
import ShadowScroll from "@shared/v2/ShadowScroll";
import PlayMessageToggle from "./PlayMessageToggle";
import {
  ADMIN_INBOUND_RECORDING_OPTIONS,
  ADMIN_OUTBOUND_RECORDING_OPTIONS,
} from "./helpers/recordingOptions";
import { CustomOption } from "./CallRecordingDropdown";
import {
  useAccountCallRecordingLoading,
  useAccountCallRecordingSettings,
} from "../../reducers/layoutReducer/selectors";
import { updateAccountRecordingSettings } from "../../reducers/layoutReducer";

const transitionStyles = {
  entering: "tw-opactiy-0 tw-max-h-0",
  entered: "tw-opacity-1 tw-max-h-[500px] tw-overflow-visible",
  exiting: "tw-opacity-0 tw-max-h-0",
  exited: "tw-opacity-0 tw-max-h-0 tw-mt-[-8px] tw-overflow-hidden",
};

const TeamCallRecordingSettingsModal = ({ onClose, open }) => {
  const dispatch = useDispatch();
  const loading = useAccountCallRecordingLoading();
  const accountCallRecordingSettings = useAccountCallRecordingSettings();
  const twoPartyRef = useRef(null);
  const [formData, setFormData] = React.useState({});
  const showTwoPartyNotice =
    formData.inboundNotificationMessage === false || formData.outboundNotificationMessage === false;
  const initialAgreeTwoParty =
    accountCallRecordingSettings.inboundNotificationMessage === false ||
    accountCallRecordingSettings.outboundNotificationMessage === false;
  const [agreeTwoParty, setAgreeTwoParty] = React.useState(initialAgreeTwoParty);

  const setFormValue = (optOrEvent, action) => {
    const target = optOrEvent?.target;
    setFormData((prev) => ({
      ...prev,
      [action?.name || target.name]: optOrEvent.value || target.checked,
    }));
  };

  useEffect(() => {
    if (showTwoPartyNotice) setAgreeTwoParty(initialAgreeTwoParty);
  }, [showTwoPartyNotice]);

  useEffect(() => {
    if (accountCallRecordingSettings && open) {
      setFormData(accountCallRecordingSettings);
      setAgreeTwoParty(initialAgreeTwoParty);
    }
  }, [accountCallRecordingSettings, open]);

  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      dialogClassName="tw-max-w-[600px] tw-w-full"
      contentClassName="tw-max-h-[90vh] tw-flex"
      show={open}
      onHide={onClose}
    >
      <form
        className="tw-flex tw-flex-col tw-gap-[32px]"
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(updateAccountRecordingSettings(formData)).then(onClose);
        }}
      >
        <Modal.Header
          closeButton
          title="Team Call Recording Settings"
          description={
            <span>
              These call recording settings will apply to outgoing calls made by all team members from the
              Brivity CRM, Brivity GO, and incoming calls to any Brivity Number. Learn more about{" "}
              <TextLink>recommended settings</TextLink>.
            </span>
          }
        />
        <Modal.Body className="tw-flex-1 tw-overflow-auto tw-flex tw-flex-col tw-gap-[16px]">
          <h3 className="tw-text-18d tw-font-bold tw-m-0">Inbound Calls</h3>
          <Dropdown
            label="Inbound Recording"
            components={{ Option: CustomOption }}
            options={ADMIN_INBOUND_RECORDING_OPTIONS}
            value={ADMIN_INBOUND_RECORDING_OPTIONS.find((o) => o.value === formData.inboundRecording)}
            name="inboundRecording"
            menuShouldComeToFront
            onChange={setFormValue}
          />
          {formData.inboundRecording !== "disabled" && (
            <PlayMessageToggle
              checked={formData.inboundNotificationMessage || false}
              name="inboundNotificationMessage"
              onChange={setFormValue}
            />
          )}
          <h3 className="tw-text-18d tw-font-bold tw-m-0">Outbound Calls</h3>
          <Dropdown
            label="Outbound Recording"
            components={{ Option: CustomOption }}
            options={ADMIN_OUTBOUND_RECORDING_OPTIONS}
            value={ADMIN_OUTBOUND_RECORDING_OPTIONS.find((o) => o.value === formData.outboundRecording)}
            name="outboundRecording"
            menuShouldComeToFront
            onChange={setFormValue}
          />
          {formData.outboundRecording !== "disabled" && (
            <PlayMessageToggle
              checked={formData.outboundNotificationMessage || false}
              name="outboundNotificationMessage"
              onChange={setFormValue}
            />
          )}
          <Transition in={showTwoPartyNotice} nodeRef={twoPartyRef}>
            {(state) => (
              <div
                ref={twoPartyRef}
                className={`tw-transition-all tw-duration-300 tw-ease-linear ${transitionStyles[state]}`}
              >
                <Alert
                  variant="warning"
                  title="Notice: 2-Party Recording Consent Laws"
                  text={
                    <div className="tw-flex tw-flex-col tw-gap-[8px]">
                      <ShadowScroll
                        className="tw-flex tw-flex-col tw-gap-[4px] tw-max-h-[116px]"
                        fromColor="tw-from-semantic-yellow-5"
                      >
                        <div>
                          Certain countries, states, and jurisdictions require the consent of all parties to
                          record telephone conversations. By disabling the notification feature and
                          subsequently recording telephone conversations without providing proper notice and
                          consent, you may be in violation of federal and/or state law.
                        </div>
                        <div>
                          By selecting the &quot;I agree&quot; checkbox, the Brivity account owner agrees
                          without reservation or limitation, to defend, indemnify, and hold harmless Brivity
                          Inc, its parent companies, affiliates, and their respective directors, officers,
                          employees, and agents from and against any and all losses, damages, claims,
                          liabilities, penalties, fines, judgments, settlements, actions, suits, proceedings,
                          litigation, investigations, legal costs and expenses, and demands made by any party
                          due to or arising out of the use of the Brivity Dialer call recording feature by the
                          Brivity account owner and/or his/her/its employees, agents, affiliates, or
                          representatives.
                        </div>
                      </ShadowScroll>
                      <div className="tw-flex tw-gap-[8px] tw-justify-between tw-items-center">
                        <Checkbox
                          label="I agree"
                          labelClassName="!tw-text-inherit"
                          checked={agreeTwoParty}
                          onChange={(e) => setAgreeTwoParty(e.target.checked)}
                        />
                        <Button
                          schema="secondary"
                          size="small"
                          className="!tw-text-semantic-yellow-120 !tw-border-inherit"
                        >
                          Learn More
                        </Button>
                      </div>
                    </div>
                  }
                >
                  Two party consent
                </Alert>
              </div>
            )}
          </Transition>
        </Modal.Body>
        <Modal.Footer className="tw-flex tw-gap-[12px] tw-justify-between">
          <Button size="medium" schema="tertiary" disabled={loading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="medium"
            type="submit"
            isLoading={loading}
            disabled={showTwoPartyNotice && !agreeTwoParty}
          >
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

TeamCallRecordingSettingsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default TeamCallRecordingSettingsModal;

import axios from "axios";
import { caseTransformingAxios } from "../../shared/v2/caseTransformingAxios";

const cache = {};

const cachedToken = () => {
  const exp = JSON.parse(atob(cache.token?.split(".")[1] || "e30=")).exp || 0;
  return (exp - 30) * 1000 > Date.now() ? cache.token : "";
};

const getAiToken = async () => {
  const token = cachedToken();
  if (!token && !cache.promise) {
    cache.promise = caseTransformingAxios
      .post("/api/v4/ai_assist/token")
      .then((res) => {
        cache.promise = null;
        cache.token = res.data.token;
        return res.data.token;
      })
      .catch(() => "");
  }
  return token || cache.promise;
};

const getMessagingApi = (baseURL) => {
  if (cache.api) return cache.api;

  cache.api = axios.create({ baseURL });
  cache.api.interceptors.request.use(async (config) => {
    const token = await getAiToken();
    return {
      ...config,
      paramsSerializer: { indexes: null },
      headers: { ...config.headers, Authorization: `Bearer ${token}` },
    };
  });
  return cache.api;
};

export { getAiToken, getMessagingApi };

import React from "react";
import PropTypes from "prop-types";
import { Col, Row, FormGroup } from "react-bootstrap";
import axios from "axios";
import debounce from "lodash/debounce";
import { Input } from "../../shared";
import { Dropdown } from "../../shared/v1";
import "./Filters.sass";
import LocationBubble from "./LocationBubble";
import LocationResults from "./LocationResults";

const formatPrice = (value) => {
  const parsedValue = parseFloat(value.replace(/,/g, ""));
  return parsedValue.toLocaleString();
};

const radioButtonClass = (isSelected) =>
  isSelected ? "fa fa-dot-circle-o brivity-blue" : "brivity-grey-text fa fa-circle-o";

class WebsiteSearchHistoryFilter extends React.Component {
  constructor(props) {
    super(props);
    const {
      selectedSearchHistory: {
        min_price: minPrice,
        max_price: maxPrice,
        min_bedrooms: minBedrooms,
        max_bedrooms: maxBedrooms,
        min_bathrooms: minBathrooms,
        max_bathrooms: maxBathrooms,
        city,
        zip,
        views_and_favorites: viewsAndFavorites,
        views_only: viewsOnly,
        favorites_only: favoritesOnly,
      },
    } = this.props;

    this.state = {
      minPrice: minPrice || null,
      maxPrice: maxPrice || null,
      minBedrooms: minBedrooms || null,
      maxBedrooms: maxBedrooms || null,
      minBathrooms: minBathrooms || null,
      maxBathrooms: maxBathrooms || null,
      city: city || [],
      zip: zip || [],
      favoritesAndViews: viewsAndFavorites === undefined ? true : viewsAndFavorites === "true",
      viewsOnly: viewsOnly === "true",
      favoritesOnly: favoritesOnly === "true",
      cityStateActive: false,
      cursor: 0,
      cursorCategory: "",
      searchResults: {},
      mlsIds: [],
      locationValue: "",
    };
  }

  componentDidMount() {
    const { currentUserId } = this.props;
    axios
      .get(`/users/${currentUserId}/mls_ids`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        this.setState({ mlsIds: response.data.ids });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleMinPriceChange = (e) => {
    Brivity.ClearAllFiltersButton.toggle();
    this.setState({ minPrice: formatPrice(e.target.value) });
  };

  handleMaxPriceChange = (e) => {
    Brivity.ClearAllFiltersButton.toggle();
    this.setState({ maxPrice: formatPrice(e.target.value) });
  };

  handleMinBedroomsChange = (option) => {
    this.setState({ minBedrooms: option ? option.value : "" });
  };

  handleMaxBedroomsChange = (option) => {
    this.setState({ maxBedrooms: option ? option.value : "" });
  };

  handleMinBathroomsChange = (option) => {
    this.setState({ minBathrooms: option ? option.value : "" });
  };

  handleMaxBathroomsChange = (option) => {
    this.setState({ maxBathrooms: option ? option.value : "" });
  };

  removeLocation = (type, locationToRemove) => {
    const { city, zip } = this.state;
    if (type === "city") {
      const newCities = city.filter((location) => location !== locationToRemove);
      this.setState({ city: newCities });
    } else {
      const newZips = zip.filter((location) => location !== locationToRemove);
      this.setState({ zip: newZips });
    }
  };

  toggleButtons = (button) => {
    if (button === "views_and_favorites") {
      this.setState({ favoritesAndViews: true, viewsOnly: false, favoritesOnly: false });
    } else if (button === "views") {
      this.setState({ favoritesAndViews: false, viewsOnly: true, favoritesOnly: false });
    } else {
      this.setState({ favoritesAndViews: false, viewsOnly: false, favoritesOnly: true });
    }
  };

  toggleActive = () => {
    this.setState((prevState) => ({ cityStateActive: !prevState.cityStateActive }));
  };

  fetchResults = (term) => {
    // Update state on every keypress, but debounce before searching
    this.setState({ locationValue: term }, () => this.debounceFetchResults(term));
  };

  doFetchResults = (term) => {
    const { locationValue, mlsIds } = this.state;
    if (!term.length) {
      return;
    }

    const idsArrayQuery = mlsIds.map((id) => `mlsIds[]=${id}`).join("&");

    axios
      .get(`/lead_filters/location_autocomplete.json?${idsArrayQuery}&term=${term}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (locationValue !== term) {
          return;
        }

        const cityAndZips = response.data.reduce((cAndZs, obj) => {
          const { category } = obj;
          if (
            !Object.prototype.hasOwnProperty.call(cAndZs, category) &&
            (category === "Zip Code" || category === "CityState")
          ) {
            // eslint-disable-next-line no-param-reassign
            cAndZs[category] = [];
          }
          if (category === "Zip Code" || category === "CityState") {
            cAndZs[category].push(obj.value);
          }
          return cAndZs;
        }, {});
        this.setState({ searchResults: cityAndZips });
        if (Object.prototype.hasOwnProperty.call(cityAndZips, "CityState")) {
          this.setState({ cursorCategory: "CityState" });
        } else if (Object.prototype.hasOwnProperty.call(cityAndZips, "Zip Code")) {
          this.setState({ cursorCategory: "Zip Code" });
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  // eslint-disable-next-line react/sort-comp
  debounceFetchResults = debounce((term) => {
    this.doFetchResults(term);
  }, 150);

  handleHover = (cursor, cursorCategory) => {
    this.setState({ cursor, cursorCategory });
  };

  handleSelect = (cursor, cursorCategory) => {
    const { searchResults } = this.state;
    if (cursorCategory === "CityState") {
      this.setState((prevState) => ({
        city: [...prevState.city, searchResults.CityState[cursor]],
        locationValue: "",
        cursor: 0,
        cursorCategory: "",
        searchResults: {},
      }));
    } else if (cursorCategory === "Zip Code") {
      this.setState((prevState) => ({
        zip: [...prevState.zip, searchResults["Zip Code"][cursor]],
        locationValue: "",
        cursor: 0,
        cursorCategory: "",
        searchResults: {},
      }));
    }
  };

  handleKeyDown = (e) => {
    const { cursor, cursorCategory, locationValue, searchResults } = this.state;
    if (e.keyCode === 13) {
      // Enter has been pressed
      e.preventDefault();
      if (cursorCategory === "CityState") {
        this.setState((prevState) => ({
          city: [...prevState.city, searchResults.CityState[cursor]],
        }));
      } else if (cursorCategory === "Zip Code") {
        this.setState((prevState) => ({
          zip: [...prevState.zip, searchResults["Zip Code"][cursor]],
        }));
      }
    }

    if (e.keyCode === 38) {
      // navigate up
      if (cursorCategory === "CityState") {
        if (cursor > 0) {
          this.setState((prevState) => ({ cursor: prevState.cursor - 1 }));
        }
      } else if (cursorCategory === "Zip Code") {
        if (cursor > 0) {
          this.setState((prevState) => ({ cursor: prevState.cursor - 1 }));
        } else if (cursor === 0 && Object.prototype.hasOwnProperty.call(searchResults, "CityState")) {
          this.setState({
            cursor: searchResults.CityState.length - 1,
            cursorCategory: "CityState",
          });
        }
      }
    } else if (e.keyCode === 40) {
      // navigate down
      if (cursorCategory === "CityState") {
        if (cursor < searchResults.CityState.length - 1) {
          this.setState((prevState) => ({ cursor: prevState.cursor + 1 }));
        } else if (
          cursor === searchResults.CityState.length - 1 &&
          Object.prototype.hasOwnProperty.call(searchResults, "Zip Code")
        ) {
          this.setState({ cursor: 0, cursorCategory: "Zip Code" });
        }
      } else if (cursorCategory === "Zip Code") {
        if (cursor < searchResults["Zip Code"].length - 1) {
          this.setState((prevState) => ({ cursor: prevState.cursor + 1 }));
        }
      }
    }
    if (e.keyCode === 8 && locationValue.length === 1) {
      this.setState({ locationValue: "", searchResults: {} });
    }
  };

  render() {
    const {
      minPrice,
      maxPrice,
      minBedrooms,
      maxBedrooms,
      minBathrooms,
      maxBathrooms,
      city,
      zip,
      favoritesAndViews,
      viewsOnly,
      favoritesOnly,
      cityStateActive,
      cursor,
      cursorCategory,
      locationValue,
      searchResults,
    } = this.state;
    const dropDownMinMax = [
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "5", value: "5" },
      { label: "6", value: "6" },
      { label: "7", value: "7" },
      { label: "8", value: "8" },
      { label: "9", value: "9" },
      { label: "10", value: "10" },
    ];

    return (
      <div>
        <Col xs={12}>
          <FormGroup id="view-and-favorites-selector">
            <Input
              id="leads_filter_selected_website_history_filters_view_and_favorites"
              name="leads_filter[selected_website_history_filters][views_and_favorites]"
              onClick={() => this.toggleButtons("views_and_favorites")}
              value={favoritesAndViews}
            />
            <label
              htmlFor="leads_filter_selected_website_history_filters_view_and_favorites"
              className={radioButtonClass(favoritesAndViews)}
              aria-label="Property Views & Favorites"
            />
            <span style={{ marginRight: "16px" }}>Property Views & Favorites</span>
            <Input
              id="leads_filter_selected_website_history_filters_views_only"
              name="leads_filter[selected_website_history_filters][views_only]"
              onClick={() => this.toggleButtons("views")}
              value={viewsOnly}
            />
            <label
              htmlFor="leads_filter_selected_website_history_filters_views_only"
              className={radioButtonClass(viewsOnly)}
              aria-label="Views only"
            />
            <span style={{ marginRight: "16px" }}>Views only</span>
            <Input
              id="leads_filter_selected_website_history_filters_favorites_only"
              name="leads_filter[selected_website_history_filters][favorites_only]"
              onClick={() => this.toggleButtons("favorites")}
              value={favoritesOnly}
            />
            <label
              htmlFor="leads_filter_selected_website_history_filters_favorites_only"
              className={radioButtonClass(favoritesOnly)}
              aria-label="Favorites only"
            />
            <span>Favorites only</span>
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <label htmlFor="leads_filter_selected_website_history_filters_property-min-price">
              Min Price
            </label>
            <Input
              id="leads_filter_selected_website_history_filters_property-min-price"
              name="leads_filter[selected_website_history_filters][min_price]"
              value={minPrice}
              onChange={this.handleMinPriceChange}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <label htmlFor="leads_filter_selected_website_history_filters_property-max-price">
              Max Price
            </label>
            <Input
              id="leads_filter_selected_website_history_filters_property-max-price"
              name="leads_filter[selected_website_history_filters][max_price]"
              value={maxPrice}
              onChange={this.handleMaxPriceChange}
            />
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Row>
              <Col xs={12}>
                <label htmlFor="leads_filter_selected_website_history_filters_property-bedrooms">
                  Bedrooms
                </label>
              </Col>
              <Col xs={12}>
                <div className="range-dropdowns">
                  <Dropdown
                    id="leads_filter_selected_website_history_filters_property-bedrooms"
                    name="leads_filter[selected_website_history_filters][min_bedrooms]"
                    options={dropDownMinMax}
                    placeholder="No Min"
                    value={minBedrooms}
                    onUpdate={this.handleMinBedroomsChange}
                    isClearable
                  />
                  <div className="range-separator" />
                  <Dropdown
                    name="leads_filter[selected_website_history_filters][max_bedrooms]"
                    options={dropDownMinMax}
                    placeholder="No Max"
                    value={maxBedrooms}
                    onUpdate={this.handleMaxBedroomsChange}
                    isClearable
                  />
                </div>
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Row>
              <Col xs={12}>
                <label htmlFor="leads_filter_selected_website_history_filters_property-bathrooms">
                  Bathrooms
                </label>
              </Col>
              <Col xs={12}>
                <div className="range-dropdowns">
                  <Dropdown
                    id="leads_filter_selected_website_history_filters_property-bathrooms"
                    name="leads_filter[selected_website_history_filters][min_bathrooms]"
                    options={dropDownMinMax}
                    placeholder="No Min"
                    value={minBathrooms}
                    onUpdate={this.handleMinBathroomsChange}
                    isClearable
                  />
                  <div className="range-separator" />
                  <Dropdown
                    name="leads_filter[selected_website_history_filters][max_bathrooms]"
                    options={dropDownMinMax}
                    placeholder="No Max"
                    value={maxBathrooms}
                    onUpdate={this.handleMaxBathroomsChange}
                    isClearable
                  />
                </div>
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <div>
          <Col xs={12}>
            <FormGroup style={{ position: "relative" }}>
              <label htmlFor="leads_filter_selected_website_history_filters_property-location">
                Location
              </label>
              <Input
                id="leads_filter_selected_website_history_filters_property-location"
                autoComplete="no"
                name="leads_filter[selected_website_history_filters][location][]"
                placeholder="Search by City or Zip"
                onFocus={this.toggleActive}
                onBlur={this.toggleActive}
                onChange={(e) => this.fetchResults(e.target.value)}
                onKeyDown={this.handleKeyDown}
                value={locationValue}
              />
              {cityStateActive && (
                <div id="city-zip-results">
                  <div className="result-children">
                    {Object.prototype.hasOwnProperty.call(searchResults, "CityState") && (
                      <LocationResults
                        locationType="CityState"
                        cursorCategory={cursorCategory}
                        searchResults={searchResults}
                        cursor={cursor}
                        handleHover={this.handleHover}
                        handleSelect={this.handleSelect}
                      />
                    )}
                    {Object.prototype.hasOwnProperty.call(searchResults, "Zip Code") && (
                      <LocationResults
                        locationType="Zip Code"
                        cursorCategory={cursorCategory}
                        searchResults={searchResults}
                        cursor={cursor}
                        handleHover={this.handleHover}
                        handleSelect={this.handleSelect}
                      />
                    )}
                  </div>
                </div>
              )}
            </FormGroup>
          </Col>
          <Col xs={12}>
            <div className="filter-checkboxes">
              {city.map((c) => (
                <LocationBubble key={c} type="city" location={c} removeLocation={this.removeLocation} />
              ))}
              {zip.map((z) => (
                <LocationBubble key={z} type="zip" location={z} removeLocation={this.removeLocation} />
              ))}
            </div>
          </Col>
        </div>
        <Col xs={12}>
          <hr />
        </Col>
      </div>
    );
  }
}

WebsiteSearchHistoryFilter.propTypes = {
  currentUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selectedSearchHistory: PropTypes.shape().isRequired,
};

export default WebsiteSearchHistoryFilter;

import React from "react";
import PropTypes from "prop-types";

import styleSchema from "./TextToggle.styles";
import { useGivenOrGeneratedId } from "../../hookHelpers";

const containerHeight = (size) => (size === "small" ? "tw-h-[24px]" : "tw-h-[36px]");

const TextToggleOption = ({ name, value, label, checked, size, ...otherProps }) => (
  <label className="tw-m-0 tw-text-center tw-cursor-pointer">
    <input
      type="radio"
      name={name}
      value={value}
      checked={checked}
      className="tw-sr-only tw-peer"
      {...otherProps}
    />
    <div role="radio" aria-checked={checked} className={`${styleSchema.default} ${styleSchema[size]}`}>
      {label}
    </div>
  </label>
);

TextToggleOption.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(["small", "medium"]).isRequired,
};

TextToggleOption.defaultProps = {
  name: null,
};

const TextToggle = ({ name: nameFromProps, options, value, size, onChange, className, ...otherProps }) => {
  const name = useGivenOrGeneratedId("text-toggle", nameFromProps);

  return (
    <fieldset
      className={`tw-inline-block tw-rounded-[30px] tw-border tw-border-solid tw-border-neutral-gray-15 tw-whitespace-nowrap ${className}`}
    >
      <div
        className={`tw-flex tw-flex-row tw-items-center tw-justify-center ${containerHeight(
          size,
        )} tw-m-[-1px]`}
      >
        {options.map((option) => (
          <TextToggleOption
            key={option.value}
            name={name}
            value={option.value}
            label={option.label}
            checked={value === option.value}
            size={size}
            onChange={onChange}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...otherProps}
          />
        ))}
      </div>
    </fieldset>
  );
};

const createOptionsCustomPropType = (isRequired) => (props, propName, componentName) => {
  const { options } = props;

  if (options === null || options === undefined) {
    return isRequired ? new Error(`Prop \`${propName}\` is required by \`${componentName}\`.`) : null;
  }

  if (!Array.isArray(options)) {
    return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. It must be an array.`);
  }

  if (options.length < 2) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. It must be an array with at least two elements.`,
    );
  }

  options.forEach((element) =>
    // Disabling `react/forbid-foreign-prop-types` here since we maintain the TextToggle.Option.propTypes
    // eslint-disable-next-line react/forbid-foreign-prop-types
    PropTypes.checkPropTypes(
      {
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      },
      element,
      "prop",
      "TextToggle.Option",
    ),
  );

  return null;
};

const optionsCustomPropType = createOptionsCustomPropType(false);
optionsCustomPropType.isRequired = createOptionsCustomPropType(true);

TextToggle.propTypes = {
  name: PropTypes.string,
  options: optionsCustomPropType.isRequired,
  value: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "medium"]),
  onChange: PropTypes.func,
  className: PropTypes.string,
};

TextToggle.defaultProps = {
  name: null,
  size: "medium",
  onChange: null,
  className: "",
};

export default TextToggle;

import { ArrowLeftSolidV6, Close, PhoneSolidV6 } from "@shared/v2/Icomoon";
import IconButton from "@shared/v2/IconButton";
import Modal from "@shared/v2/Modal";
import React, { useRef, useState } from "react";
import Button from "@shared/v2/Button";
import Tooltip from "@shared/v2/Tooltip";
import Dnc from "../Dnc";
import CallInfo from "../CallInfo";
import PropertyInfoBlock from "./PropertyInfoBlock";
import { prospectNameColor } from "../helpers";

const PropertyDialerModal = ({ property, callProspect, closeModal }) => {
  const overviewContainer = useRef(null);
  const [overviewContainerHeight, setOverviewContainerHeight] = useState(0);
  const [view, setView] = useState("overview");

  const showCallHistory = () => {
    // This is just to preserve the size of the modal between the dialer overview and call history
    const HeaderHeightDifference = 16;
    setOverviewContainerHeight(overviewContainer?.current?.clientHeight + HeaderHeightDifference);

    setView("history");
  };

  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      dialogClassName="tw-w-full"
      contentClassName="tw-m-auto !tw-p-0 tw-max-h-[90vh] tw-flex tw-flex-col"
      contentSize="medium"
      onHide={closeModal}
      show
    >
      <Modal.Header className="tw-m-32px">
        {view === "overview" ? (
          <div className="tw-flex tw-flex-row tw-justify-between !tw-mt-0">
            <span className="tw-text-28px tw-text-gray-75">Property Dialer</span>
            <IconButton onClick={closeModal} aria-label="Close" schema="tertiary">
              <Close />
            </IconButton>
          </div>
        ) : (
          <div onClick={() => setView("overview")} className="tw-cursor-pointer !tw-m-0">
            <ArrowLeftSolidV6 size="m" />
          </div>
        )}
      </Modal.Header>
      <Modal.Body className={`tw-overflow-y-hidden tw-px-32px tw-relative tw-mb-32px`}>
        {view === "overview" ? (
          <div ref={overviewContainer}>
            <DialerOverview
              property={property}
              callProspect={callProspect}
              showCallHistory={showCallHistory}
            />
          </div>
        ) : (
          <div className="tw-flex tw-flex-col tw-gap-8px" style={{ height: `${overviewContainerHeight}px` }}>
            <span className="tw-text-gray-75 tw-text-18px tw-font-bold">Calls to this property</span>
            {property.callHistory?.length > 0 ? (
              <div className="tw-flex tw-flex-col tw-overflow-y-auto tw-pr-20px">
                {property.callHistory.map((call, i) => (
                  <CallInfo
                    key={`call-${i}`}
                    call={call}
                    includeBorder={i < property.callHistory.length - 1}
                  />
                ))}
              </div>
            ) : (
              <span>None!</span>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PropertyDialerModal;

const DialerOverview = ({ property, callProspect, showCallHistory }) => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-32px">
      <PropertyInfoBlock property={property} />
      <div className="tw-flex tw-flex-col tw-gap-22px">
        <div className="tw-flex tw-flex-row tw-justify-between">
          <span className="tw-text-18px tw-text-gray-75">Potential Owners</span>
          <Button
            schema="secondary"
            size="small"
            disabled={!property.callHistory.length}
            onClick={showCallHistory}
          >
            Call History ({property.callHistory.length})
          </Button>
        </div>
        {property.prospects.length > 0 && (
          <div className="tw-flex tw-flex-col tw-gap-16px">
            {property.prospects.map((prospect) => (
              <ProspectRow prospect={prospect} callProspect={() => callProspect({ prospect, property })} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const ProspectRow = ({ prospect, callProspect }) => {
  const mostText = prospect.phone ? "tw-text-gray-75" : "tw-text-gray-30";
  const nameText = prospectNameColor(prospect);

  return (
    <div className="tw-flex tw-flex-row tw-justify-between">
      <div className="tw-flex tw-flex-col tw-gap-2px">
        <div className="tw-flex tw-flex-row tw-gap-4px tw-items-center">
          <span
            className={`tw-text-14px tw-max-w-[170px] tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap ${nameText}  ${prospect.name ? "" : "tw-italic"}`}
          >
            {prospect.name || "No Name"}
          </span>
        </div>
        <div className="tw-flex tw-flex-row tw-gap-4px">
          <span className={`tw-text-12px ${mostText}`}>{prospect.phoneFormatted || "--"}</span>
          <Dnc show={prospect.isDnc} />
        </div>
        {prospect.isLead && !prospect.isMyLead && (
          <div className="tw-mt-4px tw-text-12px tw-text-yellow-100">
            This number is in your CRM and may be another team member's lead
          </div>
        )}
      </div>
      <div className="tw-flex tw-flex-row tw-gap-8px tw-items-center">
        {prospect.phone ? (
          <IconButton schema="primary" size="small" onClick={callProspect}>
            <PhoneSolidV6 size="s" />
          </IconButton>
        ) : (
          <Tooltip
            trigger={
              <IconButton schema="primary" size="small" onClick={() => {}} disabled>
                <PhoneSolidV6 size="s" />
              </IconButton>
            }
            placement="right"
            multiline
            contentClassName="tw-w-[140px]"
            content={<span>Cannot call a prospect with no phone number</span>}
          />
        )}
      </div>
    </div>
  );
};

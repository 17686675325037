import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import { debounce } from "lodash";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

const Pdf = ({ file }) => {
  const [passwordError, setPasswordError] = useState("");
  const [size, setSize] = useState({ width: 0, height: 0 });
  const pageRef = useRef(null);
  const handleResize = useMemo(
    () =>
      debounce(() => {
        if (!pageRef.current) return;
        const pageElement = pageRef.current.pageElement.current;
        const width = pageElement.clientWidth;
        const height = pageElement.clientHeight;
        setSize({ width, height });
      }, 100),
    [pageRef.current, setSize],
  );
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  if (passwordError) return <div>{passwordError}</div>;

  return (
    <Document
      file={file}
      error="Failed to load document."
      onPassword={() => setPasswordError("Failed to load, this document is password protected.")}
    >
      <Page
        className="tw-flex"
        ref={pageRef}
        pageNumber={1}
        onLoadSuccess={handleResize}
        renderAnnotationLayer={false}
        width={size.width}
        height={size.height}
      />
    </Document>
  );
};

Pdf.propTypes = {
  file: PropTypes.string.isRequired,
};

export default Pdf;

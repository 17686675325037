import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { string, func, arrayOf, shape, bool } from "prop-types";
import _ from "lodash";
import IconButton from "../../../shared/v2/IconButton/IconButton";
import Plus from "../../../shared/v2/Icomoon/Icons/Plus";
import AddModal from "./AddModal";
import TaskFormLogAppointment from "../../../Tasks/components/TaskFormLogAppointment";
import PeopleSubCategories from "./PeopleSubCategories";
import { getAllPeopleAsThunk } from "./actions/thunks";
import { getEmailTemplatesAsThunk } from "../Interactions/actions/thunks";
import EllipsisDropdown from "./EllipsisDropdown";
import useOnClickOutsideRef from "../../Header/api/hooks";

const People = ({
  uuid,
  currentUserUUID,
  currentUserId,
  accountUUID,
  isAdmin,
  onGetAllPeopleAsThunk,
  clients,
  collaborators,
  team,
  roles,
  personUuid,
  onGetEmailTemplatesAsThunk,
}) => {
  const [clientsDropdown, setClientsDropdown] = useState(false);
  const [collaboratorsDropdown, setCollaboratorsDropdown] = useState(false);
  const [teamDropdown, setTeamDropdown] = useState(false);
  const [addDropdownOpen, setAddDropdownOpen] = useState(false);
  const [toggleAddModal, setToggleAddModal] = useState(false);
  const [toggleAddClientModal, setToggleAddClientModal] = useState(false);
  const [toggleAddCollaboratorModal, setToggleAddCollaboratorModal] = useState(false);
  const [appointmentModalUser, setAppointmentModalUser] = useState(null);
  const hasAddPeoplePrivilages = useMemo(
    () => isAdmin || team.some((member) => member.person.call_interaction.user_id === currentUserId),
    [team, currentUserId],
  );

  useEffect(() => {
    if (toggleAddModal || toggleAddClientModal || toggleAddCollaboratorModal) setAddDropdownOpen(false);
  }, [toggleAddModal, toggleAddClientModal, toggleAddCollaboratorModal]);

  useEffect(() => {
    onGetAllPeopleAsThunk(uuid);
  }, [uuid]);

  useEffect(() => {
    onGetEmailTemplatesAsThunk();
  }, [personUuid]);

  const ellipsisModal = useOnClickOutsideRef(() => setAddDropdownOpen(false));

  return (
    <>
      <div className="tw-flex tw-justify-between tw-mb-[24px]" ref={ellipsisModal}>
        <div className="tw-text-[18px] tw-font-bold tw-leading-24px tw-tracking-normal">People</div>
        {hasAddPeoplePrivilages && (
          <div className="tw-relative tw-mr-[10px]">
            <IconButton size="small" schema="tertiary" onClick={() => setAddDropdownOpen((prev) => !prev)}>
              <Plus size="s" />
            </IconButton>
            {addDropdownOpen && (
              <EllipsisDropdown
                setToggleAddClientModal={setToggleAddClientModal}
                setToggleAddCollaboratorModal={setToggleAddCollaboratorModal}
                setToggleAddModal={setToggleAddModal}
              />
            )}
          </div>
        )}
      </div>
      <div className="tw-flex tw-flex-col">
        <PeopleSubCategories
          setDropdown={setClientsDropdown}
          dropdown={clientsDropdown}
          data={clients}
          title="Clients"
          setAppointmentModalUser={setAppointmentModalUser}
        />
        <PeopleSubCategories
          setDropdown={setCollaboratorsDropdown}
          dropdown={collaboratorsDropdown}
          data={collaborators}
          title="Collaborators"
          setAppointmentModalUser={setAppointmentModalUser}
        />
        <PeopleSubCategories
          setDropdown={setTeamDropdown}
          dropdown={teamDropdown}
          data={team}
          title="Team"
          setAppointmentModalUser={setAppointmentModalUser}
        />
      </div>
      {appointmentModalUser && (
        <TaskFormLogAppointment
          showModal
          closeTaskModal={() => setAppointmentModalUser(null)}
          contactEmail={appointmentModalUser?.email_address} // email of the person to make the apt for
          current_user={{ uuid: currentUserUUID }} // current logged in user
          person={appointmentModalUser} // the person we are making an apt for
          account={{ uuid: accountUUID }} // current users account ID
          user={appointmentModalUser?.primary_agent} // primary agent uuid
          isfromIndex // show the right modal
          isSchedulingAppointment // show the right modal fields
          onClose={() => setAppointmentModalUser(null)} // clear the user when the modal closes
          v4TaskApi // use the new api
          listingUUID={uuid}
        />
      )}
      {toggleAddModal && (
        <AddModal
          title="Team Member"
          toggleAddModal={toggleAddModal}
          setToggleAddModal={setToggleAddModal}
          roles={roles.team_member}
          isTeamMember
        />
      )}
      {toggleAddClientModal && (
        <AddModal
          title="Client"
          toggleAddModal={toggleAddClientModal}
          setToggleAddModal={setToggleAddClientModal}
          isClient
          roles={roles.client}
        />
      )}
      {toggleAddCollaboratorModal && (
        <AddModal
          title="Collaborator"
          toggleAddModal={toggleAddCollaboratorModal}
          setToggleAddModal={setToggleAddCollaboratorModal}
          isCollaborator
          roles={roles.collaborator}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onGetAllPeopleAsThunk: (uuid) => dispatch(getAllPeopleAsThunk(uuid)),
  onGetEmailTemplatesAsThunk: () => dispatch(getEmailTemplatesAsThunk()),
});

const mapStateToProps = (state) => ({
  isAdmin: state.tdpDetailsReducer.isAdmin,
  accountUUID: state.tdpDetailsReducer.accountUUID,
  currentUserUUID: state.tdpDetailsReducer.currentUser?.uuid,
  currentUserId: state.tdpDetailsReducer.currentUser.id,
  uuid: state.tdpDetailsReducer.uuid,
  personUuid: state.tdpDetailsReducer.person.data.attributes.uuid,
  clients: state.tdpPeopleReducer.people?.listing_clients,
  collaborators: state.tdpPeopleReducer.people?.collaborators,
  team: state.tdpPeopleReducer.people?.team_members,
  roles: {
    client: _.uniqBy(state.tdpPeopleReducer.people.roles?.client, "title"),
    collaborator: _.uniqBy(state.tdpPeopleReducer.people.roles?.collaborator, "title"),
    team_member: _.uniqBy(state.tdpPeopleReducer.people.roles?.team_member, "title"),
  },
});

People.propTypes = {
  uuid: string,
  isAdmin: bool,
  currentUserUUID: string,
  currentUserId: string,
  accountUUID: string,
  onGetAllPeopleAsThunk: func,
  clients: arrayOf(shape({})),
  collaborators: arrayOf(shape({})),
  team: arrayOf(shape({})),
  roles: shape({}),
  personUuid: string,
  onGetEmailTemplatesAsThunk: func,
};

People.defaultProps = {
  uuid: null,
  isAdmin: false,
  currentUserUUID: null,
  currentUserId: null,
  accountUUID: false,
  clients: [],
  collaborators: [],
  team: [],
  roles: {
    client: [],
    collaborator: [],
    team_member: [],
  },
  personUuid: null,
  onGetAllPeopleAsThunk: () => {},
  onGetEmailTemplatesAsThunk: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(People);

/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "../Icon";

const BellRegularV6 = (props) => (
  <Icon {...props} name="Bell Regular V6">
    <path d="M9 1v.559c2.266.36 4 2.322 4 4.691v1.044c0 1.419.484 2.797 1.369 3.906l.466.581A.749.749 0 0 1 14.251 13h-12.5a.752.752 0 0 1-.586-1.219l.466-.581a6.257 6.257 0 0 0 1.37-3.906V6.25a4.75 4.75 0 0 1 4-4.691V1a1 1 0 1 1 2 0zM7.75 3A3.251 3.251 0 0 0 4.5 6.25v1.044A7.762 7.762 0 0 1 3.26 11.5h9.481A7.762 7.762 0 0 1 11.5 7.294V6.25A3.251 3.251 0 0 0 8.25 3h-.5zM10 14c0 .503-.209 1.041-.584 1.416S8.503 16 8 16c-.531 0-1.041-.209-1.416-.584S6 14.504 6 14h4z" />
  </Icon>
);

export default BellRegularV6;

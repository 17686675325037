import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { BellRegularV6, BellSolidV6 } from "@icons";
import Button from "@shared/v2/Button/Button";
import IconButton from "@shared/v2/IconButton/IconButton";
import { useClickOutside } from "@shared/hookHelpers";
import Popover from "../../Layout/Popover";
import useNotifications from "../hooks/useNotifications";
import Notification from "./Notification";

const Notifications = () => {
  const theme = useSelector(({ layout }) => layout.theme);
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  useClickOutside(ref, () => setOpen(false));
  const { loading, markedRead, notifications, actions } = useNotifications();
  const count = notifications.length;

  return (
    <div ref={ref} className="tw-relative">
      <IconButton
        className={`notifications-dropdown tw-relative ${
          theme.name === "place-theme" ? "tw-text-white" : ""
        } hover:tw-bg-gray/20 active:tw-bg-gray/30 !tw-overflow-visible`}
        id="notificationsDropdown"
        aria-haspopup="true"
        aria-expanded={open}
        onClick={() => setOpen((o) => !o)}
        schema="transparent"
      >
        {count > 0 && !markedRead ? (
          <>
            <BellSolidV6 size="xxl" />
            <div className="tw-absolute tw-flex tw-justify-center tw-items-center tw-w-[20px] tw-h-[20px] tw-bg-theme-primary tw-text-12d tw-text-white tw-top-[-8px] tw-left-[16px] tw-rounded-full">
              {count}
            </div>
          </>
        ) : (
          <BellRegularV6 size="xxl" />
        )}
      </IconButton>

      <Popover aria-labelledby="notificationsDropdown" className="tw-max-w-[450px] tw-w-[65vw]" open={open}>
        <ul className="tw-pl-0 tw-mb-0 tw-max-h-[300px] tw-overflow-y-auto">
          {notifications.map((notification) => (
            <Notification key={notification.id} notification={notification} />
          ))}
          {loading && (
            <li className="tw-flex tw-justify-center tw-items-center tw-h-[100px]">
              <i className="fa fa-spinner fa-pulse" />
            </li>
          )}
          {!loading && (count === 0 || markedRead) && (
            <li className="tw-flex tw-justify-center tw-items-center tw-h-[100px]">
              <p className="tw-m-0">No unread notifications</p>
            </li>
          )}
        </ul>
        {!loading && count > 0 && !markedRead && (
          <div className="tw-bg-white">
            <Button className="tw-w-full mark-all-read" onClick={actions.markAllAsRead}>
              Mark all read
            </Button>
          </div>
        )}
      </Popover>
    </div>
  );
};

export default Notifications;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Proptypes from "prop-types";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button/Button";
import TextButton from "@shared/v2/TextButton";
import EditAgreementBody from "./EditAgreementBody";
import { formatFileSize } from "../../../actions/helper";
import DeleteAgreementModal from "../DeleteAgreementModal";
import {
  handleDocumentDelete,
  handleDocumentUpload,
  handleDocumentRename,
  handleAddendumUpload,
  handleAddendumDelete,
  handleAddendumRename,
  handleAgreementUpdate,
  fetchAvailableTransactions,
} from "../../../actions/personDetailActionCreators";

const createDoc = (file, agreementType) => ({
  fileExtension: `.${file?.name.split(".").pop()}`,
  url: null,
  uploadedById: null,
  uploadedByImageUrl: null,
  uploadedByImage: null,
  uploadedByName: null,
  uploadedByUrl: null,
  fileName: file?.name,
  fileType: file?.type,
  humanReadableSize: formatFileSize(file?.size),
  id: file?.lastModified,
  nakedName: `${file?.name.split(".")[0]}`,
  imageIcon: null,
  size: file?.size,
  uploadedAt: null,
  addedAgo: null,
  dateAdded: null,
  expiringUrl: null,
  documentSubType: agreementType,
  isCreated: true,
  fileObj: file,
  canRename: true,
});

const getOption = (data) => {
  return data.map((item) => ({
    label: item.name,
    value: item.id,
  }));
};

const EditAgreementModal = ({ modalOpen, closeModal, person, onDelete, agreement }) => {
  const dispatch = useDispatch();
  const availableTransactions = useSelector((state) => getOption(state.personDetail.available_transactions));

  const [agreementState, setAgreementState] = useState(agreement);
  const [addendumState, setAddendumState] = useState(agreement?.addendums || []);
  const [isAddendumDelete, setIsAddendumDelete] = useState(false);
  const [saveDocId, setSaveDocId] = useState(null);

  const [openDeleteAgreementModal, setOpenDeleteAgreementModal] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    if (person.data?.id) dispatch(fetchAvailableTransactions(person.data.attributes.uuid));
  }, [person.data?.id]);

  const handleAgreementDocumentUpload = (event) => {
    const tempfiles = event.target.files[0];
    if (tempfiles) {
      setAgreementState((prevState) => ({
        ...prevState,
        document: createDoc(tempfiles, agreementState.agreementType),
      }));
    }
  };

  const handleAgreementFileDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer.files[0]) {
      setAgreementState((prevState) => ({
        ...prevState,
        document: createDoc(event.dataTransfer.files[0], agreementState.agreementType),
      }));
    }
  };

  const handleAddendumsFileDrop = (event) => {
    event.preventDefault();

    if (event.dataTransfer.files[0]) {
      setAddendumState((prevState) => [
        ...prevState,
        createDoc(event.dataTransfer.files[0], agreementState.agreementType),
      ]);
    }
  };

  const handleAddendumsDocumentUpload = (event) => {
    const tempfiles = event.target.files[0];

    if (tempfiles) {
      setAddendumState((prevState) => [...prevState, createDoc(tempfiles, agreementState.agreementType)]);
    }
  };

  const handleAgreementDocumentReplace = (docId, event) => {
    deleteAgreementDocument(true);
    handleAgreementDocumentUpload(event);
  };

  const handleAddendumsReplace = (docId, event) => {
    deleteAddendum(docId);
    handleAddendumsDocumentUpload(event);
  };

  const deleteAgreementDocument = (isReplaced) => {
    if (agreementState?.document?.isCreated) {
      setAgreementState((prevState) => ({
        ...prevState,
        document: null,
      }));
    } else {
      setAgreementState((prevState) => ({
        ...prevState,
        deletedDocId: prevState.document ? prevState.document.id : null,
        document: null,
        ...(isReplaced && {
          replaced: true,
          replacedFileName: prevState.document.fileName,
        }),
      }));
    }
  };

  const deleteAddendum = (docId) => {
    // Check if the document with the specified id exists and if it is recently created
    const isDocCreated = addendumState.some((addendum) => addendum.id === docId && addendum?.isCreated);

    if (isDocCreated) {
      setAddendumState((prevState) => prevState.filter((addendum) => addendum.id !== docId));
    } else {
      setAddendumState((prevState) =>
        prevState.map((addendum) =>
          addendum.id === docId
            ? {
                ...addendum,
                isDeleted: true,
                isRenamed: false,
              }
            : addendum,
        ),
      );
    }
  };

  const renameAgreementDocument = (docId, newFileName) => {
    // Check if the id matches and isDocCreated is true
    const isDocCreated = agreementState?.document?.isCreated;

    setAgreementState((prevState) => ({
      ...prevState,

      document: prevState.document
        ? {
            ...prevState.document,
            fileName: newFileName,
            nakedName: `${newFileName.split(".")[0]}`,
            ...(isDocCreated
              ? {
                  fileObj: new File([prevState.document.fileObj], newFileName, {
                    type: prevState.document.fileObj.type,
                  }),
                }
              : { isRenamed: true }),
          }
        : prevState.document,
    }));
  };

  const renameAddendum = (docId, newFileName) => {
    // Check if the document with the specified id exists and if it is recently created
    const isDocCreated = addendumState.some((addendum) => addendum.id === docId && addendum?.isCreated);

    setAddendumState((prevState) =>
      prevState.map((addendum) =>
        addendum.id === docId
          ? {
              ...addendum,
              fileName: newFileName,
              nakedName: `${newFileName.split(".")[0]}`,
              ...(isDocCreated
                ? {
                    fileObj: new File([addendum.fileObj], newFileName, { type: addendum.fileObj.type }),
                  }
                : { isRenamed: true }),
            }
          : addendum,
      ),
    );
  };

  const setAgreement = (name, value) => {
    setAgreementState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onClickDeleteAgreement = () => {
    setOpenDeleteAgreementModal(true);
  };

  const onClickDeleteAddendum = (docId) => {
    setOpenDeleteModal(true);
    setIsAddendumDelete(true);
    setSaveDocId(docId);
  };

  const onClickDeleteAgreementDocument = (docId) => {
    setOpenDeleteModal(true);
    setIsAddendumDelete(false);
    setSaveDocId(docId);
  };

  const deleteCloseModal = () => {
    setOpenDeleteModal(false);
  };

  const deleteAgreementCloseModal = () => {
    setOpenDeleteAgreementModal(false);
  };

  const onSave = async () => {
    closeModal();
    const createdDoc = agreementState?.document?.isCreated && agreementState?.document;
    const createAddendums = addendumState.filter((doc) => doc.isCreated === true);

    const isReplacedDoc =
      agreementState?.replaced && agreementState?.replacedFileName && agreementState?.document?.fileName;

    const deleteDocId = agreementState?.deletedDocId || null;
    const deleteAddendums = addendumState.filter((doc) => doc.isDeleted === true);

    const renameDoc = agreementState?.document?.isRenamed && agreementState?.document;
    const renameAddendums = addendumState.filter((doc) => doc.isRenamed === true);

    const dispatchPromises = [];

    if (createdDoc?.fileName) {
      const payloadData = {
        agreement_type: agreementState.agreementType,
      };
      dispatchPromises.push(
        dispatch(handleDocumentUpload(createdDoc?.fileObj, person.data.attributes, false, payloadData, true)),
      );
    }

    if (createAddendums.length > 0) {
      createAddendums.map((createAddendum) =>
        dispatchPromises.push(
          dispatch(handleAddendumUpload(createAddendum?.fileObj, person.data.attributes, agreementState?.id)),
        ),
      );
    }

    if (deleteDocId) {
      dispatchPromises.push(dispatch(handleDocumentDelete(person.data.attributes, deleteDocId)));
    }

    if (deleteAddendums.length > 0) {
      deleteAddendums.map((deleteAddendum) =>
        dispatchPromises.push(
          dispatch(handleAddendumDelete(person.data.attributes, agreementState?.id, deleteAddendum?.id)),
        ),
      );
    }

    if (renameDoc?.fileName) {
      dispatchPromises.push(
        dispatch(handleDocumentRename(person.data.attributes, renameDoc?.id, renameDoc?.fileName)),
      );
    }

    if (renameAddendums.length > 0) {
      renameAddendums.map((renameAddendum) =>
        dispatchPromises.push(
          dispatch(
            handleAddendumRename(
              person.data.attributes,
              agreementState?.id,
              renameAddendum?.id,
              renameAddendum?.fileName,
            ),
          ),
        ),
      );
    }

    // Wait for all document and addendum-related operations to complete
    const [result] = await Promise.all(dispatchPromises);
    // Then, update the agreement
    const agreementData = {
      agreement_type: agreementState?.agreementType,
      id: agreementState?.id,
      signed_at: agreementState?.signedDate,
      expired_at: agreementState?.expirationDate,
      cancelled_at: agreementState?.cancellationDate,
      completed: agreementState?.isCompleted,
      cancelled: agreementState?.isCanceled,
      listing_id: agreementState?.listing?.value,
      ...(createdDoc?.fileName && result && result?.CreatedDocID && { document_id: result?.CreatedDocID }),
      ...(isReplacedDoc && { replaced: true, replaced_file_name: agreementState?.replacedFileName }),
    };
    await dispatch(handleAgreementUpdate(person.data.attributes, agreementData, agreementState?.id));
  };

  const getActiveAgreementState = () => {
    if (agreementState?.document?.isDeleted) {
      return {
        ...agreementState,
        document: null,
      };
    }
    return agreementState;
  };

  const getActiveAddendumState = () => {
    return addendumState.filter((doc) => !doc.isDeleted);
  };

  return (
    <>
      <Modal
        className="tw-flex tw-justify-center tw-items-center"
        dialogClassName="tw-w-[636px] tw-h-auto"
        contentClassName="tw-w-full tw-h-full"
        backdrop="static"
        show={modalOpen}
        onHide={closeModal}
        data-cy="edit-agreement-modal"
      >
        <Modal.Header
          titleClassName="tw-mb-32px tw-text-neutral-gray-75"
          title="Edit Agreement"
          closeButton
        />

        <Modal.Body className="tw-mb-32px tw-max-h-[70vh] tw-overflow-auto">
          <EditAgreementBody
            agreement={getActiveAgreementState()}
            addendumState={getActiveAddendumState()}
            setAgreement={setAgreement}
            handleAgreementDocumentUpload={handleAgreementDocumentUpload}
            handleAgreementFileDrop={handleAgreementFileDrop}
            handleAddendumsFileDrop={handleAddendumsFileDrop}
            handleAddendumsDocumentUpload={handleAddendumsDocumentUpload}
            handleAgreementDocumentReplace={handleAgreementDocumentReplace}
            handleAddendumsReplace={handleAddendumsReplace}
            deleteAddendum={onClickDeleteAddendum}
            deleteAgreementDocument={onClickDeleteAgreementDocument}
            renameAgreementDocument={renameAgreementDocument}
            renameAddendum={renameAddendum}
            availableTransactions={availableTransactions}
          />
        </Modal.Body>

        <Modal.Footer className="tw-flex tw-justify-between">
          <Button size="medium" schema="tertiary" onClick={closeModal} data-cy="agreement-edit-cancel-button">
            Cancel
          </Button>
          <div className="tw-flex tw-gap-8px">
            <TextButton
              className="tw-items-center "
              size="medium"
              schema="remove"
              onClick={onClickDeleteAgreement}
              data-cy="edit-agreement-delete"
            >
              Delete Agreement
            </TextButton>
            <Button size="medium" schema="primary" onClick={onSave} data-cy="agreement-edit-submit-button">
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {openDeleteAgreementModal && (
        <DeleteAgreementModal
          modalOpen={openDeleteAgreementModal}
          onDelete={() => {
            onDelete(person, agreement.id);
            deleteAgreementCloseModal();
            closeModal();
          }}
          closeModal={deleteAgreementCloseModal}
          title="Delete Agreement?"
          msg="Deleting this agreement will remove any associated documents from the Documents section."
        />
      )}
      {openDeleteModal && (
        <DeleteAgreementModal
          modalOpen={openDeleteModal}
          onDelete={() => {
            isAddendumDelete ? deleteAddendum(saveDocId) : deleteAgreementDocument(false);
            deleteCloseModal();
          }}
          closeModal={deleteCloseModal}
          title="Delete Document?"
          msg="Deleting this document will remove it from both the agreement and the Documents section."
        />
      )}
    </>
  );
};

EditAgreementModal.propTypes = {
  modalOpen: Proptypes.bool,
  closeModal: Proptypes.func,
  agreement: Proptypes.shape({
    id: Proptypes.number,
    agreementType: Proptypes.string,
  }),
  person: Proptypes.shape({}),
  onDelete: Proptypes.func,
};
EditAgreementModal.defaultProps = {
  modalOpen: false,
  closeModal: null,
  person: {},
  agreement: {},
  onDelete: null,
};
export default EditAgreementModal;

import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import moment from "moment";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";

const getDateRange = (dateRange) => {
  let startDate = moment().startOf("year");
  let endDate = moment().endOf("year");

  if (dateRange === "today") {
    startDate = moment().startOf("day");
    endDate = moment().endOf("day");
  }

  if (dateRange === "last_7_days") {
    startDate = moment().subtract(7, "days").startOf("day");
    endDate = moment().endOf("day");
  }

  if (dateRange === "last_14_days") {
    startDate = moment().subtract(14, "days").startOf("day");
    endDate = moment().endOf("day");
  }

  if (dateRange === "this_month") {
    startDate = moment().startOf("month");
    endDate = moment().endOf("month");
  }

  if (dateRange === "last_month") {
    startDate = moment().subtract(1, "month").startOf("month");
    endDate = moment().subtract(1, "month").endOf("month");
  }

  if (dateRange === "last_year") {
    startDate = moment().subtract(1, "year").startOf("year");
    endDate = moment().subtract(1, "year").endOf("year");
  }

  return { startDate: startDate.format("YYYY-MM-DD"), endDate: endDate.format("YYYY-MM-DD") };
};

const useSalesFunnel = ({ agentUuid, dateRange, filters }) => {
  const [loading, setLoading] = useState(true);
  const [rawData, setRawData] = useState(null);
  const [error, setError] = useState(null);

  const data = useMemo(() => {
    if (!rawData) {
      return {
        callsContactedRatio: 0,
        contactedApptSetRatio: 0,
        apptSetApptHeldRatio: 0,
        apptHeldApptSignedRatio: 0,
        apptSignedPendedRatio: 0,
        pendedClosedRatio: 0,
      };
    }

    const filterKeys = Object.keys(filters).filter((key) => filters[key]);
    const dataKeys = Object.keys(rawData.buyer);
    const transformedData = dataKeys.reduce((acc, key) => {
      acc[key] = {
        actual: filterKeys.reduce((sum, filterKey) => sum + (rawData[filterKey][key]?.actual || 0), 0),
        goal: Number(Math.round(rawData[filterKeys[0]]?.[key]?.goal) || 0),
        onTarget: Number(rawData[filterKeys[0]]?.[key]?.onTarget || 0),
      };
      return acc;
    }, {});
    transformedData.callsContactedRatio = Math.round(
      (transformedData.contacted.actual / (transformedData.calls.actual || 1)) * 100,
    );
    transformedData.contactedApptSetRatio = Math.round(
      (transformedData.apptSet.actual / (transformedData.contacted.actual || 1)) * 100,
    );
    transformedData.apptSetApptHeldRatio = Math.round(
      (transformedData.apptHeld.actual / (transformedData.apptSet.actual || 1)) * 100,
    );
    transformedData.apptHeldApptSignedRatio = Math.round(
      (transformedData.apptSigned.actual / (transformedData.apptHeld.actual || 1)) * 100,
    );
    transformedData.apptSignedPendedRatio = Math.round(
      (transformedData.pended.actual / (transformedData.apptSigned.actual || 1)) * 100,
    );
    transformedData.pendedClosedRatio = Math.round(
      (transformedData.closed.actual / (transformedData.pended.actual || 1)) * 100,
    );

    return transformedData;
  }, [filters, rawData]);

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    caseTransformingAxios
      .get(`/api/v4/reporting/${agentUuid ? "agent" : "business"}_dashboard`, {
        signal: abortController.signal,
        params: {
          personUuid: agentUuid,
          section: "sales_funnel",
          ...getDateRange(dateRange),
        },
      })
      .then((res) => {
        setRawData(res.data);
        setError(null);
        setLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setError(err.response.data?.message || "Whoops! Something went wrong.");
          setLoading(false);
        }
      });
    return () => abortController.abort();
  }, [agentUuid, dateRange]);

  return { data, error, loading };
};

export default useSalesFunnel;

/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import Toggle from "@shared/v2/Toggle";
import TextInput from "@shared/v2/TextInput/TextInput";
import TextComboInput from "@shared/v2/TextComboInput";
import {
  ArrowRightSolidV6,
  DollarSignSolidV6,
  MinusSolidV6,
  PercentSolidV6,
  PlusSolidV6,
} from "@shared/v2/Icomoon";
import ButtonToggle from "@shared/v2/ButtonToggle";
import { formatNumber, isValidValue } from "@shared/v2/inputFormatUtils";
import EmailMessageInput from "../EmailMessageInput";
import DripInput from "../DripInput";
import DripFrequency from "../DripFrequency";
import HomeEstimateValue from "../HomeEstimateValue/HomeEstimateValue";
import { getHomeValue } from "../../api";

const STYLE = {
  header: "tw-text-18d tw-text-gray-75 tw-mt-18px tw-mb-24px",
  subHeader: "tw-text-14d tw-text-gray-50 tw-font-semibold",
};

function calculateHomeAdjustment({ homeAdjVal, homeAdj, isHomeAdjPercentage, isHomeAdjPositive }) {
  const adjustmentFactor = isHomeAdjPositive ? 1 : -1;

  const result = isHomeAdjPercentage
    ? homeAdjVal * (1 + adjustmentFactor * (homeAdj / 100))
    : homeAdjVal + adjustmentFactor * homeAdj;

  return `$${Number(Math.floor(result)).toLocaleString()}`;
}

const REPORT_TYPE_HASH = {
  market: false,
  home_value: true,
};

const Panel2Form = ({ setFormData, formData, report, scrollForm2 }) => {
  const [homeAdjVal, setHomeAdjVal] = useState(0);

  useEffect(() => {
    if (report?.street_address) {
      (async () => {
        const { response } = await getHomeValue({
          streetAddress: report.street_address,
          city: report.city,
          state: report.state_abbreviation,
          postalCode: report.postal_code,
        });

        setHomeAdjVal(Math.floor(response.data.value));
      })();
    }
  }, [report?.street_address]);

  useEffect(() => {
    setFormData({
      home_value_adjustment_as_percentage: report?.home_value_adjustment_as_percentage,
      home_value_adjustment: Math.abs(report?.home_value_adjustment * 1),
      report_type: report?.report_type,
      isHomeAdjPositive: report?.home_value_adjustment >= 0,
    });
  }, [report]);

  const formRef = useRef(null);

  useEffect(() => {
    if (scrollForm2) {
      formRef.current.scrollTop = formRef.current.scrollHeight;
    }
  }, [scrollForm2]);

  return (
    <form
      ref={formRef} // Attach the ref to the form element
      className="tw-flex-none tw-w-[416px] tw-px-[4px] tw-h-full tw-overflow-y-auto tw-ml-[12px]"
    >
      <hr className="tw-border-x-0 tw-border-y tw-border-solid tw-border-neutral-gray-10 tw-m-0" />
      <div className={STYLE.header}>Email Settings</div>
      <div className="tw-mb-[24px]">
        <TextInput
          label="Market Report Name"
          helperText="This field is visible to your contacts"
          value={formData.name}
          onChange={(e) => {
            setFormData({ name: e.target.value });
          }}
        />
      </div>
      <EmailMessageInput
        value={formData.email_message}
        onChange={(e) => {
          setFormData({ email_message: e.target.value });
        }}
      />
      <DripInput
        value={formData.drip_enabled}
        onChange={(val) => {
          setFormData({
            drip_enabled: val,
            recurring_frequency: !val ? "never" : formData.recurring_frequency,
          });
        }}
      />
      {formData.drip_enabled && (
        <DripFrequency
          value={formData.recurring_frequency}
          onChange={(val) => {
            setFormData({ recurring_frequency: val });
          }}
        />
      )}
      {report?.has_address && (
        <>
          <hr className="tw-border-x-0 tw-border-y tw-border-solid tw-border-neutral-gray-10 tw-m-0 tw-my-24px" />
          <div className={`${STYLE.header} !tw-mb-0`}>Home Value Estimate</div>
          <div className={`${STYLE.subHeader} tw-mb-24px`}>{formData.name}</div>
          <Toggle
            id="homeValue"
            name="homeValue"
            value={REPORT_TYPE_HASH[formData.report_type]}
            checked={REPORT_TYPE_HASH[formData.report_type]}
            onChange={() =>
              setFormData({
                report_type: formData.report_type === "market" ? "home_value" : "market",
                home_value_adjustment: null,
                home_value_adjustment_as_percentage: false,
              })
            }
            disabled={false}
            textClassName="tw-font-semibold"
            text="Include Home Value in Market Report"
          />
          <div className="tw-mt-24px tw-flex tw-gap-24px tw-items-center">
            <HomeEstimateValue value={`$ ${Number(homeAdjVal).toLocaleString()}`} />
            {Boolean(formData?.home_value_adjustment) && formData?.home_value_adjustment * 1 !== 0 && (
              <>
                <ArrowRightSolidV6 size="l" className="tw-text-gray-75" />
                <HomeEstimateValue
                  header="Adjusted Est."
                  value={calculateHomeAdjustment({
                    homeAdj: formData?.home_value_adjustment,
                    isHomeAdjPercentage: formData?.home_value_adjustment_as_percentage,
                    isHomeAdjPositive: formData?.isHomeAdjPositive,
                    homeAdjVal,
                  })}
                  onClose={() =>
                    setFormData({
                      isHomeAdjPositive: true,
                      isHomeAdjPercentage: false,
                      home_value_adjustment: 0,
                    })
                  }
                />
              </>
            )}
          </div>
          <div className="tw-mt-24px">
            <div className={STYLE.subHeader}>Adjust Home Value Estimate</div>
            <div className="tw-flex tw-gap-4px tw-mt-4px">
              <ButtonToggle
                options={[
                  { label: <PlusSolidV6 size="l" />, value: true },
                  { label: <MinusSolidV6 size="l" />, value: false },
                ]}
                value={formData.isHomeAdjPositive}
                onChange={(e) => {
                  setFormData({ isHomeAdjPositive: e.target.value === "true" });
                }}
              />
              <TextComboInput
                leading={!formData.home_value_adjustment_as_percentage ? "$" : ""}
                trailing={formData.home_value_adjustment_as_percentage ? "%" : ""}
                value={`${Math.floor(Math.abs(formData.home_value_adjustment))}`}
                onChange={(e) => {
                  if (isValidValue(e.target.value)) {
                    setFormData({ home_value_adjustment: Number(formatNumber(e.target.value.trim())) });
                  }
                }}
                className="tw-flex-1"
              />
              <ButtonToggle
                options={[
                  { label: <DollarSignSolidV6 size="l" />, value: false },
                  { label: <PercentSolidV6 size="l" />, value: true },
                ]}
                value={formData.home_value_adjustment_as_percentage}
                onChange={(e) => {
                  setFormData({
                    home_value_adjustment_as_percentage: e.target.value === "true",
                    home_value_adjustment: 0,
                  });
                }}
              />
            </div>
          </div>
          <div className="tw-mt-24px tw-text-14d tw-text-gray-50">
            The adjusted estimate will use the most recent automated estimate and then add or subtract a
            dollar or percentage amount. This will appear on the home value section of the Market Report.
          </div>
        </>
      )}
    </form>
  );
};

Panel2Form.propTypes = {
  formData: PropTypes.shape({
    drip_enabled: PropTypes.bool,
    email_message: PropTypes.string,
    name: PropTypes.string,
    recurring_frequency: PropTypes.string,
    home_value_adjustment: PropTypes.number,
    home_value_adjustment_as_percentage: PropTypes.bool,
    isHomeAdjPositive: PropTypes.bool,
  }),
  setFormData: PropTypes.func,
  report: PropTypes.shape({}),
};

Panel2Form.defaultProps = {
  formData: {
    drip_enabled: 0,
    email_message: "",
    name: "",
    recurring_frequency: "never",
    home_value_adjustment: 0,
    home_value_adjustment_as_percentage: false,
    isHomeAdjPositive: true,
  },
  setFormData: null,
  report: null,
};

export default Panel2Form;

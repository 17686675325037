import React from "react";
import PropTypes from "prop-types";
import { sortBy } from "lodash";

const NOTIFICATION_CLASSES = {
  container:
    "tw-py-[12px] tw-px-[24px] tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-neutral-gray-10 tw-border-b-[1px] hover:tw-bg-neutral-gray-5",
  name: "tw-text-theme-primary",
  time: "tw-text-gray-50",
};

const NotificationContainer = ({ className, href, name, summary, time }) => (
  <li className="tw-list-none">
    <a className="hover:tw-no-underline" href={href}>
      <div className={`${NOTIFICATION_CLASSES.container} ${className}`}>
        <div className="summary">
          <small>{summary}</small>
        </div>
        <div className={`${NOTIFICATION_CLASSES.name} notification-name`}>
          <small>{name}</small>
        </div>
        <div className={`${NOTIFICATION_CLASSES.time} time`}>
          <small>{time}</small>
        </div>
      </div>
    </a>
  </li>
);

NotificationContainer.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

NotificationContainer.defaultProps = {
  className: "",
};

const Notification = ({ notification }) => {
  switch (notification.action) {
    case "comment_on_conversation_added":
      return (
        <NotificationContainer
          className="comment_on_conversation_added"
          href={notification.path}
          summary={`${notification.notifiable.commentor_first_name} commented on the conversation`}
          name={notification.notifiable.subject}
          time={`${notification.time} ago`}
        />
      );

    case "incoming_sms":
      return (
        <NotificationContainer
          className="incoming_sms_notification"
          href={notification.path}
          summary="New SMS message from:"
          name={`${notification.notifiable.full_name || "No Name"} ${notification.content}`}
          time={`${notification.time} ago`}
        />
      );

    case "incoming_group_sms": {
      const recipientNames = sortBy(
        [notification.notifiable.meta.sender]
          .concat(notification.notifiable.meta.recipients)
          .map((r) => r.full_name || "No Name"),
      ).join(", ");
      return (
        <NotificationContainer
          className="incoming_group_sms_notification"
          href={`${notification.path}?conversationUuid=${notification.notifiable.uuid}`}
          summary={
            <>
              New Group SMS message from:&nbsp;
              <span className={NOTIFICATION_CLASSES.name}>
                {notification.notifiable.meta.sender.full_name}
              </span>
            </>
          }
          name={
            <>
              <span className="tw-text-neutral-gray-50">in Group SMS:</span>&nbsp;{recipientNames}
            </>
          }
          time={`${notification.time} ago`}
        />
      );
    }

    case "listing_team_member_added":
      return (
        <NotificationContainer
          className="listing_team_member_added"
          href={notification.path}
          summary={`You've been added to the ${notification.notifiable_type}`}
          name={
            <>
              {notification.notifiable?.title}&nbsp;as a <strong>Team Member.</strong>
            </>
          }
          time={`${notification.time} ago`}
        />
      );

    case "person_mentioned_in_note":
      return (
        <NotificationContainer
          className="montioned"
          href={notification.path}
          summary={`${notification.notifiable.mention_notification_text}`}
          name={notification.notifiable.content}
          time={`${notification.time} ago`}
        />
      );

    case "person_mentioned_in_call_note":
      return (
        <NotificationContainer
          className="mentioned"
          href={notification.path}
          summary={`${notification.content}`}
          name={notification.notifiable.comment}
          time={`${notification.time} ago`}
        />
      );

    case "person_new_lead_added":
      return (
        <NotificationContainer
          className="person_new_lead_added"
          href={notification.path}
          summary="New Lead Created:"
          name={`${notification.notifiable.first_name} ${notification.notifiable.last_name}`}
          time={`${notification.time} ago`}
        />
      );

    case "project_team_member_added":
      return (
        <NotificationContainer
          className="project_team_member_added"
          href={notification.path}
          summary={`You've been added to the ${notification.notifiable_type.toLowerCase()}`}
          name={
            <>
              {notification.notifiable.name}&nbsp;as a <strong>Team Member.</strong>
            </>
          }
          time={`${notification.time} ago`}
        />
      );

    default:
      return (
        <NotificationContainer
          className="generic-notification"
          href={notification.path}
          summary={notification.notifiable_type.toLowerCase()}
          name={notification.notifiable.name}
          time={`${notification.time} ago`}
        />
      );
  }
};

Notification.propTypes = {
  notification: PropTypes.shape().isRequired,
};

export default Notification;

import React from "react";
import { useDispatch } from "react-redux";
import Button from "@shared/v2/Button";
import { PhoneHangupSolidV6, PhoneSolidV6 } from "@shared/v2/Icomoon";
import CallRecordingTooltip from "./CallRecordingTooltip";
import { useOutboundCallRecordingSetting } from "../../reducers/layoutReducer/selectors";
import { useCallState, usePhoneNumber } from "../../reducers/dialerReducer/selectors";
import CALL_STATES from "../utils/states";
import { setCallState } from "../../reducers/dialerReducer/dialerReducer";

const CallButton = () => {
  const dispatch = useDispatch();
  const { outboundNotificationMessage, outboundRecording } = useOutboundCallRecordingSetting();
  const callState = useCallState();
  const phoneNumber = usePhoneNumber();
  return (
    <div className="tw-flex tw-flex-col tw-items-end">
      {callState === CALL_STATES.Init && (
        <>
          <CallRecordingTooltip
            disabled={outboundRecording === "disabled"}
            willNotify={outboundNotificationMessage}
            willRecord={outboundRecording === "automatic"}
          />
          <Button
            schema="solid-green"
            size="medium"
            className="tw-min-w-[132px] tw-inline-flex tw-justify-center tw-items-center tw-gap-[4px]"
            onClick={() => dispatch(setCallState(CALL_STATES.Started))}
            disabled={!phoneNumber || phoneNumber?.meta.callUnsubscribed}
          >
            <PhoneSolidV6 /> Call Now
          </Button>
        </>
      )}
      {callState !== CALL_STATES.Init && (
        <Button
          schema="solid-red"
          size="medium"
          onClick={() => dispatch(setCallState(CALL_STATES.Ended))}
          disabled={callState === CALL_STATES.Ended}
          className="tw-min-w-[132px] tw-inline-flex tw-justify-center tw-items-center tw-gap-[4px]"
        >
          <PhoneHangupSolidV6 size="l" /> End Call
        </Button>
      )}
    </div>
  );
};

export default CallButton;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@shared/v2/Modal";
import Button from "@shared/v2/Button";
import Alert from "@shared/v2/Alert";
import ChooseContact from "./ChooseContact";
import MergeContacts from "./MergeContacts";
import {
  useContactMergeLoading,
  useContactMergeModalIsOpen,
  useContactMergeOtherContact,
  useContactMergePrimaryContact,
  useContactMergeError,
} from "../reducers/contactMergeReducer/selectors";
import * as actions from "../reducers/contactMergeReducer";
import { useFeatureFlags } from "../reducers/layoutReducer/selectors";
import Loading from "../Goals/components/Loader";
import useIsEditable from "../PersonDetail/shared/useIsEditable";

const getTitle = (page) => {
  if (page === 2) return "Merge Lead";
  return "Select a Lead to Merge";
};

const ContactMergeModal = ({ push }) => {
  const dispatch = useDispatch();
  const featureFlags = useFeatureFlags();
  const person = useSelector((state) => state.personDetail.person);
  const modalIsOpen = useContactMergeModalIsOpen();
  const primaryContact = useContactMergePrimaryContact();
  const otherContact = useContactMergeOtherContact();
  const loading = useContactMergeLoading();
  const error = useContactMergeError();
  const [page, setPage] = useState(1);

  const isEditable = useIsEditable();

  useEffect(() => {
    if (featureFlags.personMergeTool && isEditable && person.data?.attributes.has_login_user === false) {
      dispatch(actions.getHasDuplicates(person.data.attributes.slug));
    }
  }, [person.data, featureFlags]);

  useEffect(() => {
    if (!primaryContact && featureFlags.personMergeTool && modalIsOpen) {
      dispatch(actions.getPotentialDuplicates(person.data.attributes.slug));
    }
  }, [primaryContact, modalIsOpen, featureFlags]);

  useEffect(() => {
    if (!modalIsOpen) setPage(1);
  }, [modalIsOpen]);

  const onClose = () => dispatch(actions.setModalIsOpen(false));

  return (
    <Modal
      closeOnEscape={false}
      closeOnClickOutside={false}
      dialogClassName="tw-flex tw-items-center tw-justify-center tw-h-full"
      contentClassName="tw-max-w-[700px] tw-max-h-[90vh] tw-w-full tw-flex tw-flex-col"
      show={modalIsOpen}
      onHide={onClose}
    >
      <Modal.Header title={getTitle(page)} closeButton={!loading} />
      <Modal.Body className="tw-relative tw-flex tw-flex-col tw-overflow-hidden tw-mt-[12px] tw-mb-[32px]">
        {loading && <Loading />}
        {primaryContact && page === 1 && <ChooseContact />}
        {page === 2 && <MergeContacts />}
      </Modal.Body>
      <Modal.Footer className="tw-flex tw-flex-col tw-gap-[24px]">
        {error && <Alert variant="error" text={error} />}
        <div className="tw-flex tw-justify-between tw-gap-[8px]">
          <Button
            size="medium"
            schema="tertiary"
            onClick={page <= 1 ? onClose : () => setPage((p) => p - 1)}
            disabled={loading}
          >
            {page > 1 ? "Back" : "Cancel"}
          </Button>
          <Button
            size="medium"
            schema="primary"
            onClick={
              page <= 1
                ? () => {
                    dispatch(
                      actions.setKeepContact(otherContact.hasListings ? otherContact : primaryContact),
                    );
                    setPage((p) => p + 1);
                  }
                : () =>
                    dispatch(actions.mergeContacts()).then(({ meta, payload: mergeContact }) => {
                      if (meta.requestStatus === "fulfilled") {
                        onClose();
                        if (primaryContact.slug === mergeContact.slug) {
                          window.location.reload();
                        } else {
                          push(`/people/${mergeContact.slug}`);
                        }
                      }
                    })
            }
            disabled={page === 1 ? !otherContact : false}
            isLoading={loading}
          >
            {page === 2 ? "Merge" : "Next"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

ContactMergeModal.propTypes = {
  push: PropTypes.func.isRequired,
};

export default ContactMergeModal;

import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Overlay } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { useClickOutside } from "../../shared/hookHelpers";
import Snackbar from "@shared/v2/Snackbar";

const MarketReportMenu = ({editReportFn, deleteReportFn, previewUrl}) => {
  const [showCopiedLink, setShowCopiedLink] = useState(false);
  const [show, setshow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  useClickOutside(ref, () => setshow(false));

  useEffect(() => {
    if (showCopiedLink) {
      const timeoutId = setTimeout(() => setShowCopiedLink(false), 3500);
      return () => clearTimeout(timeoutId);
    }
  }, [showCopiedLink]);

  const handleToggle = (event) => {
    setshow(!show);
    setTarget(event.target);
  };

  const openPreviewFn = () => window.open(previewUrl, "_blank");
  const shareReportFn = () => navigator.clipboard.writeText(previewUrl).then(() => setShowCopiedLink(true));
  const dropdownLinks = [
    { key: "edit", onClick: editReportFn, icon: "pencil", text: "Edit" },
    { key: "preview", onClick: openPreviewFn, icon: "eye", text: "Preview" },
    { key: "share", onClick: shareReportFn, icon: "link", text: "Copy Link" },
    { key: "delete", onClick: deleteReportFn, icon: "trash", text: "Delete" },
  ];

  return (
    <div className="tw-relative" ref={ref}>
      <button
        type="button"
        className="focus:tw-outline-none tw-bg-neutral-gray-10 tw-rounded-full tw-border-0 tw-p-0 tw-h-[24px] tw-w-[24px]"
        onClick={handleToggle}
      >
        <FontAwesomeIcon icon={faEllipsisH} transform="shrink-2" className="tw-text-netural-gray-75" />
      </button>

      <Overlay show={show} target={target} placement="left" container={ref.current}>
        <div className="tw-absolute !tw-top-full tw-min-w-[116px] tw-leading-5 tw-text-14d tw-shadow-dropdown tw-shrink-0	tw-bg-white tw-px-0 tw-rounded-[4px] tw-overflow-hidden tw-z-1 tw-text-[#666666]">
          {dropdownLinks.map((link) => (
            <button
              key={link.key}
              type="button"
              onClick={() => {
                setshow(false);
                link.onClick();
              }}
              className="tw-bg-white hover:tw-bg-gray-5 tw-w-full tw-text-left tw-px-[12px] tw-py-[8px] tw-border-0 tw-text-14d"
            >
              <i className={`tw-mr-[8px] fa fa-${link.icon}`} />
              {link.text}
            </button>
          ))}
        </div>
      </Overlay>
      <Snackbar position="top" show={showCopiedLink} text="Link copied" schema="executed" />
    </div>
  );
};

MarketReportMenu.propTypes = {
  editReportFn: PropTypes.func,
  deleteReportFn: PropTypes.func,
  previewUrl: PropTypes.string,
};

MarketReportMenu.defaultProps = {
  editReportFn: null,
  deleteReportFn: null,
  previewUrl: null,
};

export default MarketReportMenu;

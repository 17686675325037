import React from "react";
import isString from "lodash/isString";
import Alert from "./v2/Alert";

const Error = ({ errorMessage }) => {
  const messages = isString(errorMessage) ? errorMessage.split() : errorMessage;

  return messages.map((err, i) => (
    <Alert key={i} variant="error" text={<div dangerouslySetInnerHTML={{ __html: err }} />} />
  ));
};

Error.defaultProps = {
  errorMessage: "",
};
export default Error;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faMapMarker, faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as creators from "../../actions/creators";
import SummaryTile from "../SummaryTitle";
import ContactEmailInput from "../ContactEmailInput";
import RadiusSelector from "../RadiusSelector";
import SearchAreaInput from "../SearchAreaInput";
import PropertyTypesSelector from "../PropertyTypesSelector";
import PriceSelector from "../PriceSelector";
import BedsSelector from "../BedsSelector";
import BathsSelector from "../BathsSelector";
import ShowMoreLess from "../ShowMoreLess";
import SqFeet from "../SqFeet";
import LotSize from "../LotSize";
import YearBuilt from "../YearBuilt";
import Map from "../Map";
import { useFormData, useRunOnce } from "./hookHelpers";
import { getUserLocationAndInitMap } from "../Map/helpers";
import { useAutoDetect } from "../Map/hookHelpers";

let clearIsDrawing;

const mapStateToProps = (state) => ({
  person: state.addMarketReport.person,
  formData: state.addMarketReport.form1,
  isDrawing: state.addMarketReport.isDrawing,
  isSearching: state.addMarketReport.isSearching,
  coords: state.addMarketReport.form1.lat_lng,
  browserLatLon: state.addMarketReport.browserLatLon,
  locationData: state.addMarketReport.locationData,
  isFormInValid: Object.keys(state.addMarketReport.formErrors).some(
    (k) => state.addMarketReport.formErrors[k] === true,
  ),
  hasSearch: state.addMarketReport.form1.searchLocation !== null,
  currentName: state.addMarketReport?.savedMarketReport?.name,
});
const Panel1 = ({
  person,
  formData,
  setFormData,
  setSearching,
  setContactData,
  setLatLon,
  setBrowserLatLon,
  coords,
  browserLatLon,
  isDrawing,
  isSearching,
  setIsDrawing,
  setMessage,
  setFormError,
  isFormInValid,
  locationData,
  setLocationData,
  hasSearch,
  currentName,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [loadingLocation, setLoadingLocation] = useState(null);
  useRunOnce({ person, setContactData });
  useAutoDetect({
    coords,
    geoJSON: formData.geo_json || formData.boundry_coordinates,
    autoDetectFn: () => {
      setLoadingLocation(true);
      getUserLocationAndInitMap({
        errorFn: () => {
          setLoadingLocation(false);
        },
        successFn: (e) => {
          const crds = [e.coords.latitude, e.coords.longitude];
          setLatLon(crds);
          setBrowserLatLon(crds);
          setLoadingLocation(false);
        },
      });
    },
  });
  useFormData({
    formData,
    isDrawing,
    setLocationData,
    setFormError,
    setMessage,
    isFormInValid,
    setSearching,
  });
  return (
    <div className="tw-flex tw-h-full tw-overflow-y-hidden tw-overflow-x-auto">
      <div className="preview map tw-flex-1 tw-min-w-[360px] tw-h-full tw-relative tw-bg-gray-5 tw-rounded-[8px]">
        {hasSearch || coords?.length === 2 || browserLatLon?.length === 2 || formData.boundry_coordinates ? (
          <Map />
        ) : (
          <div className="tw-absolute tw-inset-0 tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center">
            {loadingLocation ? (
              <>
                <h4 className="tw-font-bold">Looking for your location</h4>
                <FontAwesomeIcon icon={faSpinner} className="tw-h-[16px] tw-w-[16px] tw-mx-auto fa-spin" />
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faSearch} className="tw-h-[16px] tw-w-[16px]" />
                <h4 className="tw-font-bold tw-m-0 ">Enter a search area</h4>
                <p className="tw-mt-[6px] tw-mb-[25px] tw-px-8">
                  Address, Neighborhood, County, City, or Zip code
                </p>
                <FontAwesomeIcon icon={faMapMarker} className="tw-h-[16px] tw-w-[16px]" />
                <h4 className="tw-font-bold tw-m-0">or enable geo-location</h4>
                <p className="tw-mt-[6px] tw-px-8">Allow your browser to use your location</p>
              </>
            )}
          </div>
        )}

        <div className="tw-absolute tw-left-1/2 tw--translate-x-1/2 tw-justify-center tw-bottom-7 tw-flex">
          {isSearching && <SummaryTile text="Searching" color="gray" isSearching />}
          {locationData && (
            <>
              <SummaryTile text="Active" color="green" total={locationData?.listing_counts?.active} />
              <SummaryTile text="Pending" color="yellow" total={locationData?.listing_counts?.pending} />
              <SummaryTile text="Sold" color="red" total={locationData?.listing_counts?.sold} />
              <SummaryTile text="Off Mkt" color="gray" total={locationData?.listing_counts?.off_market} />
            </>
          )}
        </div>
      </div>
      <form className="tw-w-[416px] tw-flex-none tw-overflow-y-auto tw-border-x-0 tw-border-y tw-border-solid tw-border-neutral-gray-10 tw-ml-[12px] tw-px-[4px]">
        <ContactEmailInput
          value={formData.contact.email}
          onChange={(val) => {
            setContactData({
              email: val,
            });
          }}
        />
        <SearchAreaInput
          currentName={currentName}
          MLSCountry={formData.mls_country}
          value={formData.searchLocation}
          onChange={(val) => {
            const mod = { searchLocation: val, street_address: val?.label };
            if (!val) {
              mod.street_address = null;
              mod.boundry_coordinates = null;
              mod.searchLocation = null;
              mod.lat_lng = null;
              mod.city = null;
              mod.county = null;
              mod.district = null;
              mod.postal_code = null;
              mod.state_abbreviation = null;
            }
            setFormData(mod);
          }}
          defaultOptions={person?.addresses}
          clearIsDrawing={() => {
            if (clearIsDrawing) {
              clearIsDrawing();
              setIsDrawing(false);
            }
          }}
        />
        {formData.search_type === "radius" && formData.searchLocation !== null && (
          <RadiusSelector
            toggle={formData.radius_type === "smart-radius"}
            onToggle={(val) => {
              setFormData({ radius_type: val ? "smart-radius" : "radius" });
            }}
            value={formData.radius}
            onChange={(val) => {
              setFormData({ radius: val });
            }}
          />
        )}
        <PropertyTypesSelector
          value={formData.property_type}
          onChange={(vals) => {
            setFormData({ property_type: vals });
          }}
          primaryAgentID={formData.primary_agent_uuid}
        />
        <PriceSelector
          min={formData.min_price}
          max={formData.max_price}
          onChange={(min, max) => {
            setFormData({ min_price: min, max_price: max });
          }}
        />
        <BedsSelector
          value={[formData.min_beds, formData.max_beds]}
          onChange={(min, max) => {
            setFormData({ min_beds: min, max_beds: max });
          }}
        />
        <BathsSelector
          value={[formData.min_baths]}
          onChange={(value) => {
            setFormData({ min_baths: value });
          }}
        />
        {showMore && (
          <>
            <SqFeet
              min={formData.min_area}
              max={formData.max_area}
              onChange={(min, max) => {
                setFormData({ min_area: min, max_area: max });
              }}
            />
            <LotSize
              min={formData.min_lot}
              max={formData.max_lot}
              onChange={(min, max) => {
                setFormData({ min_lot: min, max_lot: max });
              }}
            />
            <YearBuilt
              min={formData.min_year_built}
              max={formData.max_year_built}
              onChange={(min, max) => {
                setFormData({
                  min_year_built: min,
                  max_year_built: max,
                });
              }}
            />
          </>
        )}
        <ShowMoreLess showMore={showMore} onClick={() => setShowMore((s) => !s)} />
        {showMore && <div className="tw-h-[150px]" />}
      </form>
    </div>
  );
};

Panel1.propTypes = {
  isDrawing: PropTypes.bool,
  isSearching: PropTypes.bool,
  setSearching: PropTypes.func,
  hasSearch: PropTypes.bool,
  person: PropTypes.shape({
    email: PropTypes.string,
    addresses: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.shape({
      attributes: PropTypes.shape({
        type: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        uuid: PropTypes.string,
        lead: PropTypes.shape({
          id: PropTypes.string,
        }),
      }),
    }),
  }),
  formData: PropTypes.shape({
    search_address: PropTypes.string,
    street_address: PropTypes.string,
    lat_lng: PropTypes.arrayOf(PropTypes.number),
    search_type: PropTypes.string,
    radius_type: PropTypes.string,
    radius: PropTypes.number,
    property_type: PropTypes.string,
    mls_country: PropTypes.string,
    boundry_coordinates: PropTypes.arrayOf(PropTypes.number),
    geo_json: PropTypes.arrayOf(PropTypes.number),
    min_price: PropTypes.number,
    max_price: PropTypes.number,
    min_beds: PropTypes.number,
    max_beds: PropTypes.number,
    min_baths: PropTypes.number,
    max_baths: PropTypes.number,
    min_area: PropTypes.number,
    max_area: PropTypes.number,
    min_lot: PropTypes.number,
    max_lot: PropTypes.number,
    min_year_built: PropTypes.number,
    max_year_built: PropTypes.number,
    contact: PropTypes.shape({
      email: PropTypes.string,
    }),
    primary_agent_uuid: PropTypes.string,
    searchLocation: PropTypes.shape({}),
  }),
  setMessage: PropTypes.func,
  setFormError: PropTypes.func,
  setFormData: PropTypes.func,
  setIsDrawing: PropTypes.func,
  setContactData: PropTypes.func,
  setLatLon: PropTypes.func,
  setBrowserLatLon: PropTypes.func,
  isFormInValid: PropTypes.bool,
  coords: PropTypes.arrayOf(PropTypes.number),
  browserLatLon: PropTypes.arrayOf(PropTypes.number),
  setLocationData: PropTypes.func,
  locationData: PropTypes.shape({
    listing_counts: PropTypes.shape({
      active: PropTypes.arrayOf(PropTypes.shape({})),
      pending: PropTypes.arrayOf(PropTypes.shape({})),
      sold: PropTypes.arrayOf(PropTypes.shape({})),
      off_market: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  currentName: PropTypes.string,
};

Panel1.defaultProps = {
  isDrawing: false,
  isSearching: false,
  setSearching: null,
  person: null,
  hasSearch: false,
  formData: {
    search_address: "",
    street_address: null,
    lat_lng: null,
    search_type: null,
    radius_type: "",
    radius: 0,
    property_type: "",
    boundry_coordinates: null,
    mls_country: null,
    geo_json: null,
    min_price: 0,
    max_price: 0,
    min_beds: 0,
    max_beds: 0,
    min_baths: 0,
    max_baths: 0,
    min_area: null,
    max_area: null,
    min_lot: null,
    max_lot: null,
    min_year_built: null,
    max_year_built: null,
    contact: {
      email: null,
    },
    primary_agent_uuid: null,
    searchLocation: null,
  },
  isFormInValid: false,
  setFormError: null,
  setMessage: null,
  setFormData: null,
  setIsDrawing: null,
  setContactData: null,
  setLatLon: null,
  setBrowserLatLon: null,
  coords: null,
  browserLatLon: null,
  setLocationData: null,
  locationData: null,
  currentName: null,
};

export default connect(mapStateToProps, creators)(Panel1);

import { useEffect, useState } from "react";
import { sortBy, find, forEach, each } from "lodash";
import { produce } from "immer";

import { LABEL_VALUE_STATES } from "@shared/constants";
import { getEnvoyReferralsFormDataApi } from "../../shared/api";

export const useGetEnvoyReferralData = (personId) => {
  const [formOptionsFromApi, setFormOptionsFromApi] = useState({});
  const [formDataFromApi, setFormDataFromApi] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(!personId) return;

    (async () => {
      setIsLoading(true);
      const { res } = await getEnvoyReferralsFormDataApi(personId);

      if (res) {
        const retrievedLoanOfficers = sortBy(res.data.hub_spot_owner_options, 'name');

        setFormOptionsFromApi(prev => produce(prev, draft => {
          draft.sourceOptions = res.data?.source_options.map((source) => ({ label: source, value: source }));
          draft.stateOptions = res.data?.state_options.map((state) => ({ label: state, value: state })).slice(1);
          draft.bestTimeToCallOptions = res.data?.best_time_to_call_options.map((time) => ({ label: time, value: time }));
          draft.timeFrameOptions = res.data?.timeframe_options.map((time) => ({ label: time, value: time }));
          draft.referringAgentOptions = res.data?.agent_options.map((agent) => ({ label: agent?.agent_name, value: { agentEmail: agent?.agent_email, agentPhone: agent?.agent_phone } }));

          draft.originalLOList = retrievedLoanOfficers;
          const loanOfficersByState = {};
          forEach(retrievedLoanOfficers, (agent, index) => {
            const states = agent.licensedStates.split(', ');
            each(states, (state) => {
              if (loanOfficersByState[state]) {
                loanOfficersByState[state].push(index);
              } else {
                loanOfficersByState[state] = [index];
              }
            });
          });

          draft.loanOfficersByState = loanOfficersByState;
        }));

        setFormDataFromApi(prev => produce(prev, draft => {
            draft.agent_email = res.data.primary_agent?.agent_email;
            draft.agent_name = res.data.primary_agent?.agent_name;
            draft.agent_phone = res.data.primary_agent?.agent_phone;
            draft.team_name = res.data.team_name;
            draft.brokerage = res.data.brokerage;
            draft.lastReferredDate = res.data.last_envoy_referral?.referred_at;
            draft.hub_spot_owner_id = res.data.default_hub_spot_owner_id;
            
            if(res.data?.office_state) {              
              const stateObj = find(LABEL_VALUE_STATES, { abbreviation: res.data.office_state });
              draft.state = stateObj ? stateObj.label : null;
            }
        }))
      }

      setIsLoading(false);

    })();
  }, [personId]);

  return {
    formOptionsFromApi,
    formDataFromApi,
    setFormDataFromApi,
    isLoading
  };
};

import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  caseTransformingAxios,
  responseDataToCamelCase,
  requestDataToSnakeCase,
} from "@shared/v2/caseTransformingAxios";
import CALL_STATES from "../../Dialer/utils/states";
import dncApi from "@shared/phoneDncStatusApi";
import { upperFirst } from "lodash";
import { viewPhoneFromFlat } from "@shared/models/Communications/viewPhone";
import { STATUS_ICON_MODIFIERS } from "@shared/models/Communications/viewPhoneIcons/CallStatusIcon";
import { DncFlag } from "@shared/Sms";
import { updateTimeline } from "../../PersonDetail/actions/personDetailActionCreators";

export const initialState = {
  callState: CALL_STATES.Init,
  comment: "",
  conference: null,
  duration: null,
  error: null,
  leadUuids: null,
  loading: false,
  outcome: null,
  person: {},
  phoneNumber: null,
  phoneNumbers: [],
  session: null,
  validLeads: {},
};

const PhoneNumberOption = ({ viewPhone, isDnc }) => {
  const icon = viewPhone.isMobile ? viewPhone.mobileCallIcon : viewPhone.callIcon;
  const phoneColor = STATUS_ICON_MODIFIERS[viewPhone.callStatus];

  return (
    <div className="tw-flex tw-space-x-[6px] tw-items-center">
      <span className="tw-flex">{icon.icon({ size: "m" })}</span>
      <span className={phoneColor}>{`${upperFirst(viewPhone.category)}: ${viewPhone.formattedNumber}`}</span>
      {isDnc && <DncFlag show={isDnc} />}
    </div>
  );
};

PhoneNumberOption.propTypes = {
  viewPhone: PropTypes.shape().isRequired,
  isDnc: PropTypes.bool.isRequired,
};

const checkValidLeads = createAsyncThunk("dialer/checkValidLeads", (leadUuids, thunkApi) =>
  caseTransformingAxios.post("/dialer/check_valid_leads", { leadUuids }),
);

const createTemporaryTimelineEvent = ({ currentUser, outcome, comment, duration }) => {
  const unixTime = moment().unix();
  return {
    agent_person: {
      first_name: currentUser.firstName,
      last_name: currentUser.lastName,
    },
    id: `public_activity_${unixTime}`,
    interaction_type: "call",
    interacted_at_epoch: unixTime,
    interacted_at_absolute: moment.tz("America/Los_Angeles").format("MM/DD/YY [at] h:mm a z"),
    object_attributes: {
      duration,
      interaction_type: "call",
      outcome,
      markdown_comment: comment,
    },
  };
};

const getPersonAndNumbers = createAsyncThunk("dialer/getPerson", (uuid, thunkApi) => {
  return caseTransformingAxios
    .get(`/api/v4/person/${uuid}/dialer_details`, { signal: thunkApi.signal })
    .then(({ data: person }) => {
      const dialablePhoneNumbers = person?.dialablePhoneNumbers || [];
      return dncApi
        .index(
          dialablePhoneNumbers.map((x) => x.value),
          thunkApi.signal,
        )
        .catch(() => ({ data: {} }))
        .then(({ data: dncMap }) => {
          const phoneNumbers = dialablePhoneNumbers.map((phone) => {
            const viewPhone = viewPhoneFromFlat(phone);
            const isDnc = dncMap[phone.value];
            return {
              label: <PhoneNumberOption viewPhone={viewPhone} isDnc={isDnc} />,
              value: phone.value,
              meta: { ...viewPhone, isDnc },
            };
          });
          const phoneNumber = phoneNumbers.find((i) => i.meta.position === 1) || phoneNumbers[0] || null;
          return {
            person: {
              id: person.id,
              fullName: person.fullName,
              primaryPhoneNumber: phoneNumber?.value,
              uuid: person.uuid,
              slug: person.slug,
            },
            phoneNumbers,
            phoneNumber,
          };
        });
    });
});

const createDialerSession = createAsyncThunk(
  "dialer/createDialerSession",
  ({ listingUuid } = {}, thunkApi) => {
    const {
      dialer: { leadUuids, person },
      layout: {
        apiUrls: { dialerServiceUrl },
      },
    } = thunkApi.getState();

    return caseTransformingAxios
      .post("/dialer/create_dialer_session", { leadUuids, person, listingUuid }, { signal: thunkApi.signal })
      .then(({ data: session }) =>
        caseTransformingAxios
          .post(`${dialerServiceUrl}/dialer/start_dialer_session`, { token: session.token })
          .then(() => session),
      )
      .catch((err) => thunkApi.rejectWithValue(err.response?.data));
  },
);

const endCall = createAsyncThunk("dialer/endCall", (_, thunkApi) => {
  const {
    dialer: { person, session },
    layout: {
      apiUrls: { dialerServiceUrl },
    },
  } = thunkApi.getState();
  const token = session?.token;
  return caseTransformingAxios
    .post(`${dialerServiceUrl}/dialer/end_current_call`, { token }, { signal: thunkApi.signal })
    .catch(console.log)
    .finally(() => {
      if (person) {
        return caseTransformingAxios
          .post(`${dialerServiceUrl}/dialer/end_dialer_session`, { token })
          .catch(console.log);
      }
    });
});

const logCall = createAsyncThunk("dialer/logCall", ({ listingId, listingUuid } = {}, thunkApi) => {
  const {
    dialer: { outcome, comment, conference, duration, person, session },
    layout: {
      currentUser,
      apiUrls: { dialerServiceUrl },
    },
  } = thunkApi.getState();
  const token = session?.token;

  if (!token || !conference?.currentParticipant?.id) {
    return caseTransformingAxios
      .post(`/people/${person.id}/interactions`, {
        interaction: {
          listingId,
          userId: currentUser.id,
          personId: person.id,
          interactionType: "call",
          comment,
          outcome,
        },
        personDetailsPage: "true",
      })
      .then((res) =>
        thunkApi.dispatch(updateTimeline(requestDataToSnakeCase(res.data), "UPDATE_PUBLIC_ACTIVITY_EVENTS")),
      );
  }

  return caseTransformingAxios
    .post(`${dialerServiceUrl}/dialer/save_outcome`, {
      outcome,
      comment,
      listingUuid,
      participantId: conference?.currentParticipant.id,
      token,
    })
    .then(() => {
      thunkApi.dispatch(
        updateTimeline(
          createTemporaryTimelineEvent({ currentUser, outcome, comment, duration }),
          "UPDATE_PUBLIC_ACTIVITY_EVENTS",
        ),
      );
    });
});

const startRecording = createAsyncThunk("dialer/startRecording", (_, thunkApi) => {
  const {
    dialer: { session },
    layout: {
      apiUrls: { dialerServiceUrl },
    },
  } = thunkApi.getState();
  const token = session?.token;
  return caseTransformingAxios.post(`${dialerServiceUrl}/dialer/start_recording`, { token });
});

const stopRecording = createAsyncThunk("dialer/stopRecording", (_, thunkApi) => {
  const {
    dialer: { session },
    layout: {
      apiUrls: { dialerServiceUrl },
    },
  } = thunkApi.getState();
  const token = session?.token;
  return caseTransformingAxios.post(`${dialerServiceUrl}/dialer/stop_recording`, { token });
});

export const dialerSlice = createSlice({
  name: "dialer",
  initialState,
  reducers: {
    clearSession: (state) => {
      state.session = null;
    },
    setCallState: (state, action) => {
      state.callState = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
      state.person.primaryPhoneNumber = action.payload.value;
    },
    actionCableUpdateReceived: (state, action) => {
      const conference = responseDataToCamelCase(action.payload.conference);
      if (conference.currentParticipant?.humanizedCallStatus === "Ringing") {
        state.callState = CALL_STATES.Ringing;
      }
      if (conference.currentParticipant?.humanizedCallStatus === "In-progress") {
        state.callState = CALL_STATES.Connected;
      }
      if (
        conference.totalParticipants === 1 &&
        (conference.currentParticipant?.humanizedCallStatus === "Completed" ||
          conference.agentParticipant?.humanizedCallStatus === "Completed")
      ) {
        state.callState = CALL_STATES.Ended;
      }
      if (conference.agentParticipant?.humanizedCallStatus === "Canceled") {
        state.callState = CALL_STATES.Init;
        state.session = null;
      }
      state.conference = conference;
    },
    setCallOutcome: (state, action) => {
      state.outcome = action.payload;
    },
    setComment: (state, action) => {
      state.comment = action.payload;
    },
    setLeadUuid: (state, action) => {
      if (action.payload) state.leadUuids = [action.payload];
      else state.leadUuids = null;
    },
    setLeadUuids: (state, action) => {
      state.leadUuids = action.payload;
    },
    setDuration: (state, action) => {
      state.duration = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkValidLeads.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkValidLeads.fulfilled, (state, action) => {
        state.loading = false;
        state.validLeads = action.payload;
      })
      .addCase(checkValidLeads.rejected, (state, action) => {
        state.loading = false;
      });

    builder
      .addCase(getPersonAndNumbers.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getPersonAndNumbers.fulfilled, (state, action) => {
        state.callState = CALL_STATES.Init;
        state.loading = false;
        state.person = action.payload.person;
        state.phoneNumbers = action.payload.phoneNumbers;
        state.phoneNumber = action.payload.phoneNumber;
      })
      .addCase(getPersonAndNumbers.rejected, (state, action) => {
        state.loading = false;
        state.error = "Failed to fetch person and phone numbers. Please try again later.";
      });

    builder
      .addCase(createDialerSession.pending, (state) => {
        state.error = null;
      })
      .addCase(createDialerSession.fulfilled, (state, action) => {
        state.session = action.payload;
      })
      .addCase(createDialerSession.rejected, (state, action) => {
        state.callState = CALL_STATES.Init;
        if (action.payload?.status === 422) {
          state.error = "Number is not valid";
        } else {
          state.error = "Dialer session could not be created, try again later.";
        }
      });

    builder
      .addCase(logCall.fulfilled, (state) => {
        state.outcome = null;
        state.comment = "";
        state.callState = CALL_STATES.Init;
        state.session = null;
      })
      .addCase(logCall.rejected, (state, action) => {
        console.log(action.error);
        state.error = "Call outcome could not be saved.";
      });
  },
});

export {
  checkValidLeads,
  createDialerSession,
  endCall,
  getPersonAndNumbers,
  logCall,
  startRecording,
  stopRecording,
};

export const {
  actionCableUpdateReceived,
  setCallOutcome,
  setCallState,
  setDuration,
  setLeadUuid,
  setLeadUuids,
  setComment,
  setPhoneNumber,
  clearSession,
} = dialerSlice.actions;

export default dialerSlice.reducer;

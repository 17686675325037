import React from "react";
import TextLink from "@shared/v2/TextLink";
import Typography from "@shared/v2/Typography";
import Button from "@shared/v2/Button";
import { GearSolidV6, PlusSolidV6 } from "@shared/v2/Icomoon";

const BlogIndex = () => (
  <div className="tw-flex-1 tw-max-w-[1416px] tw-mx-auto tw-flex tw-gap-[32px]">
    <div className="tw-m-[16px] tw-bg-white tw-border-[1px] tw-border-solid tw-border-gray-10 tw-rounded-[8px] tw-p-[32px]">
      <div className="tw-flex tw-gap-[32px]">
        <div>
          <Typography variant="headline1">Team Blog</Typography>
          <Typography variant="body-m" className="tw-mt-[8px]">
            Blog posts help you showcase expertise, attract buyers and sellers, and improve your Brivity
            website’s search rankings. Share market insights, tips, and local highlights to build trust,
            nurture leads, and offer valuable advice to clients. <TextLink href="#">Learn more</TextLink>
          </Typography>
        </div>
        <div className="tw-flex tw-gap-[8px]">
          <Button size="medium" schema="tertiary" className="tw-whitespace-nowrap">
            <GearSolidV6 /> Settings
          </Button>
          <Button size="medium" className="tw-whitespace-nowrap">
            <PlusSolidV6 /> New Post
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default BlogIndex;

import React, { useState } from "react";
import { Col, Row } from "../../../../shared/v1";
import Button from "../../../../shared/v2/Button";
import Plus from "../../../../shared/v2/Icomoon/Icons/Plus";
import AdApplyPlan from "../AdApplyPlan";
import AdSite from "../AdSite";

const EmptyState = () => {
  const [adsiteModalOpen, setadsiteModalOpen] = useState(false);
  const [adplanModalOpen, setadplanModalOpen] = useState(false);

  const adsitecloseModal = () => {
    setadsiteModalOpen(false);
  };
  const adplancloseModal = () => {
    setadplanModalOpen(false);
  };
  return (
    <>
      <Row className="tw-p-0 tw-mx-[0px] tw-m-0">
        <Col size={12} className="tw-p-0 tw-py-[0px] tw-px-[0px] tw-mx-[0px] tw-m-0">
          <div className="tw-border-gray-10 tw-border tw-border-solid tw-rounded-lg tw-text-center tw-p-[24px]">
            <p className="tw-text-gray-75 tw-text-14d tw-font-normal">
              No advertising plans have been applied.
            </p>
            <p className="tw-text-gray-50 tw-text-12d tw-font-normal tw-mb-[16px]">
              Add a plan to share links to sites where this property is advertised with your client.
            </p>
            <div className="tw-flex tw-justify-center tw-gap-[16px]">
              <Button onClick={() => setadplanModalOpen(true)} schema="primary" size="small">
                <Plus size="s" className="tw-mr-[4px]" />
                APPLY AD PLAN
              </Button>
              <Button onClick={() => setadsiteModalOpen(true)} schema="secondary" size="small">
                <Plus size="s" className="tw-mr-[4px]" />
                ADD SITE
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <AdApplyPlan modalOpen={adplanModalOpen} closeModal={adplancloseModal} />

      {adsiteModalOpen && (
        <AdSite
          isFromManageModal={false}
          planId={null}
          modalOpen={adsiteModalOpen}
          closeModal={adsitecloseModal}
        />
      )}
    </>
  );
};

export default EmptyState;

import React, { useState } from "react";
import Results from "./Results";
import css from "./QuickSearch.module.css";
import { Search } from "../../shared/v2/Icomoon";
import useQuickSearch from "../../Dashboard/services/useQuickSearch";

const saveRecentSearch = (result) => {
  const recentSearches = JSON.parse(localStorage.getItem("recentSearches") || "[]");
  for (let i = 0; i < recentSearches.length; i++) {
    if (recentSearches[i].path === result.path) {
      recentSearches.splice(i, 1);
      break;
    }
  }
  recentSearches.unshift(result);
  const limitedRecentSearches = recentSearches.slice(0, 5);
  localStorage.setItem("recentSearches", JSON.stringify(limitedRecentSearches));
};

const QuickSearch = () => {
  const [isActive, setIsActive] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [search, setSearch] = useState("");
  const { data, error, loading } = useQuickSearch({ search });

  const toggleActive = () => setIsActive((a) => !a);

  const handleKeyDown = (e) => {
    const searchValid = !error;
    const recentSearches = JSON.parse(localStorage.getItem("recentSearches") || "[]");

    // when pressing the enter key on a selection goes to that result's page
    if (e.keyCode === 13) {
      e.preventDefault();
      let selected = undefined;

      if (searchValid) {
        selected = data[cursor];
      } else {
        if (cursor <= recentSearches.length - 1) {
          selected = recentSearches[cursor];
        }
      }
      selected && saveRecentSearch(selected);
      selected && window.location.replace(selected.path);
      // arrow up/down button should select next/previous list element
    } else if (e.keyCode === 38 && cursor > 0) {
      setCursor((c) => c - 1);
    } else if (
      e.keyCode === 40 &&
      ((searchValid && cursor < data.length - 1) || (!searchValid && cursor < recentSearches.length - 1))
    ) {
      setCursor((c) => c + 1);
    }
  };

  const handleHover = (index) => setCursor(index);

  return (
    <div>
      <form role="search">
        <div className={css.searchDropdown}>
          <div className={css.searchInputContainer} id="searchInputContainer">
            <input
              className={`${css.formControl} !tw-bg-gray/10 !tw-text-inherit`}
              type="text"
              onFocus={toggleActive}
              onBlur={toggleActive}
              maxLength={50}
              placeholder="Search by name, address, phone or MLS#"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <Search className="tw-absolute tw-right-[6px] tw-top-[calc(50%-8px)]" size="l" />
          </div>
          {isActive && (
            <Results
              searchResults={data}
              hasResults={search.trim() && data.length > 0}
              onHover={handleHover}
              searchTerm={search}
              cursor={cursor}
              searchTermLength={search.length}
              isFetching={loading}
              searchValid={search.trim()}
              saveRecentSearch={saveRecentSearch}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default QuickSearch;

import React from "react";
import Moment from "react-moment";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { calculatePercent, determineCorrectPercentColor, truncateTime } from "./reportHelpers";
import { defaultOrImage } from "../../shared/DefaultOrImage";

const AgentReportRow = ({ report, ...props }) => {
  return (
    <div className="user td">
      <Col xs={2} className="tw-flex">
        <div style={{ display: "inline-block", padding: "0 10px" }}>
          <a href={`/people/${report.user_path}`}>{defaultOrImage(report.avatar, report.agent)}</a>
        </div>
        <div className="tw-overflow-hidden tw-text-ellipsis">{report.agent}</div>
      </Col>
      <div className="col-xs-1 text-center" style={{ display: "inline-block" }}>
        <span>{report.lead_count}</span>
      </div>
      <div className="col-xs-1 text-center">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="right"
          overlay={
            <Tooltip id="email_count">
              <p style={{ paddingTop: "5px" }}>
                % of Assigned Leads:{" "}
                <span
                  style={{
                    color: determineCorrectPercentColor(
                      props.total_leads,
                      props.total_emails,
                      report.lead_count,
                      report.email_count,
                    ),
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  ({calculatePercent(report.lead_count, report.email_count).toFixed(0)}%)
                </span>
              </p>
              <p>
                Team Average:{" "}
                <strong>
                  <span style={{ fontSize: "13px" }}>
                    {" "}
                    ({calculatePercent(props.total_leads, props.total_emails).toFixed(0)}%)
                  </span>
                </strong>
              </p>
            </Tooltip>
          }
        >
          <span>
            {report.email_count}
            <span
              style={{
                color: determineCorrectPercentColor(
                  props.total_leads,
                  props.total_emails,
                  report.lead_count,
                  report.email_count,
                ),
                fontSize: "13px",
              }}
            >
              {" "}
              ({calculatePercent(report.lead_count, report.email_count).toFixed(0)}%)
            </span>
          </span>
        </OverlayTrigger>
      </div>
      <div className="col-xs-1 text-center">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="right"
          overlay={
            <Tooltip id="call_count">
              <p style={{ paddingTop: "5px" }}>
                % of Assigned Leads:{" "}
                <span
                  style={{
                    color: determineCorrectPercentColor(
                      props.total_leads,
                      props.total_calls,
                      report.lead_count,
                      report.call_count,
                    ),
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  ({calculatePercent(report.lead_count, report.call_count).toFixed(0)}%)
                </span>
              </p>
              <p>
                Team Average:{" "}
                <strong>
                  <span style={{ fontSize: "13px" }}>
                    {" "}
                    ({calculatePercent(props.total_leads, props.total_calls).toFixed(0)}%)
                  </span>
                </strong>
              </p>
            </Tooltip>
          }
        >
          <span>
            {report.call_count}
            <span
              style={{
                color: determineCorrectPercentColor(
                  props.total_leads,
                  props.total_calls,
                  report.lead_count,
                  report.call_count,
                ),
                fontSize: "13px",
              }}
            >
              {" "}
              ({calculatePercent(report.lead_count, report.call_count).toFixed(0)}%)
            </span>
          </span>
        </OverlayTrigger>
      </div>
      <div className="col-xs-1 text-center">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="right"
          overlay={
            <Tooltip id="contacted">
              <p style={{ paddingTop: "5px" }}>
                % Assigned Leads:{" "}
                <span
                  style={{
                    color: determineCorrectPercentColor(
                      props.total_leads,
                      props.total_calls,
                      report.lead_count,
                      report.contacted_count,
                    ),
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  ({calculatePercent(report.lead_count, report.contacted_count).toFixed(0)}%)
                </span>
              </p>
              <p>
                Team Average:{" "}
                <strong>
                  <span style={{ fontSize: "13px" }}>
                    {" "}
                    ({calculatePercent(props.total_leads, props.total_contacted).toFixed(0)}%)
                  </span>
                </strong>
              </p>
            </Tooltip>
          }
        >
          <span>
            {report.contacted_count}
            <span
              style={{
                color: determineCorrectPercentColor(
                  props.total_leads,
                  props.total_calls,
                  report.lead_count,
                  report.contacted_count,
                ),
                fontSize: "13px",
              }}
            >
              {" "}
              ({calculatePercent(report.lead_count, report.contacted_count).toFixed(0)}%)
            </span>
          </span>
        </OverlayTrigger>
      </div>

      <div className="col-xs-1 text-center">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="right"
          overlay={
            <Tooltip id="text_count">
              <p style={{ paddingTop: "5px" }}>
                % of Assigned Leads:{" "}
                <span
                  style={{
                    color: determineCorrectPercentColor(
                      props.total_leads,
                      props.total_texts,
                      report.lead_count,
                      report.text_count,
                    ),
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  ({calculatePercent(report.lead_count, report.text_count).toFixed(0)}%)
                </span>
              </p>
              <p>
                Team Average:{" "}
                <strong>
                  <span style={{ fontSize: "13px" }}>
                    {" "}
                    ({calculatePercent(props.total_leads, props.total_texts).toFixed(0)}%)
                  </span>
                </strong>
              </p>
            </Tooltip>
          }
        >
          <span>
            {report.text_count}
            <span
              style={{
                color: determineCorrectPercentColor(
                  props.total_leads,
                  props.total_texts,
                  report.lead_count,
                  report.text_count,
                ),
                fontSize: "13px",
              }}
            >
              {" "}
              ({calculatePercent(report.lead_count, report.text_count).toFixed(0)}%)
            </span>
          </span>
        </OverlayTrigger>
      </div>
      <div className="col-xs-3" style={{ display: "flex", justifyContent: "flex-start", padding: 0 }}>
        <div className="col-xs-4 text-center">
          {report.email_resp_label !== "-" ? (
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="right"
              overlay={<Tooltip id="email_resp_time">{report.email_resp_label}</Tooltip>}
            >
              <span>{truncateTime(report.email_resp_label)}</span>
            </OverlayTrigger>
          ) : (
            <span>{truncateTime(report.email_resp_label)}</span>
          )}
        </div>
        <div className="col-xs-4 text-center">
          {report.call_resp_label !== "-" ? (
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="right"
              overlay={<Tooltip id="call_resp_time">{report.call_resp_label}</Tooltip>}
            >
              <span>{truncateTime(report.call_resp_label)}</span>
            </OverlayTrigger>
          ) : (
            <span>{truncateTime(report.call_resp_label)}</span>
          )}
        </div>
        <div className="col-xs-4 text-center">
          {report.text_resp_label !== "-" ? (
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="right"
              overlay={<Tooltip id="text_resp_time">{report.text_resp_label}</Tooltip>}
            >
              <span>{truncateTime(report.text_resp_label)}</span>
            </OverlayTrigger>
          ) : (
            <span>{truncateTime(report.text_resp_label)}</span>
          )}
        </div>
      </div>
      <div className="col-xs-1 text-center">{report.login_count}</div>
      {report.last_login ? (
        <div className="col-xs-1 text-center">
          <Moment format="MM/DD/YYYY">{report.last_login}</Moment>
        </div>
      ) : (
        <div className="col-xs-1" />
      )}
    </div>
  );
};

export default AgentReportRow;

import React from "react";
import PropTypes from "prop-types";

const ShowMoreLess = ({ showMore = false, onClick }) => {
  const clickHnalder = (e) => {
    e.preventDefault();
    onClick();
  };
  return (
    <button
      type="button"
      className="tw-bg-transparent tw-border-0 tw-text-12d tw-font-bold tw-mt-[8px] tw-px-0 tw-text-teal"
      onClick={clickHnalder}
    >
      SHOW {showMore ? "LESS" : "MORE"}
    </button>
  );
};

ShowMoreLess.propTypes = {
  showMore: PropTypes.bool,
  onClick: PropTypes.func,
};

ShowMoreLess.defaultProps = {
  showMore: false,
  onClick: null,
};

export default ShowMoreLess;

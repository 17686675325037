import React, { useContext, useState } from "react";
import { Transition } from "react-transition-group";
import PropTypes from "prop-types";

import { PanelContext } from "./PanelContext";

const PANEL_ANIMATION_DURATION_MS = 300;

const containerAnimationStyles = (duration) => ({
  transition: `max-height ${duration}ms ease-in-out`,
});

const containerTransitionStyles = (nodeHeight) => ({
  entering: { maxHeight: `${nodeHeight}px`, overflow: "hidden" },
  entered: { maxHeight: `${nodeHeight}px` },
  exiting: { maxHeight: "0", overflow: "hidden" },
  exited: { maxHeight: "0" },
});

const PanelCollapse = ({ className, collapsed: collapsedFromProps, children, ...props }) => {
  const [nodeHeight, setNodeHeight] = useState(0);
  const { collapsed: collapsedFromContext } = useContext(PanelContext);
  const collapsed = typeof collapsedFromProps === "boolean" ? collapsedFromProps : collapsedFromContext;

  return (
    <Transition in={!collapsed} appear timeout={PANEL_ANIMATION_DURATION_MS} unmountOnExit mountOnEnter>
      {(panelContainerState) => (
        <div
          {...props}
          ref={(node) => setNodeHeight(node?.scrollHeight || 0)}
          className={className}
          data-js-collapsed={collapsed}
          style={{
            ...containerAnimationStyles(PANEL_ANIMATION_DURATION_MS),
            ...containerTransitionStyles(nodeHeight)[panelContainerState],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};

PanelCollapse.propTypes = {
  className: PropTypes.string,
  collapsed: PropTypes.bool,
  children: PropTypes.node,
};

PanelCollapse.defaultProps = {
  className: "",
  collapsed: null,
  children: null,
};

export default PanelCollapse;

import React from "react";
import { Modal } from "react-bootstrap";
import Proptypes from "prop-types";
import HeaderTitle from "../HeaderTitle";
import Sitebody from "./Sitebody";

const AdSite = ({ isFromManageModal, modalOpen, planId, closeModal }) => (
  <Modal
    className="modal"
    backdrop="static"
    show={modalOpen}
    onHide={closeModal}
    data-cy="marketing-advertising-plan-adsite-modal"
  >
    <Modal.Header className="tw-pb-0 tw-px-[32px] tw-pt-[32px]">
      <Modal.Title className="tw-pb-[12px]  tw-border-0">
        <HeaderTitle title="Add Site" onClose={closeModal} />
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="tw-pt-0 tw-px-[32px] tw-pb-[32px]">
      <span className="tw-text-[14px] tw-font-normal tw-leading-[20px] tw-text-gray-75">
        When you promote this listing on a 3rd party website, add the link here to have it appear in the
        Client Email Update and client timeline for this transaction.
      </span>
      <Sitebody isFromManageModal={isFromManageModal} planId={planId} closeModal={closeModal} />
    </Modal.Body>
  </Modal>
);

AdSite.propTypes = {
  modalOpen: Proptypes.bool,
  isFromManageModal: Proptypes.bool,
  closeModal: Proptypes.func,
  planId: Proptypes.string,
};
AdSite.defaultProps = {
  modalOpen: false,
  isFromManageModal: false,
  closeModal: null,
  planId: null,
};
export default AdSite;

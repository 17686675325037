import React from "react";
import { useSelector } from "react-redux";
import AiAskPlace from "@shared/AiAssistant/AiAskPlace";
import Notifications from "../../Notifications";
import Help from "./HelpDropdown";
import FeatureToggle from "./FeatureToggle";
import GlobalStatus from "../GlobalStatus";
import SuperUser from "../SuperUser";
import MetaUser from "../MetaUser";
import UserDropdown from "./UserDropdown";
import Logo from "./Logo";
import MobileHamburger from "./MobileHamburger";
import Resources from "./Resources";
import ReferFriend from "./ReferFriend";
import { useCurrentUser, useFeatureFlags } from "../../reducers/layoutReducer/selectors";
import QuickSearch from "../../QuickSearch/components/QuickSearch";
import ActivitiesFeed from "./ActivitiesFeed";

const Header = () => {
  const currentUser = useCurrentUser();
  const featureFlags = useFeatureFlags();
  const asSuper = useSelector(({ layout }) => layout.asSuper);

  return (
    <>
      <SuperUser />
      <MetaUser />
      <GlobalStatus />
      {!asSuper && (
        <>
          <header className="tw-sticky tw-top-0 tw-z-1000 tw-text-neutral-gray-50 tw-px-[20px] tw-pt-[8px] tw-flex tw-gap-[16px] tw-justify-between tw-items-center">
            <div className="tw-flex tw-gap-[16px] tw-items-center">
              <MobileHamburger />
              <Logo />
            </div>

            <div className="bs-xs:tw-hidden tw-min-w-[200px] tw-max-w-[600px] tw-flex-1 tw-flex tw-items-center">
              {currentUser?.account.uuid && featureFlags.askPlace && (
                <div className="tw-mr-[8px] tw-my-[-2px]">
                  <AiAskPlace />
                </div>
              )}

              {currentUser?.account.uuid && featureFlags.quicksearch && (
                <div className="tw-flex-1">
                  <QuickSearch />
                </div>
              )}
            </div>

            <div className="tw-flex tw-gap-[12px] tw-items-center">
              <FeatureToggle />
              {currentUser?.account.uuid && (
                <div className="tw-flex tw-items-center">
                  <ReferFriend />
                  <Resources />
                  <Help />
                </div>
              )}
              {currentUser?.account.uuid && featureFlags.activityFeed ? (
                <ActivitiesFeed />
              ) : (
                <Notifications />
              )}
              <UserDropdown />
            </div>
          </header>
        </>
      )}
    </>
  );
};

export default Header;

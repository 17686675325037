import { useEffect, useState } from "react";
import { caseTransformingAxios } from "../../v2/caseTransformingAxios";

let tokenData = null;

const useAiToken = () => {
  const [data, setData] = useState({});
  const getToken = () =>
    caseTransformingAxios
      .post("/api/v4/ai_assist/token")
      .then((res) => {
        setData(res.data);
        tokenData = res.data;
      })
      .catch(() => setData({ error: true }));

  useEffect(() => {
    // token payload || {}
    const payload = JSON.parse(atob(data.token?.split(".")[1] || "e30="));
    let timeoutId = null;

    if (payload.exp) {
      // in the unlikely but possible event that a user remains interacting with the ai
      // assistant for longer than the token lasts, give it a little refresh
      const timeout = new Date((payload.exp - 30) * 1000).valueOf() - Date.now();
      timeoutId = setTimeout(getToken, Math.max(0, timeout));
    }

    return () => {
      // if unmounted cancel refresh
      clearTimeout(timeoutId);
    };
  }, [data.token]);

  useEffect(() => {
    if (tokenData) setData(tokenData);
    else getToken();
  }, []);

  return data;
};

export default useAiToken;

import React, { useState } from "react";
import { connect } from "react-redux";
import Proptypes from "prop-types";
import Snackbar from "@shared/v2/Snackbar";
import LeadHeaders from "../components/LeadHeaders";
import LeadRows from "../components/LeadRows";
import SingleDialerModal from "../../Dialer/SingleDialerModal";

const mapStateToProps = (state) => ({ from: state.leadIndex.from });

const LeadIndex = ({ from }) => {
  const [alert, setAlert] = useState(localStorage.getItem("successMsg"));

  return (
    <div id="content" className="tw-flex tw-flex-col tw-h-full">
      <Snackbar
        position="top"
        show={Boolean(alert)}
        text={alert || ""}
        timeout={5000}
        schema="executed"
        closeOnTimeout={() => {
          setAlert(null);
          localStorage.removeItem("successMsg");
        }}
      />
      <div className="sticky-table tw-flex-1">
        <table className={from === "leads" ? "leads-table" : "all-people-table"}>
          <LeadHeaders />
          <LeadRows />
          <SingleDialerModal />
        </table>
      </div>
    </div>
  );
};

LeadIndex.propTypes = {
  from: Proptypes.string,
};
LeadIndex.defaultProps = {
  from: "null",
};
export default connect(mapStateToProps)(LeadIndex);

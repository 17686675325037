import axios from "axios";
import { useEffect, useState } from "react";

const useNotifications = () => {
  const [loading, setLoading] = useState(true);
  const [markedRead, setMarkedRead] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    axios
      .get("/api/v4/notifications", {
        headers: { "Content-Type": "application/json", Accept: "application/json" },
        params: { _: Date.now() },
      })
      .then((res) => setNotifications(res.data))
      .finally(() => setLoading(false));
  }, []);

  const actions = {
    markAllAsRead() {
      axios
        .delete("/notifications/mark_all_as_read", {
          params: { authenticity_token: ReactOnRails.authenticityToken() },
          headers: { "Content-Type": "application/json", Accept: "application/json" },
        })
        .then(() => setNotifications([]))
        .finally(() => setMarkedRead(true));
    },
  };
  return { loading, markedRead, notifications, actions };
};

export default useNotifications;
